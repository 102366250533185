export const EditorState = {
    scroll:{x:0, y:0},
    pressedKeys:[],
    tipForEdit:0,
    openFirst:0,
    apiUrl:null,
    goodsUrl:null,
    carFields:null,
    //customFields:null
    customerData:null,
    customerFields:null,
    maxTempId:1,
    lastInsertPosition:{x:5, y:5},
    dragging:false,
    dragStartPos:{},
    dragCurrentPos:{},
    firmMap:[
        {name:'jmeno', label:'jméno'},
        {name:'email',label:'email'},
        {name:'dic', label:'dič'},
        {name:'ico', label:'ičo'},
        {name:'obec', label:'obec'},
        {name:'ulice', label:'ulice'},
        {name:'psc', label:'psč'},
        {name:'telefon', label:'telefon'},
        {name:'www', label:'www'},
        {name:'today', label:'dnešní datum'},
        //{name:'logo', label:'logo'}
    ],
    //logo:null,
    version:'1.6.3'
}