import {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {DocumentContext} from "../App";
import {Fade} from "@mui/material";
import {useFade} from "../hooks/useFade";

export const Alerts = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);





    //let count = Cfg.alerts.length;
//    console.log(Cfg);
    return <> {Cfg.alerts.length > 0 &&
        <div style={{zIndex: '100'}} className={'alertStack'}>
            {Cfg.alerts.map((a, index)=>{
                    return <Alert key={index}  a={a}/>
            })}
        </div>
    }</>
}

export const Alert = (props)=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [a, setA] = useState(props.a);
    const myEl = useRef();

    //const faded = useRef(false);

    let showMe = true;
    const onEnd = (show)=>{
        console.log('END FADE...' + show);
        if(!show){
            showMe = false;
            let alerts = Cfg.alerts.filter((a1)=>{
               console.log("Test :" + a1.key + ' vs ' + a.key);
                return a1.key != a.key;
            });
            //console.log('END FADE 2...' + showMe + ' :: ' + alerts.length);
            myEl.current.style.display = 'none';
            //dispatch({type:'NONE', payload:{...Cfg, alerts:alerts}});
            dispatch({type:'property', payload:{name:'alerts', value:alerts}});
            console.log('Skryvam alerts..');
            console.log(alerts);
        }
    }
    const [isVisible, setVisible, fadeProps] = useFade(true, onEnd);

    const closeAlert = (ev, key)=>{
        let alerts = Cfg.alerts.filter((a1)=>{
            //console.log("Test :" + a1.key + ' vs ' + a.key);
            return a1.key != a.key;
        });
        //console.log('END FADE 2...' + showMe + ' :: ' + alerts.length);
        dispatch({type:'property', payload:{name:'alerts', value:alerts}});
        console.log('Skryvam alerts..');
        console.log(alerts);
        //dispatch({type:'NONE', payload:{...Cfg, alerts:alerts}});
    }
/*
    useLayoutEffect(()=>{

        console.log('IsVisibleChnaged: ' + isVisible + '; COUNT: ' + Cfg.alerts.length);
    },)

    useEffect(()=>{
        console.log(fadeProps);
    }, );
*/
    useEffect(()=>{
        //console.log('isVisible: ' + isVisible);
        setVisible(false);
    }, []);


//    console.log('showMe: ' + showMe + '; vis: ' + isVisible);


    return<> {(showMe && isVisible) && <div ref={myEl}  className={['alertItem', a.level].join(' ') } {...fadeProps}>
                <div className={'msgAlert'}>{a.msg}</div> <span onClick={(ev)=>{closeAlert(ev, a.key)}} className={'closeAlert'}>X</span>
            </div>}
    </>
}