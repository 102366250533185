import {useContext, useRef, useState} from "react";
import {DocumentContext} from "../../App";

export function Dialogs(props){
    const [Cfg, dispatch] = useContext(DocumentContext);
    const STATE_NORMAL = 1;
    const STATE_VALIDATE = 2;
    const [actualState, setActualState] = useState(STATE_NORMAL);
    const [message, setMessage] = useState(null);

    const closeDialog = async (d, btn)=>{
        let dProps = d.props;
        //console.log("Close dialog: " + dProps.id);
        let validated = true;
        if(btn == 'OK' && dProps.validate){
            setActualState(STATE_VALIDATE);
            validated = false;
            let validateResult = await dProps.validate(d);
            console.log('Validated result:');
            console.log(JSON.stringify(validateResult));
            if(validateResult.status != 'OK'){
                setActualState(STATE_NORMAL);
                console.log('Nastavuji msg: ' + validateResult.msg)
                setMessage(validateResult.msg);
            }else{
                validated = true;
            }
            console.log('Po validaci: ' + validated);
        }
        if(validated){
            if (dProps.close) {
                dProps.close(d, btn);
            }
            dispatch({type: 'dialog', payload: {verb: 'close', id: dProps.id, props: dProps}});
        }
    }

    return <>
            <div className='progressOver'>
                {actualState == STATE_NORMAL ? <>
            {Cfg.dialogs.map((d, index)=>
                <div key={index} className='dialog'>

                    {d}
                    <hr/>
                    {message != null &&<> <div style={{color:'red', backgroundColor:'transparent'}}>
                        {message}
                        </div>
                        <hr/></>
                    }

                    <div className='buttons'>
                    <button onClick={(ev)=>{closeDialog(d, 'Close')}}>Zavřít</button>
                    <button onClick={(ev)=>{closeDialog(d, 'OK');} }>OK</button>

                </div>
                </div>
                )} </> : <div>Kontrola dat...</div>}
        </div>
    </>
}