import {Frame} from "./Frame";
import {FrameInfo} from "../structs/FrameInfo";
import {initialConfig} from "../InitialConfig";

export const defaultFrameMan = {
    MaxId:0,
    Frames:[],
    Selected:[],
    add:()=>{

    },
    select:(fi)=>{
        this.Selected = [];
        this.Selected.push(fi);
    },
}

export function frameManager(){
    this.MaxId = 0;
    this.Frames = [];
    this.Selected = [];
    this.DefaultClickText = "poklepáním myši změňte text";

    this.needClickText = function(txt){
        return txt = (txt == false) || (txt == this.DefaultClickText);
    };

    this.select = function (fi){
        //console.log('SEl: ' + fi);
        this.Selected = [];
        this.Selected.push(fi);
    };

    this.add = function(payload){
        this.MaxId++;
        let ff = new FrameInfo();

        ff.tempId = this.MaxId;
        ff.page = payload.page;

        if(payload && payload.position){
            ff.top = payload.position.y;
            if(payload?.section == true){
                //ff.left = 0;
                ff.realSize.x = 0;
                ff.isSection = true;
            }else{
                //ff.left =payload.position.x;
                ff.realSize.x = payload.position.x;
            }
            if(payload.byFrame){
                ff = {...payload.byFrame, tempId:ff.tempId, dbId:-1, realSize:{...ff.realSize}};
            }
        }
        //ff.textContent = initialConfig.defaultClickText;
        this.Frames.push(ff);
        //console.log(ff);
        return ff;
    }
}