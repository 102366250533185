import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter, Routes, Route, useSearchParams, Await} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import {createContext, useEffect, useReducer, useState} from "react";
import { initialConfig } from "./InitialConfig";
import {Frame} from "./components/Frame";
import {FrameInfo} from "./structs/FrameInfo";
import {frameManager} from "./components/FrameManager";
import {GlobalReducer} from "./AppReducer";
import {usePostData} from "./hooks/usePostData";
import {UserData} from "./pages/UserData";
import {AdminHome} from "./components/Admin/AdminHome";
import {Preview} from "./pages/Preview.js";
import {useDocument} from "./hooks/useDocument";
import {EditorReducer} from "./EditorReducer";
import {EditorState} from "./EditorState";
import {GoodsState} from "./GoodsState";
import {GoodsReducer} from "./GoodsReducer";

export const DocumentContext = createContext();
export const GoodsContext = createContext();
export const EditorContext = createContext();
//export const DataContext = createContext();

function App() {
  //const [newDoc, SetNewDoc] = useState(null);
  //const [firstLoad, setFirstLoad] = useState(true);

  const reducer = (state, action)=>{
      return GlobalReducer.reducerSwitchProxy(state, action);
  };


  const getInitialConfig = (()=>{
      return initialConfig;
  });

  const getEditorState = () =>{
      let ret = sessionStorage.getItem('editor');
      if(ret){
          let retParsed = JSON.parse(ret);
          let vers = document.head.querySelector("[name~=buildVersion][content]").content;
          //console.log('VERZE: ' + vers);
          if(retParsed.version != EditorState.version){
            console.log('JINA VERZE....');
            sessionStorage.clear();
            ret = {...EditorState};
            ret.version = EditorState.version;
          }else{
            return retParsed;
          }
      }else{
        ret = {...EditorState};
      }
      return ret;
  }

  const [Cfg, dispatch] = useReducer(reducer, getInitialConfig());
  const [Editor, dispatchEditor] = useReducer(EditorReducer, getEditorState());
  const [Goods, dispatchGoods] = useReducer(GoodsReducer, {...GoodsState});
//  const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);
  const [apiAddress, stApiAddress] = useState(null);
  const [myKeys, setMyKeys] = useState([]);


  let apiUrl = '/';
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    //apiUrl = Fetch_url;
  } else {
    // production code
  }


  Cfg.apiUrl = apiUrl;

  const onScroll = ()=>{
    let scrPos = {x:window.scrollX, y:window.scrollY};
    //const onScroll = () => setOffset(window.scrollY);
    //dispatch({type:'scroll', payload:scrPos});
    //console.log('Scroll');
    //console.log(scrPos);
    //dispatch({type:'properties', payload:{scrollOffset:scrPos}}); //return {...fm1, scrollOffset: payload};
    dispatchEditor({type:'scroll', payload:scrPos});
  }

  const onbeforeunload = (ev)=>{
    console.log('Before unload...');
  }

  const onKeyUp = (ev)=>{

    //let keys = [...Editor.pressedKeys];
    if(myKeys.includes('#' + ev.keyCode)){
      console.log('remove...')
      let ix = myKeys.indexOf('#' + ev.keyCode);
      setMyKeys(myKeys.splice(ix, 1));
    }
    //console.log(keys);
    //dispatchEditor({type:'pressedKeys', payload:keys});
  }

  const onKeyDown = (ev)=>{

    if(!myKeys.includes('#' + ev.keyCode)){
      //console.log('ADD...')
      setMyKeys([...myKeys, '#' + ev.keyCode]);
      //keys.push('#' + ev.keyCode);
    }
    //console.log('KEYS 2: ' + JSON.stringify(myKeys));
    //dispatchEditor({type:'pressedKeys', payload:keys});
  }


  useEffect(() => {
    //let keys = [...Editor.pressedKeys];


    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('keydown', onKeyDown,);
    window.removeEventListener('keyup', onKeyUp,);
    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    //window.removeEventListener('beforeunload', onbeforeunload);
    //window.addEventListener('beforeunload', onbeforeunload);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keydown', onKeyUp);
    }
  }, []);


  //console.log(Cfg.basename);


  return (<>
    <DocumentContext.Provider value={[Cfg, dispatch]}>
      <GoodsContext.Provider value={[Goods, dispatchGoods]}>
        <EditorContext.Provider value={[Editor, dispatchEditor]}>
      <BrowserRouter basename={Cfg.basename}>
          <Routes>
            <Route path="/" element={<Layout/>}>
              <Route index element={<Home/>}></Route>
              <Route path="*" element={<NoPage/>}/>
            </Route>
            <Route path="/preview/" element={<Layout/>}>
              <Route index element={<Preview/>}></Route>
              <Route path=":template_id" element={<Preview/>}></Route>
              <Route path=":template_id/:document_id" element={<Preview/>}></Route>
            </Route>
            <Route path="/userData/" element={<Layout/>}>
              <Route index element={<UserData/>}></Route>
              <Route path=":template_id" element={<UserData/>}></Route>
              <Route path=":template_id/:document_id" element={<UserData/>}></Route>
            </Route>
            <Route path="/admin/" element={<Layout/>}>
              <Route index element={<AdminHome/>}></Route>
              <Route path=":template_id" element={<AdminHome/>}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </EditorContext.Provider>
      </GoodsContext.Provider>
    </DocumentContext.Provider>
    </>
  );
}

export default App;