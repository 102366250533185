import {useContext} from "react";
import {DocumentContext} from "../../App";
import {useDocument} from "../../hooks/useDocument";

export const PageSelector = ({addButtons = true})=>{
    /* const {addButtons} = props;*/
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [loadDoc, saveDoc, docUtils] = useDocument();

    const pageChange = (ev, page)=>{
        //console.log(ev.target.value);
        //dispatch({type:'none', payload:{...Cfg, currentPage:ev.target.value}});
        //dispatch({type:'none', payload:{...Cfg, currentPage:page}});
        dispatch({type:'property', payload:{name:'currentPage', value:page}});
    };

    const addPage = ()=>{
        //dispatch({type:'none', payload:{...Cfg, pageCount:(Cfg.pageCount + 1)}});
        console.log('')
        dispatch({type:'property', payload:{name:'pageCount', value:(Cfg.pageCount + 1)}});
    }

    const removePage = ()=>{

        //console.log('Copy to clipboard')
        /*
        if(Cfg.frameMan.Selected.length > 0){
            let sel = Cfg.frameMan.Selected[0];
            console.log('Copy to clipboard');
            console.log(sel);
            navigator.clipboard.writeText(JSON.stringify({clipboard:'Jsem tu'})).then(()=>{console.log('Konec Copy...')});
        }*/
        //navigator.clipboard.writeText(JSON.stringify({clipboard:'Jsem tu'})).then(()=>{console.log('Konec Copy...')});

        docUtils.removePage(Cfg.currentPage);
    }

    let pages = [];
    for(let i = 0; i < Cfg.pageCount; i++){
        pages.push(i + 1);
    }

    return <>
        <div className={'pageSelector'}>
        <fieldset>
            <legend>
                <div className={'pageLabel'}><span>Stránky: </span>
                    {addButtons &&
                        <span className={'pageButtons'}><div onClick={addPage}><span>+</span></div><div
                            onClick={removePage}><span>-</span></div></span>
                    }
                </div>
            </legend>
            {/*
                    <div className={'pageLabel'}><span>Stránky: </span>
                        <span className={'pageButtons'}><div onClick={addPage}>+</div><div onClick={removePage}>-</div></span>
                    </div>*/}
            <div className={'pages'}>
                {pages.map((p, index)=>{
                    return <div onClick={(ev)=>{pageChange(ev, index + 1)}} className={'page noSelectable ' + (Cfg.currentPage == index + 1 ? ' selPage' : '')}
                                style={{}} key={'p' + index} value={p} >{p}</div>
                })}
            </div>
        </fieldset>
        </div>
    </>
}