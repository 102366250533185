import {usePostData} from "./usePostData";
import {useContext, useState} from "react";
import {DocumentContext, EditorContext, GoodsContext} from "../App";

export const useGoods = ()=>{
    const [goodsId, setGoodsId] = useState(-1);
    //const [goods, setGoods] = useState(null);
    const  [Goods, dispatchGoods] = useContext(GoodsContext);
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);

    const [fetchByJson, fetchByPost] = usePostData();
/*
    const loadGoods = ()=>{
        console.log('Načítám data');
        let payData = {title: "Načítám pevná data", id: 'FetchData', verb: "add"};
        dispatch({type: "task", payload: payData});

        let url = Editor.goodsUrl;
        url += "?token=" + Cfg.firmaInfo.token;
        fetch(url, {})
            .then(resp=>resp.json())
            .then(json=>{
                console.log('Goods Fetch X..');
                let goods = json.fields;
                console.log(goods);
                dispatch({type: "carFields", payload: {fields:goods}});
            })
            .finally(() => {
                payData.verb = "remove";
                dispatch({type: "task", payload: payData})
            });

    }
*/
    const setGoodId = (id)=>{
        if(id != Goods.id) {
            console.log(`Nastavuji ID DAT na ${id} ....`);
            //setGoodId(id);
            let data = {
                dataId: id
            };
            console.log(data);
            let pr = fetchByJson('getGoods', data).promise;
            pr.then(resp => resp.json())
                .then(json => {
                    console.log('getGoods: ');
                    console.log(json);
                    dispatchEditor({type:'carFields', payload:json.fields});
                    dispatchEditor({type:'car', payload:json.car});
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    return [setGoodId   ];
}