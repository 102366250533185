import {useContext, useEffect, useRef, useState} from "react";
import {DocumentContext, EditorContext, GoodsContext} from "../App";
import {frameManager} from "../components/FrameManager";
import {initialConfig} from "../InitialConfig";
import {usePostData} from "./usePostData";
import {InputName} from "../dialogs/InputName";
import {forEach} from "react-bootstrap/ElementChildren";
import {defaultDocFont, defaultFrameBorder, FrameInfo} from "../structs/FrameInfo";
import {AlertButtons, AlertInfo as alertI, AlertInfo, AlertTypes, UseAlert} from "./useAlert";
import {FrameTypes} from "../structs/FrameTypes";
import {OpenDocumentDialog} from "../dialogs/OpenDocumentDialog";
import {PageMode} from "../structs/PageMode";


const pageInfo = {
    pageId:null,
}

export class DocInfo{
    constructor(id, dataId = -1) {
        this.id = id;
        this.dataId = dataId;
    }
}

export const DocNameInput = (props)=>{
    const input = useRef();

    const inputChanged = ()=>{
        //console.log('Input changed: ');

        props.inputData.name = input.current.value;

    }
//    console.log(props);
    return <><div className={'dialogPanel'} style={{width:'100%'}}>
        <label>Název dokumentu: </label><br/>
        <input onChange={inputChanged} style={{width:'100%'}} type='text' ref={input} name={'nameInput'} maxLength={45}/>
    </div></>
}



export const useDocument = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
    const  [Goods, dispatchGoods] = useContext(GoodsContext);
    const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);
    const [showAlert, renderAlerts] = UseAlert();
    //const [maxFrameId, setMaxFrameId] = useState(0);
    //const [lastInsert, setLastInsert] = useState({position:{x:5, y:5}});


    const saveNamedAs = async  (cfgOld = null)=>{
        let cfg = null;
        if(cfgOld){
            cfg = {...cfgOld};
        }else{
            cfg = {...Cfg}
        }
        cfg.documentId = -1;
        let newFr = [...Cfg.frameMan.Frames];
        newFr.forEach(f=>f.dbId = -1);
        cfg.frameMan.Frames = newFr;
        console.log('New Frames:');
        console.log(cfg);
        saveNamed(cfg);
    }


    const deletePrint = async ()=>{
        /* TODO
        let pp =  fetchJson('deletePrint/' + Cfg.documentId, {}).promise
            .then(resp=>resp.json())
            .then(json=>{
                document.reload();
            })
            .catch(ex=>{
                console.log('Chyba');
                console.log(ex);
            });
         */
    }

    const saveNamed = async  (cfg = null)=>{
        let async = true;
        let cfgSave = cfg ? cfg : Cfg;
        console.log('saveNamed...' + (async ? 'Async' : 'Sync'));

        if(!cfgSave.documentName){
            saveDoc();
        }else {
            let saveData = {...cfgSave.frameMan.Frames};
            /*
            let saveData = cfgSave.frameMan.Frames.map(f=>{
                    return {...f, widthByText:}
                });
            */
            //console.log('saving...');
            //console.log(saveData);



            let pay = {title: "Ukládám data", id: 'Fetch-' + Date.now(), verb: "add"};
            dispatch({type: "task", payload: pay});

            let savedata = {action: 'save',
                documentName: cfgSave.documentName,
                documentId:cfgSave.documentId,
                pageCount:cfgSave.pageCount,
                dataId:cfgSave.dataId,
                saveTimestamp:(new Date()).getTime(),
                templateId:cfgSave.templateId,
                pageMode:cfgSave.pageMode,
                pageModeExt:cfgSave.pageModeExt,
                data: saveData
            };

            let pp =  fetchJson('save', savedata);
            if(async){
                console.log('Async...');
                pp.promise
                    .then(resp=>resp.json())
                    .then(json=>{
                        if(json.status != 'OK'){
                            let alertI ={...AlertInfo};
                            alertI.type = AlertTypes.MESSAGE_BOX;
                            alertI.buttons = [AlertButtons.OK];
                            alertI.content = <>Chyba při uložení</>;
                            alertI.callBack = (data)=>{
                                //console.log('Return: ' + data.closeButton.name);
                                /*
                                if(data.closeButton.name == 'yes'){
                                    //dispatch({type:'removeFrame', payload:frame});
                                    dispatch({type: 'frame', payload:{verb:'remove', tempId:frame.tempId}});
                                }
                                 */
                            };
                            pay.verb = "remove";
                            dispatch({type: "task", payload: pay});
                            showAlert(alertI);
                         //   console.log('SAVED KO:' + JSON.stringify(json));
                            return;
                        }
                       // console.log('SAVED ok:' + JSON.stringify(json));
                        loadDoc(json);
                    });
            }else{
                //console.log('Sync...');
                let resp = await pp.promise;
                let json = await resp.json();
                if(json.status != 'OK') {
                    let alertI = {...AlertInfo};
                    alertI.type = AlertTypes.MESSAGE_BOX;
                    alertI.buttons = [AlertButtons.OK];
                    alertI.content = <>Chyba při uložení</>;
                    alertI.callBack = (data) => {
                        //console.log('Return: ' + data.closeButton.name);
                        /*
                        if(data.closeButton.name == 'yes'){
                            //dispatch({type:'removeFrame', payload:frame});
                            dispatch({type: 'frame', payload:{verb:'remove', tempId:frame.tempId}});
                        }
                         */
                    };
                    pay.verb = "remove";
                    dispatch({type: "task", payload: pay});
                    showAlert(alertI);
                    console.log('SAVED KO:' + JSON.stringify(json));
                    return;
                }
                console.log('SAVED OK:' + JSON.stringify(json));
                pp = loadDoc(json);
                //console.log('Po ulozeni: '  + JSON.stringify(json));
            }

        }
    }
/*
    useEffect(()=>{
        console.log('useDocument: ' + Cfg.documentName);
        console.log(Cfg)
        console.log('------------')
    }, [Cfg]);
*/

    const beforeSave = ()=>{
        //console.log('beforeSave... A');
        //console.log(Cfg.frameMan);
        let frs = Cfg.frameMan.Frames.map((f, index)=>{
            /*
            if(f.width == 0){
                f.width = f.tempWidth;
            }
            */
        });
        //console.log('beforeSave... B');
        //console.log(frs);
    };

    useEffect(()=>{
        let cpy = [...Cfg.frameMan.Frames.map(f=>f.tempId), 0];
        //console.log(cpy);

        let maxTemp = Math.max(...cpy);
        //console.log('MaxFrameId: ' + maxTemp + ' vs ' + Editor.maxTempId);
        if(maxTemp > Editor.maxTempId){
            dispatchEditor({type:'maxTempId', payload:maxTemp});
        }
    }, [Cfg.frameMan.Frames]);



    const saveDoc = (saveAs = false)=>{
/*
        console.log('saveDoc Hook: ' + Cfg.documentName);
        console.log(Cfg);
        console.log('------------')
*/
       // beforeSave();
        if(!Cfg.documentName || saveAs){
            let props = {
                id:'fileName',
                myText:{text:''}
            };

            let alertI ={...AlertInfo};
            alertI.type = AlertTypes.INPUT;
            alertI.buttons = [AlertButtons.CANCEL, AlertButtons.OK];
            let inpData = {name:''};
            alertI.content = <DocNameInput inputData={inpData}/>;
            alertI.callBackAsync = async (data)=>{
  //              console.log('Return: ' + data.closeButton.name);
                if(data.closeButton.name.toLowerCase() == 'ok'){
                    //dispatch({type:'removeFrame', payload:frame});
                    let validJsonProm = await fetchJson('checkName', {'name':inpData.name});//.promise
                    console.log(validJsonProm);
                    let validJsonResp = await validJsonProm.promise;
                    console.log(validJsonResp);
                        //.then(resp=>resp.json())
                        //;
                    let validJson = await validJsonResp.json();
    //                console.log('Valid json X: ' + validJson);
    //                console.log(validJson);
                    //let validJson = resp.json();
                    if(validJson.status != 'OK'){
                        let alertI2 ={...AlertInfo};
                        alertI2.type = AlertTypes.MESSAGE_BOX;
                        alertI2.buttons = [AlertButtons.OK];
                        alertI2.content = <>{validJson.msg}</>;
                        showAlert(alertI2);
                        return {noClose:true, msg:'Abcdx'};
                    }
      //              console.log(validJson);
                    //return;
                    let pp = {...Cfg, documentName:inpData.name};
                    //pp.documentName = inp.props.myText.text;
                    //pp.saveNow = true;
                    //Cfg.documentName = pp.documentName;
                    //dispatch({type:'none', payload:pp});
                    //console.log('Aftrer name: ' + inp.props.myText.text);
                    if(saveAs){
                        saveNamedAs(pp);
                    }else{
                        saveNamed(pp);
                    }
                    //console.log(Cfg);

                    //saveNamed();
                    //dispatch({type: 'frame', payload:{verb:'remove', tempId:frame.tempId}});
                }
                //alert('Jsem tu....');
            };
            showAlert(alertI);
            return;

            let inp =  <InputName {...props}
                                  validate = {async (data)=>{
                                      //console.log('Validate...');
                                      let resp = await fetchJson('checkName', {'name':inp.props.myText.text}).promise;
                                      return await resp.json();

                                    }
                                  }
                                  close={(ev, btn)=>{
                                      //console.log('BTN: ' + btn);
                                      if(btn == 'OK'){
                                          let pp = {...Cfg, documentName:inp.props.myText.text};
                                          //pp.documentName = inp.props.myText.text;
                                          //pp.saveNow = true;
                                          //Cfg.documentName = pp.documentName;
                                          //dispatch({type:'none', payload:pp});
                                          console.log('Aftrer name: ' + inp.props.myText.text);
                                          //console.log(Cfg);
                                          saveNamed(pp, false);
                                      }
                                          /*
                                         fetchJson('checkName', {'name':inp.props.myText.text}).promise
                                             .then(resp=>resp.json())
                                             .then(json=>{
                                                 console.log('Check Name...');
                                                 console.log(JSON.stringify(json));
                                                 if(json.status != 'OK'){

                                                 }else{
                                                     let pp = {...Cfg, documentName:inp.props.myText.text};
                                                     //pp.documentName = inp.props.myText.text;
                                                     //pp.saveNow = true;
                                                     //Cfg.documentName = pp.documentName;
                                                     //dispatch({type:'none', payload:pp});
                                                     //console.log('Aftrer name: ' + inp.props.myText.text);
                                                     //console.log(Cfg);
                                                     saveNamed(pp)
                                                 }
                                                 return json;
                                             });
                                          }*/
                                  }}></InputName>;

            dispatch({type:'dialog', payload:{ctrl:inp, verb:'add'} });
        }else{
            saveNamed(null, false);
        }
    }

    const loadPrintDoc = (docInfo)=>{
        let pay = {title: "Načítám data pro " + (docInfo.name ? docInfo.name : docInfo.id), id: 'Fetch-Open', verb: "add"};
        dispatch({type: "task", payload: pay});

        console.log('DocInfo: openPrint');
        console.log(docInfo);
        let ff = fetchJson('openPrint/?t=' + (new Date().getTime()), docInfo);
        ff.promise
            .then(resp=>{
                console.log(resp);
                return resp.json()
            })
            .then(json=>{
                console.log('OpenDoc...');
                console.log(json);

                let goodsData = json.goodsData;

                let newDoc =  loadDoc(json);
                console.log("TEST CAR " + goodsData.car?.ID + ' vs ' + json.doc.data_id);

                if(goodsData.car?.ID == json.doc.data_id){
                    console.log("Mam CarData...");
                    dispatchEditor({type:'carFields', payload:goodsData});
                    if(goodsData.car.cely_nazev){
                        //dispatch({type:'property', payload:{name:'documentName', value:(Cfg.documentName + '-' + goodsData.car.cely_nazev)}});
                    }
                }
                console.log('doc: -----------------');
                console.log(Cfg);
//                let newDoc =  loadDoc(json.doc);// docUtils.loadDoc(json.doc);
            })
            .catch(err=>{
                console.log(console.log(err))})
            .finally(()=>{
                pay.verb = "remove";
                dispatch({type: "task", payload: pay});
            })
        ;

    }

    const openDialogClosed = (info)=>{
        let doc = info.props?.dataBag?.selection;
        if(doc){
            //  console.log("DOC: " + doc);
            sessionStorage.removeItem('docData');
            let sessKeys = Object.keys(sessionStorage);
            sessKeys.forEach(k=>{
                if(k.startsWith('hist')){
                    sessionStorage.removeItem(k);
                }
            });
            let pay = {title: "Načítám data pro " + doc.nazev, id: 'Fetch-Open', verb: "add"};
            dispatch({type: "task", payload: pay});
            let dataForFetch = {makeCopy:0};
            if(info.props?.dataBag?.selection.makeCopy){
                dataForFetch.makeCopy = 1;
            }
            let ff = fetchJson('open/' + doc.id, dataForFetch);
            ff.promise
                .then(resp=>resp.json())
                .then(json=>{
                    console.log('OpenDoc First...');
                    console.log(json);
                    //json.doc.firmaInfo = json.firmaInfo;
                    //let newDoc =  docUtils.loadDoc(json.doc);
                    //console.log(json.doc);
                    let newDoc =  loadDoc(json);// docUtils.loadDoc(json.doc);
                })
                .finally(()=>{
                    pay.verb = "remove";
                    dispatch({type: "task", payload: pay});
                })
            ;

        }
    }

    const openDoc = ()=>{
        let props = {
            id:'fileName',
            myText:{text:''}
        };

        let od = <OpenDocumentDialog document={{}} dataBag={{selection:null}}
                                     close={(d)=>{
                                         //console.log('Close...' + d.button + '| ' + d);
                                         openDialogClosed(d);
                                     }}>
        </OpenDocumentDialog>
        dispatch({type:'dialog', payload:{ctrl:od, verb:'add', ok:()=>{}}});
    }

    const loadData = ()=>{

    }

    const loadDoc = (par, type='')=>{
        if(type == 'P'){//preview
            loadPrintDoc(par);
            return;
        }

        let novy = par == null;
        console.log('loadDoc Hook X');
        console.log(par);
        //console.log(initialConfig);

/*        let copyDoc = {...Cfg,
                documentName: null,
                documentId: -1,
                frameMan:new frameManager(),
                currentPage:1,
                pageCount:1,
                scrollOffset: {x: 0, y: 0},
            };
*/

        let copyDoc = {...initialConfig, frameMan:new frameManager(),
            //dataUrl:Cfg.dataUrl,
            //firmaInfo: Cfg.firmaInfo
        };


        if(par?.car){
            //setAdverb(par.car);
            throw 'Goods nutno upravit....';
            //console.log('Goods nutno upravit....');

            return;
        }

        if(par) {

            if(par.status != 'OK'){
                sessionStorage.clear();
                console.log('Error');
                console.log(par);
                copyDoc.error = {msg:'Dokument nelze otevřít'};
                dispatch({type:'none', payload: copyDoc});
                return;
            }

            let doc = par.doc;

            console.log('OpenDoc:');
            console.log(doc);
            console.log('Cfg');
            console.log(Cfg);

            copyDoc.documentId = doc.id; //v JSON ma být ID
            copyDoc.documentName = doc.nazev;
            copyDoc.pageCount = parseInt(doc.pocet_stranek, 10);
            copyDoc.dataId = doc.data_id;
            copyDoc.firmaInfo = doc.firmaInfo;
            copyDoc.dataUrl = Cfg.dataUrl;
            copyDoc.templateId = doc.templateId;
            copyDoc.pageMode = Cfg.pageMode;
            copyDoc.pageModeExt = Cfg.pageModeExt;
            //copyDoc.dataFields = Cfg.dataFields;
            copyDoc.customFields = Cfg.customFields;
            copyDoc.savedTimestamp = doc.zmeneno;
            copyDoc.copyFrom = doc.kopie_z;
            if(doc.nazev_sablony){
                copyDoc.templateDocumentName = doc.nazev_sablony;
            }
            //copyDoc.alerts = [...Cfg.alerts, {key:'Loaded', level:'success', msg:"Načteno " + doc.nazev}];
            //console.log(doc);
            for (let i = 0; i < doc.frames.length; i++) {
                let frLoadded = doc.frames[i];
                //let nf = new FrameInfo();
                let position = {
                    x: parseInt(frLoadded.x, 10),
                    y: parseInt(frLoadded.y, 10)
                }
                let p = {
                    position: position
                };

                //console.log(`position:`);
                //console.log(position);
                let fr = copyDoc.frameMan.add(p);
                fr.dbId = frLoadded.id;
                fr.dataType = frLoadded.dataType;
                fr.width = parseInt(frLoadded.width, 10);
                fr.height = parseInt(frLoadded.height, 10);

                //fr.realSize.widthByText = frLoadded.widthByText == 1;
                //fr.realSize.heightByText = frLoadded.heightByText == 1;

                let wByText = frLoadded.widthByText == 1;
                let hByText = frLoadded.heightByText == 1;
                //console.log('sByText = ' + (wByText ? 1 : 0) + '; hByText = ' + (hByText ? 1 : 0))
                fr.realSize = {
                    x: position.x,
                    y: position.y,
                    width: fr.width,
                    height: fr.height,
                    widthByText: wByText,
                    heightByText: hByText
                };


                fr.dataType = frLoadded.dataType;
                fr.name = frLoadded.name;
                fr.volName = frLoadded.volname;
                fr.dataName = frLoadded.dataname;
                //fr.content = frLoadded.content;
                fr.title = frLoadded.title;
                //fr.previewText = frLoadded.previewtext;
                fr.font = JSON.parse(frLoadded.font);
                fr.page = parseInt(frLoadded.page, 10);
                fr.border = JSON.parse(frLoadded.border);
                fr.dataId = frLoadded.data_id;
                fr.origId = frLoadded.origId;
                fr.textContent = frLoadded.textContent;

                if(!fr.border){
                    fr.border = {...defaultFrameBorder};
                }

                if(!fr.font){
                    fr.font = {...defaultDocFont};
                }else{

                }
                /*
                console.log('Loaded frame: ' + fr.tempId);
                console.log(fr.realSize);
                 */
            }

        }else{
            console.log('Nova firma...');
            copyDoc.dataUrl = Cfg.dataUrl;
            copyDoc.firmaInfo = Cfg.firmaInfo;
            console.log(Cfg.firmaInfo);
        }

        //console.log("LoadDoc hook A: " + Cfg.dataUrl);
        //console.log(copyDoc);
        dispatch({type:'clearHistory', payload:{}});
        dispatch({type:'loaded', payload:copyDoc});
        //return copyDoc;
    };

    const deleteTemplate= ()=>{
        if(Cfg.documentId > 0){
            alertI.type = AlertTypes.MESSAGE_BOX;
            alertI.buttons = [AlertButtons.NO, AlertButtons.YES];
            let co = (Cfg.templateId > 0 ? 'dokument' : 'šablonu');
            alertI.content = <>Opravdu smazat {co} {Cfg.documentName} (id: {Cfg.documentId})?</>;
            alertI.callBack = (data)=>{
                if(data.closeButton.name == 'yes'){
                    console.log('MAZU...');
                    let pay = {title: "Mažu šablonu " + Cfg.documentName, id: 'Fetch-' + Date.now(), verb: "add"};
                    dispatch({type: "task", payload: pay});
                    let savedata = {action: 'deleteTemplate',
                        documentId:Cfg.documentId,
                        createTime:Cfg.createTime
                    };
                    let refresh = Cfg.templateId > 0 ? true : false;
                    sessionStorage.removeItem('docData')
                    let pp =  fetchJson('deleteTemplate', savedata);
                    pp.promise.then(resp=>resp.json())
                        .then(json=>{
                            if(json.status == 'OK'){
                                console.log('DELETE OK');
                                if(refresh){
                                    //prenactu print dokument
                                    console.log('RELOAD AFTER delete');
                                    document.location.reload();
                                }else{
                                    console.log('LOAD NEW AFTER delete');
                                    loadDoc(null);
                                }
                            }else{
                                throw 'Šablona nebyla smazaná';
                            }
                        })
                        .catch(ex=>{
                            console.error(ex);
                            alertI.type = AlertTypes.MESSAGE_BOX;
                            alertI.buttons = [AlertButtons.OK];
                            alertI.content = <>Chyba při mazání</>;
                            showAlert(alertI);
                        })
                        .finally(()=>{
                            console.log('Finally...');
                            pay.verb = "remove";
                            dispatch({type: "task", payload: pay});
                        })
                }
            };
            console.log('Start DELL...')
            showAlert(alertI);
        }else{
            alertI.type = AlertTypes.MESSAGE_BOX;
            alertI.buttons = [AlertButtons.OK];
            alertI.content = <>Šablona není uložena</>;
            showAlert(alertI);
            return;
        }
    }

    const removePage = (pageNum)=>{
        let alertI ={...AlertInfo};
        //testy
        if(pageNum > Cfg.pageCount){
            alertI.type = AlertTypes.MESSAGE_BOX;
            alertI.buttons = [AlertButtons.OK];
            alertI.content = <>Stránka {pageNum} neexistuje</>;
            showAlert(alertI);
            return;
        }
        if(Cfg.pageCount == 1){
            alertI.type = AlertTypes.MESSAGE_BOX;
            alertI.buttons = [AlertButtons.OK];
            alertI.content = <>Dokument bsahuje pouze 1 stránku</>;
            showAlert(alertI);
            return;
        }

        let framesOnPage = Cfg.frameMan.Frames.filter((f)=>{
            return f.page == pageNum;
        });

        if(framesOnPage.length > 0){
            alertI.type = AlertTypes.MESSAGE_BOX;
            alertI.buttons = [AlertButtons.OK];
            alertI.content = <>
                <div>
                Na stránce {pageNum} jsou rámce.<br/>
                Smažte je, nebo je přesuňte na jinou stránku.
                </div>
            </>;
            showAlert(alertI);
            return;
        }

        alertI.type = AlertTypes.MESSAGE_BOX;
        alertI.buttons = [AlertButtons.NO, AlertButtons.YES];
        alertI.content = <>Opravdu smazat stránku {pageNum}?</>;
        alertI.callBack = (data)=>{
            //console.log('Return: ' + data.closeButton.name);
            if(data.closeButton.name == 'yes'){
                let nRamce = [...Cfg.frameMan.Frames];
                let newC = {...Cfg, pageCount:Cfg.pageCount-1};
                if(pageNum < Cfg.pageCount){
                    for(let fr of nRamce){
                        if(fr.page > pageNum){
                            fr.page--;
                        }
                    }
                }else{
                    newC.currentPage = pageNum - 1;
                }


                newC.frameMan.Frames = nRamce;
                console.log('Posilam Cfg...');
                console.log(newC);
                dispatch({type:'removePage', payload:newC});
            }
        };
        showAlert(alertI);
    }

    const removeFrame = (frame)=>{
        //dispatch({type:'none',payload: {...Cfg, contextMenu:null}});
        let alertI ={...AlertInfo};
        alertI.type = AlertTypes.MESSAGE_BOX;
        alertI.buttons = [AlertButtons.NO, AlertButtons.YES];
        alertI.content = <>Opravdu smazat objekt?</>;
        alertI.callBack = (data)=>{
            //console.log('Return: ' + data.closeButton.name);
            if(data.closeButton.name == 'yes'){
                //dispatch({type:'removeFrame', payload:frame});
                dispatch({type: 'frame', payload:{verb:'remove', tempId:frame.tempId}});
            }
        };
        showAlert(alertI);
    }

    const nextXY = (pos = null, x = null, y = null)=>{

        let newX =  (pos ? pos.x : Editor.lastInsertPosition.x) + (x === null ? 5 :x);
        let newY =  (pos ? pos.y : Editor.lastInsertPosition.y) + (y === null ? 5 :x);
        if(newX > 150){
            newX = 5;
        }
        if(newY > 240){
            newY = 5;
        }
        return {x:newX, y: newY};
    }
    const addFrameInner = (payload)=>{
        if(!payload){
            let newPos = nextXY();
            payload = {page:Cfg.currentPage, position: {x:Editor.lastInsertPosition.x, y:Editor.lastInsertPosition.y}};
            /*
            let newX =  lastInsert.position.x + 5;
            let newY =  lastInsert.position.y + 5;
            if(newX > 190){
                newX = 5;
            }
            if(newY > 280){
                newY = 5;
            }*/
            //setLastInsert({...lastInsert, position: newPos});
            dispatchEditor({type:'lastInsertPosition', payload:newPos});
        }
        //console.log(payload);
        let ff = new FrameInfo();
        console.log('MAX ID: ' + Editor.maxTempId);
        ff.tempId = Editor.maxTempId + 1;
        ff.page = payload.page;
        //console.log('payPage: ' + payload.page + ' VS ' + Cfg.currentPage);
        if(payload && payload.position){
            ff.top = payload.position.y;
            if(payload?.section == true){
                ff.realSize.x = 0;
                //ff.left = 0;
                ff.isSection = true;
            }else{
                //ff.left =payload.position.x;
                ff.realSize.x = payload.position.x;
                ff.realSize.y = payload.position.y;
            }
            if(payload.byFrame){
                //let rs = {...payload.realSize};

                ff = {...payload.byFrame, tempId:ff.tempId, /*left:ff.left, top:ff.top, */dbId:-1, };
                ff.realSize = {...payload.byFrame.realSize};
                ff.realSize.x = payload.position.x;
                ff.realSize.y = payload.position.y;
                ff.page = payload.page;
                /*
                console.log('Paste: ');
                console.log(ff);
                console.log(payload);
                 */
            }
        }
        //Cfg.frameMan.Frames.push(ff);

        //this.Frames.push(ff);
        //console.log(ff);
        return ff;
    }

    const addFrames = (payloadPar)=>{
        let ret = [];
        let ids = Cfg.frameMan.Frames.map(f=>{return f.tempId});
        console.log(ids);
        let maxId = ids.length > 0 ? Math.max(...ids) : 0;
        if(!maxId){
            maxId = 1;
        }else {
            maxId++;
        }
        //console.log('MaxId: ' + maxId);
        let lastPos = nextXY(Editor.lastInsertPosition, 0);

        payloadPar.forEach(p=>{
            let ff =addFrameInner(null);
            ff = {...ff, ...p};
            console.log('ADD RANGE X');
            console.log(ff);
            console.log(p);
            ff.tempId = maxId++;
            let pp =  lastPos;
            ff.realSize.x = pp.x;
            ff.realSize.y = pp.y;
            lastPos = nextXY(pp, 0);
            ret.push(ff);
        })
        //console.log('Vkladam Range...');
        //console.log(ret);
        //setLastInsert({...lastInsert, position: lastPos});
        dispatchEditor({type:'lastInsertPosition', payload:lastPos});
        dispatch({type:'frame', payload:{verb:'addRange', frames:ret}});
        return ret;
    }

    const addFrame = (payloadPar)=>{
        //setMaxFrameId(maxFrameId + 1);
        //console.log('AddFrame');
        //console.log(payload);

        let ff = addFrameInner(payloadPar);
        dispatch({type:'frame', payload:{verb:'add', frame:ff}});
        return ff;
    }


    const addPage = ()=>{
        let pageInfo = {...pageInfo};
        pageInfo.pageId = Cfg.firmaInfo.kodFirmy + "#" + (new Date()).getTime();
    }

    const frameDescription = (frame)=>{
        let desc = '';
        switch (frame.dataType){
            case FrameTypes.TEXT:
                desc = frame.textContent;
                break;
            case FrameTypes.DATA:
                desc = frame.title ? frame.title : frame.dataName;
                break;
            case FrameTypes.CUSTOM:
                desc = frame.title ? frame.title : frame.volName;
                break;
        }
        if(desc && (desc.length > 10)){
            desc = desc.substring(0, 10) + '...';
        }
        return '(' + frame.tempId + ') [' + frame.dataType + '] ' + desc;
    }

    useEffect(()=>{
        //console.log('Effect Frames...');
    }, [Cfg.frameMan.Frames]);

    //return {loadDoc: loadDoc, saveDoc:saveDoc};
    return [loadDoc, saveDoc, {
        removeFrame:removeFrame,
        removePage:removePage,
        addFrame:addFrame,
        addFrames:addFrames,
        frameDescription:frameDescription,
        openDoc:openDoc,
        openById:openDialogClosed,
        deleteTemplate:deleteTemplate
    }];
}