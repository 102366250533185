import {useEffect, useState} from "react";

export const useFade = (initial, onEnd, timeout) => {
    const [show, setShow] = useState(initial);
    const [time, setTime] = useState(timeout);
    const [isVisible, setVisible] = useState(show);

    // Update visibility when show changes
    /*
    useEffect(() => {
        if (show){
            setVisible(true);
        }
    }, [show]);*/

    //let vis = true;
    // When the animation finishes, set visibility to false
    const onAnimationEnd = () => {
        //console.log('onAnimationEnd...' + isVisible + ': ' + show);
        //vis = false;
        if(onEnd){
           // console.log('Volam on End: ' + isVisible);
            onEnd(show);
        }
        if (!show){
            setVisible(false);
        }
    };

    const style = {animation: `${show ? "fadeIn" : "fadeOut"} 15s` };
    //if(!vis){
        //style.padding = '100px';
    //}

    // These props go on the fading DOM element
    const fadeProps = {
        style,
        onAnimationEnd
    };

   // console.log('Hook State...');

    return [isVisible, setShow, fadeProps];
};