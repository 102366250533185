import {useContext, useEffect, useState} from "react";
import {DocumentContext, EditorContext} from "../App";

export const DragFake = (props) => {
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);

    const [startPos, setStartPos] = useState({x:null, y:null});

    useEffect(()=>{
      //  console.log('')
//        console.log(props);
        setStartPos(props.frame.realSize);
    }, [])


    useEffect(()=>{

    }, [Editor.dragVector])

    //console.log('DragFrame PAINTX....');
    //console.log(startPos);



    return <>
        <div className={'fakeDrag'} style={{position:'absolute',
            left:(startPos.x + Cfg.pageOffset.x + (Editor.dragCurrentPos.x - Editor.dragStartPos.x)) + 'mm',
            top:(startPos.y + Cfg.pageOffset.y+ (Editor.dragCurrentPos.y - Editor.dragStartPos.y)) +  'mm',
            width:startPos.width + 'mm', height:startPos.height + 'mm', zIndex:50}}>&nbsp;</div>
    </>
}