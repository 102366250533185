import * as React from 'react';
//import {Children, useEffect, useState} from "react";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {PositionTab} from "./FrameProperties/PositionTab";
import {FrameTypes} from "../structs/FrameTypes";
import {DocumentContext} from "../App";
import {DataTab} from "./FrameProperties/DataTab";
import {FontsTab} from "./FrameProperties/FontsTab";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                   {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const TabBox = ({zmena})=>{
    const [value, setValue] = useState(0);
    const form = useRef();
    const [Cfg, dispatch] = useContext(DocumentContext);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let sel = Cfg.frameMan.Frames.find((f)=>{return f.tempId == Cfg.frameMan.Selected[0];});
    /*
    const formChanged = ()=>{
        console.log('Form Changed tabb...');
        if(sel != null) {
            let dataSel = form.current.elements.selData;
            let volSel = form.current.elements.selVolitelne;
            //console.log('form changed... ' + dataSel.options[dataSel.selectedIndex].value);
            let oldType = sel.dataType;
            sel.dataType = form.current.elements.typDat.value;
            if (sel.dataType == oldType) {
                sel.left = parseInt(~~form.current.elements.posLeft.value ?? 0, 10);
                sel.top = parseInt(~~form.current.elements.posTop.value ?? 0, 10);
                sel.width = parseInt(~~form.current.elements.posWidth.value ?? 0, 10);
                sel.height = parseInt(~~form.current.elements.posHeight.value ?? 0, 10);
                switch (sel.dataType) {
                    case FrameTypes.TEXT:
                        sel.content = form.current.elements.myText.value;
                        break;
                    case FrameTypes.DATA:
                        sel.dataName = dataSel.options[dataSel.selectedIndex ?? 0 ].value;
                        sel.title  = dataSel.options[dataSel.selectedIndex ?? 0 ].text;
                        break;
                    case FrameTypes.CUSTOM:
                        sel.volName = volSel.options[volSel.selectedIndex ?? 0].value;
                        sel.title  = volSel.options[volSel.selectedIndex ?? 0 ].text;
                        break;
                }
            }

            dispatch({type: 'frame', payload: sel});
        }
    };*/
/*
    useEffect(()=>{
        console.log("Zmena TabBox: ");
        console.log(zmena);
    }, [zmena]);
*/
    return <>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Údaje" {...a11yProps(0)} />
                    <Tab label="Vzhled" {...a11yProps(1)} />
                    <Tab label="Pozice" {...a11yProps(2)} />

                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <DataTab/>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <FontsTab/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PositionTab/>
            </TabPanel>

        </Box>
    </>
}