import {useContext, useState} from "react";
import {DocumentContext} from "../App";
//import {InputName} from "../components/Alerts/InputName";

export const AlertTypes = {
    MESSAGE_BOX:'messageBox',
    CONFIRM:'confirm',
    INPUT:'input'
}

export const AlertButtons = {
    OK:{text:'OK', name:'ok'},
    NO:{text:'Ne', name:'no'},
    YES:{text:'Ano', name:'yes'},
    CANCEL:{text:'Zavřít', name:'cancel'}
}
/*
export const AlertData = {
    closeButton:AlertButtons.CANCEL,
    returnData:{}
}
*/

export const AlertInfo = {
    content:'',
    /** @var AlertTypes type */
    type:AlertTypes.MESSAGE_BOX,
    callBack:(data)=>{},
    callBackAsync:null,
    returnData:{},
    buttons:[AlertButtons.OK],
    closeButton:AlertButtons.CANCEL,
}

export const UseAlert = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
   // const [alerts, setAlerts] = useState([]);

    const showAlert = (info)=>{
        info.sysId = '#' + new Date().getTime();
        //console.log('SysId: ' + info.sysId);
        //console.log(Cfg);
        //let alerts = [...Cfg.activeAlerts, info];
        switch (info.type){
            case AlertTypes.INPUT:
                /*
                if(!info.content){
                    info.content = <InputName info={info}></InputName>
                }*/
                break;
        }

        dispatch({type:'modal', payload:{info:info, verb:'add'}});
    }


    const buttonClick = async (ev, alertI, button)=>{
        //console.log('Klik : ' + button.name);
        alertI.closeButton = button;
        let clb = null;
        if(alertI.callBack) {
            clb = alertI.callBack(alertI);
        }
        if(alertI.callBackAsync){
            console.log('Volam async');
            clb = await alertI.callBackAsync(alertI);
            console.log('Po Async');
            console.log(clb);
        }
        if(clb?.noClose){
            console.log('NoClose....' + clb.msg);
            return;
        }

        //console.log('After callback....');
        /*
        let newAlerts = Cfg.activeAlerts.filter((a)=>{
            return a.sysId != alertI.sysId;
        });
         */
        //console.log('Klik 2 : ' + button.name);
        dispatch({type:'modal', payload:{verb:'remove', info:alertI}});
    }

    const renderAlerts = ()=>{
        return (Cfg.activeAlerts?.length ?? 0)  > 0 ? (<div className={'modalBackground'}>
                {Cfg.activeAlerts.map((a, index)=>{
                    let btns = '';
                    switch (a.type){
                        case AlertTypes.INPUT:
                        case AlertTypes.CONFIRM:
                        case AlertTypes.MESSAGE_BOX:
                            btns = <div className={'buttons'}>
                                {
                                    a.buttons.map((b, index)=>{
                                        return <button onClick={(ev)=>{buttonClick(ev, a, b)}} key={index}>{b.text}</button>
                                    })
                                }
                            </div>
                            break;
                    }
                    return <div key={index}  className={'dialog'}><div >{a.content}{btns}</div></div>
                })}
        </div>) : ''
    }
    return [showAlert, renderAlerts];
}
