import {useContext, useEffect, useRef, useState} from "react";
import {DocumentContext, EditorContext} from "../App";
import {DragHandle} from "./DragHandle";
import {useDragAndDrop} from "../hooks/useDragAndDrop";
import {usePagePosition} from "../hooks/usePagePosition";

export function DraggFrame(props){
    //const {child, innerItem, show, ...other} = props;
    const [dragComp, setDragComp] = useState(null);
    const [innerComp, setInnerComp] = useState(null);
    //const [innerBound, setInnerBound] = useState(null);
    const innerBound = useRef(null);
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
    const dragHandle = useRef({});
    const handleL = useRef(null);
    const handleT = useRef(null);
    const handleR = useRef(null);
    const handleB = useRef(null);
    let dragG = null;
    //const [xL, yL, moveL] = useDragAndDrop('L');
    const [mousePos, setMousePos, setPosXY] = usePagePosition();
    const [draggedDir, setDraggedDir] = useState(null);





    useEffect(()=>{
        dragG = props.innerChild;
        if(props.innerChild) {
            setDragComp(props.innerChild);
            //console.log(props.innerChild);
            //console.log(props.innerChild.clientWidth)
        }
        if(props.innerComp && props.innerChild){
            let posOrig = props.innerChild.getBoundingClientRect();
            //console.log('posOrig start');
//            console.log(posOrig);
//            console.log('posOrig end');
            //pos.left += window.scroll
            // X;
            let pos = {left:posOrig.x + window.scrollX, top: posOrig.y + window.scrollY,
                width: posOrig.width, height: posOrig.height};
            //setInnerBound(pos);
            innerBound.current = pos;
    //        mysStyle.left = pos.left + 'px';
  //          console.log('innerComp...' + pos.left + '::: ' + Cfg.scrollOffset.y);
            setInnerComp(props.innerChild);
        }
    },[props.innerComp]);

    let bound = {};
    if(dragComp){
        bound = dragComp.getBoundingClientRect();
    }

    let half = 3;
    let styleTemplate = {
        position:'absolute',
        backgroundColor: 'black',
        width: (half*2) + 'px',
        height: (half*2) + 'px',
        zIndex:100,
        cursor:'move'
    };


    const mysStyleL = {...styleTemplate,
        left: innerBound.current ? (innerBound.current.left - half) + 'px' : '0px',
        top: innerBound.current ? (innerBound.current.top + innerBound.current.height/2 -half) + 'px' : '0px',
    }

    const mysStyleB = {...styleTemplate,
        left: innerBound.current ? (innerBound.current.left + innerBound.current.width/2 - half) + 'px' : '0px',
        top: innerBound.current ? (innerBound.current.top + innerBound.current.height  -half) + 'px' : '0px',
    }

    const mysStyleR = {...styleTemplate,
        left: innerBound.current ? (innerBound.current.left + innerBound.current.width - half) + 'px' : '0px',
        top: innerBound.current ? (innerBound.current.top + innerBound.current.height/2  -half) + 'px' : '0px',
    }

    const mysStyleT = {...styleTemplate,
        left: innerBound.current ? (innerBound.current.left + innerBound.current.width/2 - half) + 'px' : '0px',
        top: innerBound.current ? (innerBound.current.top  -half) + 'px' : '0px',
    }

    const dragStart = (e)=>{
        //let dir = e.target.dataset;

        let dir2 = e.target.getAttribute('data-direction');
        //console.log(dir2 + ' DragStart: ' + dir2);
        setMousePos(e, true);
        var img = new Image();
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
        e.dataTransfer.setDragImage(img, 0, 0);
        let rs = {...props.frame.realSize};
        switch (dir2){
            case 'R':
                rs.widthByText = false;
                break;
            case 'B':
                rs.heightByText = false;
                break;
        }
        let pay = {...props.frame, realSize:rs};
        dispatch({type: 'frame', payload: {frame:pay, verb:'update'}});


        //dispatch({type:'property', payload:{propertyName:'dxragging', value:true}});
        dispatchEditor({type:'dragging', payload:true});
        setDraggedDir(dir2);
        switch (dir2){
            case 'L':
//                console.log(props.frame);
                break;
        }
    }




    const mouseOver = (ev)=>{
        ev.target.style.backgroundColor = 'red';
    };

    const mouseOut = (ev)=>{
        ev.target.style.backgroundColor = 'black';
    };

/*
    const dragCapture = ()=>{
        console.log('Drag capture...');
    };
*/
    const drag = (e)=>{
        let dir2 = e.target.getAttribute('data-direction');
        //console.log(dir2 + ' Drag...');
        //Cfg.clientToMM(e.x, e.y);
        setMousePos(e, false);

        //console.log('X1: ' + mousePos.x + '; Y1: ' + props.frame.width + '; Delta***: ' + mousePos.delta.x);
        let rect = props.innerChild.getBoundingClientRect();
        let posx = setPosXY(rect.x, rect.y, rect.width, rect.height);



        switch (dir2){
            case 'L': {
                let delta = posx.x - mousePos.x;
                let ww = Math.round(delta + posx.width);// mousePos.delta.x; //props.frame.width -
                //console.log('***** Delta: ' + mousePos.delta.x + '; ODL W: ' + props.frame.width + '; NEW---: ' + ww);
//                console.log(props.frame);
                let pay = {...props.frame, left: mousePos.x, width: ww};
                //console.log('PayL: ' + pay.width + '|' + ww);
                dispatch({type: 'frame', payload: {frame:pay, verb:'update'}});
                }
                break;
            case 'B': {
                    let delta = mousePos.y - (posx.y + posx.height);
                    let hh = Math.round(delta + posx.height);// mousePos.delta.x; //props.frame.width -
                    //console.log('***** Delta B: ' + mousePos.delta.y + '; ODL W: ' + props.frame.height + '; NEW---: ' + hh);
                    let rs = {...props.frame.realSize};
                    rs.height = hh;
                    let pay = {...props.frame, height: hh, realSize: rs};
                    //console.log('Pay: ' + pay.height);
                    dispatch({type: 'frame', payload: {frame:pay, verb:'update'}});
                }
                break;
            case 'R': {
                    let delta = mousePos.x  - (posx.x + posx.width);
                    let ww = Math.round(delta + posx.width);// mousePos.delta.x; //props.frame.width -
                    //console.log('***** Delta: ' + mousePos.delta.x + '; ODL W: ' + props.frame.width + '; NEW---: ' + ww);
                    //console.log(props.frame);
                    let rs = {...props.frame.realSize};
                    rs.width = ww;
                    let pay = {...props.frame, width: ww, realSize: rs};
                    //console.log('PayR: ' + pay.width);
                    //dispatch({type: 'frame', payload: pay});
                    dispatch({type: 'frame', payload: {frame:pay, verb:'update'}});
                }
                break;
        }

    };

    const drop = (e)=>{
        //dispatch({type:'property', payload:{propertyName:'dragging', value:false}});
    };

    const dragEnd = ()=>{
        setDraggedDir(null);
        //dispatch({type:'property', payload:{propertyName:'dxragging', value:false}});
        dispatchEditor({type:'dragging', payload:false});
        //console.log('DRAG end....');
    }


    const attrs = {
  //      onClick:(ev)=>onClick(ev),
        onDragStart:dragStart,
        //onDragOver:dragOver,
       //onDragEnter:(ev)=>{dragEnter(ev);},
        onMouseOver:(ev)=>mouseOver(ev),
        onMouseOut:(ev)=>mouseOut(ev),
        //draggable:true,
        //onDragCapture:dragCapture,

        //onDragStartCapture:mouseMove,
        //onPointerDown:mouseDown,
        //onPointerUp:mouseUp,
        //onPointerMove:
        //onMouseMove:mouseMove,
        onDrag:drag,
        onDrop:drop,
        onDragEnd:dragEnd
    };


    const mouseUp = ()=>{

    }

    const mouseDown = (ev)=>{
        //console.log('Mouse DOWN...DRAG FRAME');
        ev.stopPropagation();
    }

    //console.log('DragedDir: ' + draggedDir);
    return <>
        <div {...attrs} draggable={true} ref={handleR} onMouseDown={ev=>mouseDown(ev)} onMouseUp={()=>{mouseUp()}} key={'dragR'} data-dr={'dragframe'} data-direction={'R'} style={mysStyleR}>&nbsp;</div>

        <div {...attrs} draggable={true} ref={handleB} onMouseDown={ev=>mouseDown(ev)} onMouseUp={()=>{mouseUp()}} key={'dragB'} data-dr={'dragframe'} data-direction={'B'} style={mysStyleB}>&nbsp;</div>
        {/*
        <div {...attrs} draggable={true} ref={handleL} key={'dragL'} data-dr={'dragframe'} data-direction={'L'} style={mysStyleL}>&nbsp;</div>
        <div {...attrs} draggable={true} ref={handleT} key={'dragT'} data-dr={'dragframe'} data-direction={'T'} style={mysStyleT}>&nbsp;</div>
        */}
        {draggedDir == 'R' &&
            <div style={{
                width:'1px',
                zIndex:10,
                height:'297mm',
                backgroundColor:'rgb(255, 0,0, 0.2)',
                position:'absolute',
                left:innerBound.current.left + innerBound.current.width + 'px',
                top:Cfg.pageOffset.y + 'mm'
            }}></div>
        }
    </>
}