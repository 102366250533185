import {initialConfig} from "./InitialConfig";
import {frameManager} from "./components/FrameManager";
import {InputName} from "./dialogs/InputName";
import {useContext} from "react";
import {DocumentContext} from "./App";
import {AlertButtons, AlertInfo, AlertTypes} from "./hooks/useAlert";
import {forEach} from "react-bootstrap/ElementChildren";

export class  AppReducer2{
    constructor() {
        this.LoadState = null;
        this.ApiUrl = null;
        this.singleCalls = {};
    }
    reducerSwitchProxy (state, action){
        //console.log('Firma Info....');
        //console.log(state.firmaInfo);
        let ret = this.reducerSwitch(state, action);
        if(state.firmaInfo?.kod_firmy){
            let saveObj = {...ret, dialogs:[], tasks:[]};
            this.addHistory();
            //SCROLL OFFSET
            sessionStorage.setItem("docData", JSON.stringify(saveObj));
        }
        return ret;
    }
    hashCode(str){
        var hash = 0,
            i, chr;
        if (!str || str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    clearHistory(){
        try {
            let keys = this.getVersionKeys();
            keys.forEach(k => {
                sessionStorage.removeItem(k.name);
            });
        }catch (e){
            console.error(e);
        }
    }

    addHistory(){
        try {
            let keys = this.getVersionKeys();
            // console.log('Add history...');
            // console.log(keys);
            let max = 1;

            let delCount = keys.length - 10;
            if (delCount > 0) {
                for (let i = 0; i < delCount; i++) {
                    sessionStorage.removeItem(keys[i].name);
                }
            }

            keys.forEach(k => {
                if (k.version > max) {
                    max = k.version;
                }
            });
            let newName = 'hist-' + (max + 1);
            let akt = sessionStorage.getItem('docData');
            let hash = this.hashCode(akt);

            if (max > 0) {
                let lastHash = sessionStorage.getItem('histHash');
                if (lastHash == hash) {
                    return;
                }
            }
            if (akt) {
                //console.log('History write...');
                sessionStorage.setItem(newName, akt);
                sessionStorage.setItem('histHash', hash);
            }
        }catch (e){
            console.error(e);
        }

        //console.log(max);
        //console.log(keys);
    }

    getVersionKeys(){
        let keys = Object.keys(sessionStorage);

        let histKeys = keys.filter(k=>{
            return  k.startsWith('hist-');
        });
        let ret = histKeys.map(k=>{
            let vers = k.split('-', 2);
            if(vers.length > 1){
                let info = {
                    name: k,
                    version: parseInt(vers[1])
                };
                return info;
            }
        });
        return ret.sort((a, b)=>{
            if(a.version == b.version){
                return 0;
            }
            return a.version < b.version ? -1 : 1
        })
    }

   reducerSwitch (state, action){
        let fm1 = {...state};
        let payload = action.payload;
        let idFrame = 0;

        /*
        console.log('Jsem ve switchi...');
        console.log(state);
        console.log(action);
        console.log('... jsem ve switchi');
         */
        switch (action.type){
            case 'clearHistory':
                this.clearHistory();
                return state;
            case 'singleCall':
                let singleCallKey = payload.key;
                if(!this.singleCalls[singleCallKey]){
                    this.singleCalls[singleCallKey] = {};
                    payload.call();
                    /*
                    console.log('PoCall...' + singleCallKey);
                    if(singleCallKey && singleCallKey.includes('**')){
                        let findThem = singleCallKey.split('**')[0] + '**';
                        console.log('Hledam: ' + findThem);
                        let ft = Object.keys(this.singleCalls).filter(sck=>sck.startsWith(findThem) && sck != singleCallKey);
                        console.log('Nalezeno: ' + findThem);
                        ft.forEach(t=>{
                            console.log('MAZU ' + t);
                            delete this.singleCalls[t];
                        });
                        console.log(ft);
                        console.log(this.singleCalls);
                    }
                    */
                }
                return state;
                break;
                /*
            case 'checkToken':
                let urls = new URL(document.location.href);
                let token = urls.searchParams.get('token');
                //console.log('CfgToke> ' + Cfg.token);
                fetch('checkToken?token=' + token, {user:{token:token}}).promise
                    .then(resp=>resp.json())
                    .then(json=>{
                        action.payload.call(json);
                    });
                return state;
                 */
            case 'getDataUrl':
                if(this.LoadState === null){
                    this.LoadState = 'URL';
                    let urls = new URL(document.location.href);
                    console.log('get data URL...');
                    console.log(urls);

                    let urlEnd = '/api/fetchUrl.json';
                    let apiUrl = '';
                    switch (urls.host){
                        case 'dokumenty-editor.lan.ebm.cz':
                            console.log('get data URL 2...');
                            apiUrl = urls.origin + urlEnd;
                            break;
                        default:
                            let url1 = urls.origin + urlEnd;
                            apiUrl =  url1;//urls.origin + urlEnd;//'.' + urlEnd;
                            break
                    }
                    let token = urls.searchParams.get('token');
                    console.log(apiUrl);
                    fetch(apiUrl + '?' + (new Date()).getTime())
                        .then((resp)=>resp.json())
                        .then((json)=>{
                            action.payload.call(json);
                            //dispatch({type:'none', payload:{...Cfg, basename:basename, dataUrl:json.dataUrl, token:token}});
                        })
                        .catch((ex)=>{
                            console.error(ex);
                        });
                }
                //console.log('LoadState ' + this.LoadState);
                return state;
                break;
            case 'systemInfo':
                //console.log('SystemInfo: ' + payload.value);
                fm1.systemInfo[payload.propertyName] = payload.value;
                break;
            case 'properties':
                //console.log('properties');
                //console.log(payload);
                for(let p in payload){
                    fm1[p] = payload[p];
                }
                return fm1;
                break;
            case 'property':
                if(payload.propertyName){
                    fm1[payload.propertyName] = payload.value;
                }else{
                    fm1[payload.name] = payload.value;
                }

                return fm1;
                break;
            case 'activeContextMenu':
                switch (payload.verb){
                    case 'add':
                        return {...state, activeContextMenu: payload.menu};
                        break;
                    case 'close':
                        return {...state, activeContextMenu: null};
                        break;
                }

                break;
            case 'modal':
                switch (payload.verb){
                    case 'add':{
                            let alerts = [...state.activeAlerts, payload.info];
                            return {...state, activeAlerts: alerts};
                         }
                    case 'remove': {
                        let alerts = state.activeAlerts.filter((a) => {
                            return a.sysId != payload.info.sysId;
                        });
                        return {...state, activeAlerts: alerts};
                        }
                }
                break;
            case 'pageMode':
                return {...fm1, pageMode:payload};
                break;
            case 'user':
                return {...fm1, firmaInfo: payload.firma};
                break;
            case 'removeFrame':
                return this.removeFrame(state, action)
            case 'addSection':
            case 'addFrame':
                return this.addFrame(state, action);
            case 'save':
                return this.save(state,action);
                /*
            case 'scroll': {
                return {...fm1, scrollOffset: payload};
            }*/
            case 'selRange':
                console.log('SELECT RANGE...');
                console.log(payload);
                fm1.frameMan.Selected = payload;
                return fm1;
                break;
            case 'sel':
                //console.log('SELECT: ' + action.payload.tempId);
                if(fm1.frameMan.Selected.length > 0){
                    if(fm1.frameMan.Selected[0].tempId == payload.tempId){
                        fm1.frameMan.Selected = [];// = {...fm1.frameMan.se}
                        return fm1;
                    }
                }
                if(payload == null || payload < 1){
                    fm1.frameMan.Selected = [];
                }else {
                    fm1.frameMan.Selected = [payload]; // = {...fm1.frameMan.se}
                }
                return  fm1;
                break;
            case 'customs': {
                fm1.customFields = payload;
                return fm1;
            }
            case 'carFields': {
                throw 'car Fields vynato z AppContextu';
                console.log('carFields...')
                console.log(payload);
                fm1.dataFields = payload;
                return fm1;
                break;
            }
            case 'loaded':
                //console.log('LOADED...');
                return payload;
            case 'dialog':{
                switch (payload.verb){
                    case 'add':
                        fm1.dialogs = [...fm1.dialogs, payload.ctrl];
                        break;
                    case 'close':
                        // console.log('Remove ' + payload.id);
                        fm1.dialogs = fm1.dialogs.filter((d)=>{return d.props.id != payload.id})
                        //console.log(fm1);
                        return {...fm1}
                    default:
                        //console.log('Neplatná action pro dialog (' + payload.verb + ')');
                        return state;
                }
                //console.log(fm1.dialogs);
                return fm1;
            }
            case 'task':{
                //console.log('AddTask...' + payload.action + '; id: ' + payload.id);
                let ret = fm1;
                switch (payload.verb) {
                    case 'add': {
                        let tsts = [...fm1.tasks, payload];
                        ret = {...fm1, tasks: tsts};
                        //payload.pokus = 'abcd...';
                        return ret;
                    }
                    case 'remove': {
                        //  console.log(fm1.tasks);
                        let tsts = fm1.tasks.filter((t)=>{
                            //    console.log(t.id + ' vs ' + payload.id);
//                console.log(t);
                            return t.id !== payload.id;
                        });
                        ret = {...fm1, tasks: tsts};

                        return ret;
                    }
                }
                return ret;
            }
            case 'edit':
                //let fm1 = {...state};
                let id = payload.frame.tempId;

                //console.log('Change id: ' + id);
                fm1.frameMan.Frames = fm1.frameMan.Frames.map((f)=> {
                        if (f.tempId === id) {
                            return payload.frame;
                        } else {
                            return f;
                        }
                    }
                );
                switch(payload.verb){
                    case 'change':
                        if(fm1.frameMan.Selected.includes(id)){
                            fm1.frameMan.Selected = fm1.frameMan.Selected.filter((s)=>{
                                return s != id;
                            });

                        }else{
                            fm1.frameMan.Selected = [...fm1.frameMan.Selected, id];
                        }
                        break;
                    case 'set':
                        fm1.frameMan.Selected = [id];
                        break;
                    case 'add':
                        fm1.frameMan.Selected = [...fm1.frameMan.Selected, id];
                        break;
                    case 'end':
                        break;
                }
                return fm1;
                break;
            case 'newDocument': {
                let ret = {...initialConfig, frameMan: new frameManager()};
                //console.log(ret.pageKoefX);
                return ret;
            }

                break;
            case 'NONE':
            case 'none':
            case 'None':
                if(payload) {
/*
                    console.log('NONE dispatch...');
                    console.log(payload);
                    console.log('---');
                    console.log(payload);
*/
                    return {...payload};
                }
                return  fm1;
                break;
            case 'removePage':
                if(payload) {
                    /*
                    console.log('RemovePage dispatch...');
                    console.log(payload);
                    console.log('---');
                     */
                    return {...payload};
                }
                break;
            case 'frames':
                fm1.frameMan.Frames = payload;
                return fm1;
                break;
            case 'frame':
//                FRAME Sjednotit
                //console.log('Frame OPT: ');
                //console.log(payload.frame);
                idFrame = payload.tempId ?? -1;
                if(idFrame < 0){
                    idFrame = payload.frame?.tempId ?? -1;
                }
                //console.log('Frame Id: ' + idFrame + '; verb: ' + payload.verb);
                switch(payload.verb){
                    case 'addRange':
                        fm1.frameMan.Frames = fm1.frameMan.Frames.concat(payload.frames);
                        fm1.frameMan.Selected = [];
                        console.log('Reducer Frames');
                        console.log(fm1.frameMan.Frames);
                        return fm1;
                        break;
                    case 'add':
                        fm1.frameMan.Frames = [...fm1.frameMan.Frames, payload.frame];
                        fm1.frameMan.Selected = [payload.frame.tempId];
                        //console.log('Add frame...');
                        //console.log(payload.frame);
                        return fm1;
                        break;
                    case 'realSize':
//                        console.log('Realsize changed...');
//                        console.log(payload);
                        fm1.frameMan.Frames = fm1.frameMan.Frames.map((f)=> {
                                if (f.tempId === idFrame) {
                                    //console.log('realsize byId ID: ')
                                    //let ret = {...payload.frame, realSize:payload.frame.realSize};
/*
                                    if(payload.frame.realSize.width > 20 && payload.frame.realSize.x + payload.frame.realSize.width > 210){
                                        payload.frame.realSize.width = 210 - payload.frame.realSize.x;
                                        if(payload.frame.realSize.width < 20){
                                            payload.frame.realSize.width = 20;
                                        }
                                        payload.frame.realSize.widthByText = false;
                                    }
                                    if(payload.frame.realSize.height > 20 &&  payload.frame.realSize.y + payload.frame.realSize.height > 297){
                                        payload.frame.realSize.height = 297 - payload.frame.realSize.y;
                                        payload.frame.realSize.heightByText = false;
                                        if(payload.frame.realSize.height < 20){
                                            payload.frame.realSize.height = 20;
                                        }
                                    }
*/
                                    f.realSize = payload.frame.realSize;

                                    //console.log(payload.frame.realSize);
                                    return f;// payload.frame;
                                } else {
                                    return f;
                                }
                            }
                        );
                        return fm1;
                        break;
                    case 'update':
                        fm1.frameMan.Frames = fm1.frameMan.Frames.map((f)=> {
                                if (f.tempId === idFrame) {
                                    /*
                                    console.log('Nastavuji frame... Reducer');
                                    console.log(payload);
                                    console.log(payload.frame.realSize);
                                     */
                                    return payload.frame;
                                } else {
                                    return f;
                                }
                            }
                        );

                        if(!fm1.frameMan.Selected.includes(idFrame)){
                            fm1.frameMan.Selected = [idFrame];
                        }
                        return fm1;
                        break;
                    case 'remove':
                        //console.log('Pocet pred smazanim: ' + fm1.frameMan.Frames.length);
                        let frms = fm1.frameMan.Frames.filter((f)=>{return f.tempId != payload.tempId});
                        fm1.frameMan.Frames = frms;
                        //console.log('Pocet po smazani: ' + fm1.frameMan.Frames.length);
                        fm1.frameMan.Selected = [];
                        return fm1;
                }



                let newFrs = fm1.frameMan.Frames.map((f)=> {
                    if (f.tempId === idFrame) {
                        //console.log("VRACIM PAYLOAD: " + payload.width);
                        //console.log(payload);
                        return payload;
                    } else {
                        return f;
                    }
                });
                fm1.frameMan.Frames = [...newFrs];
                //console.log('FRAME CHANGED: ');
                //console.log(payload);
                //fm1.frameMan.Selected = [idFrame];
                return fm1;
                break;

        }
        return state;
    };

    addFrame(state, action){
       let fm = {...state};

        let fr = fm.frameMan.add(action.payload);
        
        fm.frameMan.Selected = [fr.tempId];
        let ret = fm;
        // {...state, frameMan: {...state.frameMan, Frames:frams}};
        return ret;
    }
/*
    removeFrame(state, action){
        //console.log('removeFrame...')
        let frms = state.frameMan.Frames.filter((f)=>{return f.tempId != action.payload.tempId});
        state.frameMan.Frames = frms;

        state.frameMan.Selected = [];
        return {...state}

    }
*/
    save (state, action){
        alert('Uložit lze v menu dokumentu ' + (state.documentName));
        return state;
    }
}

export const GlobalReducer = new AppReducer2();





