import {useContext, useEffect, useRef, useState} from "react";
import {DocumentContext} from "../../App";
import {defaultFrameBorder} from "../../structs/FrameInfo";
import {ListItem} from "@mui/material";

export const FontsTab = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const form  = useRef();
    const [sel, setSel] = useState(null);

    useEffect(()=>{
        //console.log('useEffrct length: ' + Cfg.frameMan.Selected.length);
        if(Cfg.frameMan.Selected.length > 0){
            //console.log('useEffrct: 1');
            setSel(Cfg.frameMan.Frames.find((f)=>{return f.tempId == Cfg.frameMan.Selected;}));
//            console.log('Zmena sel: ' + sel)
        }else{
            setSel(null);

        }
    }, [Cfg.frameMan.Frames]);

    const formChanged = ()=>{
        if(sel) {
            let fontSize = parseFloat(form.current.fontSizeInp.value.replace(',', '.') || 0, 10);
            let bold = form.current.boldCHB.checked;
            let italic = form.current.italicCHB.checked;
            let align = form.current.alignSel.value;
            let valign = form.current.vAlignSel.value;
            let family = form.current.fontFamilySel.value;
            let font = {...sel.font};


            let changed = false;
            if (family != sel.font.family) {
                sel.font.family = family;
                changed = true;
            }

            if (fontSize != sel.font.fontSize) {
                sel.font.size = fontSize;
                changed = true;
            }
            if (bold != sel.font.bold) {
                sel.font.bold = bold;
                changed = true;
            }
            if (italic != sel.font.italic) {
                sel.font.italic = italic;
                changed = true;
            }
            if (align != sel.font.align) {
                //console.log('Form changed...: ' + align);
                sel.font.align = align;
                changed = true;
            }
            if (valign != sel.font.valign) {
                //console.log('Form changed...: ' + align);
                sel.font.valign = valign;
                changed = true;
            }
            if (changed) {
                //console.log('Form changed dispatch');
                let fr = {...sel, font: font};
                dispatch({type: 'frame', payload: {...sel}});
            }
        }
    }

    const borderClick = (ev, borderPar)=>{
        //console.log('Border click: ' + borderPar);
        let border = {...sel.border};
        switch (borderPar){
            case 'none':
                border.left = false;
                border.top = false;
                border.right = false;
                border.bottom = false;
                break;
            case 'all':
                border.left = true;
                border.top = true;
                border.right = true;
                border.bottom = true;
                break;
            case 'left':
                border.left = !border.left;
                break;
            case 'top':
                border.top = !border.top;
                break;
            case 'right':
                border.right = !border.right;
                break;
            case 'bottom':
                border.bottom = !border.bottom;
                break;
        }
        dispatch({type:'frame', payload:{frame:{...sel, border:border}, verb:'update'}});
    };

    const listFonts = ()=>{
        let { fonts } = document;
        const it = fonts.entries();

        let arr = [];
        let done = false;

        while (!done) {
            const font = it.next();
            if (!font.done) {
                arr.push(font.value[0].family);
            } else {
                done = font.done;
            }
        }

        // converted to set then arr to filter repetitive values
        return [...new Set(arr)];
    }

    let fonts = listFonts();
    //console.log(fonts);

    const borderStyle = {
        border:'dotted 1px black',
        width:'8mm',
        height:'8mm',
        cursor:'hand',
        margin: '0px',
    };
    let allBorder = sel && sel.border.top && sel.border.right && sel.border.bottom && sel.border.left;
    let noBorder = sel && !sel.border.top && !sel.border.right && !sel.border.bottom && !sel.border.left;

    console.log(sel?.font);


    return <>
        {sel != null && <>
            <form ref={form} onChange={formChanged} className='fontTab' onSubmit={(ev) => {
                ev.preventDefault()
            }}>
                <div className='props'>
                    <fieldset>
                        <legend>Písmo</legend>
                        <div><label>Font: </label><select onChange={()=>{}} name={'fontFamilySel'} value={sel.font.family}>
                            <option value={''}>---</option>
                            {
                                fonts.map((f, index)=>{
                                    return <option value={f} key={index}>{f}</option>
                                })
                            }
                        </select></div>
                    <div><label>Velikost: </label><input onChange={(ev)=>{}} type='text' name='fontSizeInp' value={parseFloat(sel.font.size)}/> bodů</div>
                    <div><label>Tučné písmo: </label><input onChange={()=>{}} type={"checkbox"} checked={sel.font.bold} name='boldCHB' /></div>
                    <div><label>Kurzíva: </label><input onChange={()=>{}} type={"checkbox"} checked={sel.font.italic} name='italicCHB' /></div>
                    </fieldset>
                    <br/>
                    <div><label>Zarovnání</label>
                        <select onChange={()=>{}}  name='alignSel' value={sel.font.align} >
                        <option value='L'>vlevo</option>
                        <option value='C'>střed</option>
                        <option value='R'>vpravo</option>
                    </select>
                    </div>
                    <div><label>Svisle zarovnání</label>
                        <select onChange={()=>{}}  name='vAlignSel' value={sel.font.valign} >
                            <option value='T'>vrch</option>
                            <option value='M'>střed</option>
                            <option value='B'>spodek</option>
                        </select>
                    </div>
                    <br/>
                    <fieldset>
                        <legend>Rámeček</legend>

                        <div style={{marginBottom:'3mm'}}>
                            <div className={'borderEdge'} style={!sel.border.top && !sel.border.right && !sel.border.bottom && !sel.border.left ? {backgroundColor:'silver', borderColor:'navy'} : {}}>
                                <div onClick={ev=>borderClick(ev, 'none')} className={'borderInner'} style={{...borderStyle}}>&nbsp;</div>
                            </div>
                            <div className={'borderEdge'} style={ allBorder ? {backgroundColor:'silver', borderColor:'navy'} : {}}>
                                <div onClick={ev=>borderClick(ev, 'all')} className={'borderInner'} style={{...borderStyle, border:'solid 3px black'}}>&nbsp;</div>
                            </div>
                            <div className={'borderEdge'} style={sel.border.left && !allBorder ? {backgroundColor:'silver', borderColor:'navy'} : {}}>
                                <div onClick={ev=>borderClick(ev, 'left')} className={'borderInner'} style={{...borderStyle, borderLeft:'solid 3px black'}}>&nbsp;</div>
                            </div>
                            <div className={'borderEdge'} style={sel.border.top && !allBorder ? {backgroundColor:'silver', borderColor:'navy'} : {}}>
                                <div onClick={ev=>borderClick(ev, 'top')} className={'borderInner'}  style={{...borderStyle, borderTop:'solid 3px black'}}>&nbsp;</div>
                            </div>
                            <div className={'borderEdge'} style={sel.border.right && !allBorder ? {backgroundColor:'silver', borderColor:'navy'} : {}}>
                                <div onClick={ev=>borderClick(ev, 'right')} className={'borderInner'} style={{...borderStyle, borderRight:'solid 3px black'}}>&nbsp;</div>
                            </div>
                            <div className={'borderEdge'} style={sel.border.bottom && !allBorder ? {backgroundColor:'silver', borderColor:'navy'} : {}}>
                                <div onClick={ev=>borderClick(ev, 'bottom')} className={'borderInner'} style={{...borderStyle, borderBottom:'solid 3px black'}}>&nbsp;</div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </form>
            </>
        }
    </>
}