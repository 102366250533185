/**
 *
 * @type {{top: number mm, left: number mm, width: number mm, height: number mm}}
 */
import {FrameTypes} from "../structs/FrameTypes.js";
import {memo} from "react";
export const defaultDocFont = {
    size:'8.5',
    family:'DejaVu Sans',
    bold:false,
    italic:false,
    align:'L',
    valign:'T'
};

export const defaultFrameBorder = {
    left:false,
    top:false,
    right:false,
    bottom:false,
}

export function FrameInfo(){
    this.width = 0;
    //this.tempWidth = 0;
    this.height = 0;
    this.realSize = {x:0, y: 0, width:0, height:0, widthByText:true, heightByText:true};
    //this.automaticHeight = true;
    //this.automaticWidth = true;

    this.dbId = -1;
    this.tempId = null;
    this.origId = -1;
    //this.content= 'Text';
    this.children = [];
    this.parent = null;
    this.name = 'frame';
    this.dataType = FrameTypes.TEXT;
    this.dataName = '';
    this.volName = '';
    this.title = '';
    this.isSection = false;
   // this.previewText = '';
    this.font = {...defaultDocFont};
    this.border = {...defaultFrameBorder};
    this.page = 1;
    this.textContent = '';//'poklepáním myši změňte text';


    this.getDescription = function (){
        let desc = '';
        switch (this.dataType){
            case FrameTypes.TEXT:
                desc = this.textContent;
                break;
            case FrameTypes.DATA:
                desc = this.title ? this.title : this.dataName;
                break;
            case FrameTypes.CUSTOM:
                desc = this.title ? this.title : this.volName;
                break;
        }
        if(desc && (desc.length > 10)){
            desc = desc.substring(0, 10) + '...';
        }
        return '(' + this.tempId + ') [' + this.dataType + '] ' + desc + ' (str: ' + this.page + ')';
    }
}

