import {useContext, useEffect, useRef, useState} from "react";
import {DocumentContext, EditorContext, GoodsContext} from "../App";
import {PageMode} from "../structs/PageMode";
import {useDocument} from "../hooks/useDocument";
import {FrameTypes} from "../structs/FrameTypes";
import {AlertButtons, AlertInfo, AlertTypes, UseAlert} from "../hooks/useAlert";
import {PageSelector} from "./FrameProperties/PageSelector";
import {initialConfig} from "../InitialConfig";

export const PreviewPanel = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
    const  [Goods, dispatchGoods] = useContext(GoodsContext);
    const [loadDoc, saveDoc, docUtils] = useDocument();
    const [edits, setEdits] = useState([]);
    //const [activePage, setActivePage] = useState(1);
    const [showAlert, renderAlerts] = UseAlert();
    const selRef = useRef();
    const [saveTimestamp,setSaveTimestamp] = useState(null);
    const [afterSave, setAfterSave] = useState(false);

    const backToEdit = ()=>{
        dispatch({type:'pageMode', payload:PageMode.EDIT});
    };

    useEffect(()=>{
        if(!Editor.carFields?.car?.length){
            console.log("Nemám data auta...");
        }
    }, []);

    const smazatPdf = ()=>{
        docUtils.deleteTemplate();
    }

    const previewPdf = ()=>{
        setAfterSave(true);
        saveDoc(null, false);
        //window.open(Cfg.dataUrl + '/pdf/preview/' + Cfg.documentId + '/' + Cfg.dataId + '?token=' + Cfg.firmaInfo.token + '&t=' + Date.now() , 'preview').focus();

    }

    const openPdf = ()=>{
        window.open(Cfg.dataUrl + /*'/pdf/preview/'*/ '/pdfsave/' + Cfg.documentId +  '?token=' + Cfg.firmaInfo.token + '&t=' + Date.now() , 'preview').focus();
        console.log("OpenPdf...." + Cfg?.documentId);
    }

    useEffect(()=>{
        if(Cfg.savedTimestamp != null && Cfg.documentId > 0 && Cfg.savedTimestamp != saveTimestamp) {
            console.log("Saved timestamp...............................................");
            console.log(saveTimestamp + " >> " + Cfg.savedTimestamp);
            setSaveTimestamp(Cfg.savedTimestamp);

            if (afterSave) {
                setAfterSave(false);
                openPdf();
            }
        }
    }, [Cfg.savedTimestamp])

    const pageChange = ()=>{
       //console.log("Selval: " + selRef.current.value);
       //setActivePage(selRef.current.value);
        dispatch({type:'property', payload:{propertyName:'currentPage', value:selRef.current.value}});
    }

    const deleteDoc = (ev)=>{
        console.log('Mažu 10....');
        let alertI ={...AlertInfo};
        alertI.type = AlertTypes.MESSAGE_BOX;
        alertI.buttons = [AlertButtons.NO, AlertButtons.YES];
        alertI.content = <>Opravdu smazat dokument?<br/>Šablona zůstane zachována<br/>Data se budou muset doplnit znovu</>;
        alertI.callBack = (data)=>{
            //console.log('Return: ' + data.closeButton.name);
            if(data.closeButton.name == 'yes'){
                console.log('Mažu....');

                //dispatch({type:'removeFrame', payload:frame});
                //dispatch({type: 'frame', payload:{verb:'remove', tempId:frame.tempId}});
            }
        };
        showAlert(alertI);
    }



    const editClick = (info)=>{
        const {page, tempId} = info;
        console.log('tempId: ' + tempId + '; Page: ' + page);
        let sels = [];
        let frms = [...Cfg.frameMan.Frames].map((f)=>{
            f.editable = f.tempId == tempId;
            if(f.tempId == tempId){
                sels.push(f.tempId);
            }
            return f;
        });
//        let cfg = {...Cfg, currentPage: page};
//        cfg.frameMan.Frames = frms;
        //cfg.frameMan.Selected = sels;

        let fm = {...Cfg.frameMan, frames:frms};

        //dispatch({type:'properties', payload:{currentPage:page}});
        //dispatch({type:'properties', payload:{currentPage:page}});
        //console.log(cfg);
        dispatch({type:'property', payload:{name:'currentPage', value: page}});
        dispatch({type:'property', payload:{name:'frameMan', value: fm}});
        //dispatch({type:'none', payload:cfg});
    }

    useEffect(()=>{
        //console.log('Effect Frames...');
        let ee = [];
        let frx =  Cfg.frameMan.Frames.map((f, index)=>{
            if(f.dataType == FrameTypes.CUSTOM){
                ee.push(f);
            }
        });
        setEdits(ee);
        //console.log(edits.length);
    }, [Cfg.frameMan.Frames]);

    let pages = [];
    //console.log(Cfg.pageCount);
    for(let i  = 0; i < Cfg.pageCount; i++){
        pages.push(i + 1);
    }



    let title = '';
    let ctx = <></>;
    switch (Cfg.pageMode){
        case PageMode.PREVIEW:
            title  = Cfg.pageModeExt == PageMode.ADV_PREVIEW ? 'Náhled tisku' : 'Náhled šablony';
            ctx = <>

                {edits.length > 0 &&
                <div className={'previewStatistics'}>
                    <table><thead></thead><tbody>
                        <tr><th>id</th><th>obsah</th><th>str.</th></tr>
                    {edits.map((e, index)=>{
                        let bck =  e.textContent && e.textContent != initialConfig.defaultClickText  ? 'palegreen' : 'pink';
                        return <tr style={{userSelect: "none", backgroundColor : bck}} key={index}><td>{e.dbId}</td><td>
                            {e.textContent.length > 30 ? e.textContent.substring(0, 27) + '...' : e.textContent}
                        </td>
                            <td onClick={()=>{editClick({tempId: e.tempId, page: e.page})}} ><div className={'likeButton'}>{e.page}</div></td></tr>
                        }
                    )}
                    </tbody></table>
                </div>}
                <div style={{width:'100%'}}>Text v poznámkách můžete změnit poklepáním na červené, nebo zelené objekty ve stránce.</div>

                <button onClick={(ev)=>{previewPdf(ev)}}>{Cfg.pageModeExt == PageMode.ADV_PREVIEW  ? 'Tisk/Pdf' :  'Náhled PDF' }</button>
                {Cfg.pageModeExt == PageMode.ADV_PREVIEW && Cfg.documentId > 0 &&
                    <button onClick={(ev)=>{smazatPdf(ev)}}>Smazat dokument<br/>(šablona zůstane zachována)</button>
                }

                {Cfg.pageModeExt == PageMode.ADV_PREVIEW ? <>
                <div>{Cfg.documentId > 0 ? 'Úprava dokumentu: ' + Cfg.documentName + '(' + Cfg.documentId + ')' : 'Nový dokument'}</div>
                        <div>Podle šablony: {Cfg.templateDocumentName ?  Cfg.templateDocumentName : '-'} ({Cfg.templateId})</div>
                <div>Data ID: {Cfg.dataId}</div> </> :
                    <button onClick={(ev)=>{backToEdit(ev)}}>Zpět</button>
                }
            </>

            break;
        case PageMode.EDIT:
            title  = 'Náhled šablony';
            ctx = <>
                <button onClick={(ev)=>{backToEdit(ev)}}>Zpět</button>
            </>
             break;
    }



                    //console.log(pages);
    return <>
        <div id='previewPanel'>

    <div>
        {title.length > 0 &&
            <h5>{title}</h5>
        }

        <PageSelector addButtons={false}></PageSelector>
        {/*
        <div className={'pageDiv'}>
            <label>Stránka: </label> <select ref={selRef} value={Cfg.currentPage} onChange={()=>{pageChange()}}>
            {pages.map((p)=>{
                return <option value={p} key={p}>{p}</option>
            })}
        </select>
            <hr/>

        </div>
        */}
        <div>{ctx}</div>

        {/*
        <hr/>
        { Cfg.pageModeExt != PageMode.ADV_PREVIEW && <>
            <button onClick={(ev)=>{backToEdit(ev)}}>Zpět</button>
        </>}
        <button onClick={(ev)=>{previewPdf(ev)}}>{Cfg.pageModeExt == PageMode.ADV_PREVIEW  ? 'Uložit' :  'Náhled PDF' }</button>
        {Cfg.pageModeExt == PageMode.ADV_PREVIEW && <div>
            {edits.map((e, index)=>{
                    return <div key={index}>{e.dbId} ({e.page})</div>
                }
            )}
        </div>
        */
        }
    </div>
    </div>
    </>
}