import {frameManager} from "./components/FrameManager";
import {PageMode} from "./structs/PageMode";
//import {useFetchData} from "./functions";
//import {useState} from "react";



export const initialConfig = {
    dataUrl: null,
    data: [],
    dataFields:[],
    frameMan: new frameManager(),
    //contextMenu:  null,
    //pageKoefX: 1, Zruseno
    //pageKoefY: 1, Zruseno
    pageLeft:1,
    pageTop:1,
    documentId:-1,
    templateId:-1,
    pageOffset:{}, //v
    pageKoef:{},
    customFields:[],
    documentName:null,
    templateDocumentName:'',
    tasks: [],
    dialogs:[],
    initTasks:[],
    alerts:[],
    activeAlerts:[],
    activeContextMenu:null,
    //scrollOffset:{x:0,y:0},
    token:null,
    firmaInfo:null,
    dataId:null,
    pageMode:PageMode.EDIT,
    pageModeExt:null,
    pageCount:1,
    currentPage:1,
    pages:[],
    //dxragging:false, //indikace tažení
    copyHash:null,
    savedTimestamp:0,
    basename:'/',
    is_template:true,
    error:null,
    systemInfo:{},
    copyFrom: -1,
    defaultClickText: "poklepáním myši změňte text",
    needClickText:(txt) => {
        if(txt?.trim().length == 0){
            return true;
        }
        return  txt == initialConfig.defaultClickText;
    },

    clientToMM:(x, y)=>{
        console.log('Pocitam...koefX: ' + this.pageKoefX);
    },

    clone:()=>{
        console.log('CLONE: ' + initialConfig.dataUrl)
    }


}