import {useContext, useEffect, useRef, useState} from "react";
import {DocumentContext, EditorContext} from "../../App";
import {Checkbox} from "@mui/material";
import {useDocument} from "../../hooks/useDocument";

export const InsertMoreData = (props)=>{
    const {callbackInfo, items, ...other} = props;
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
    const [selTab, setSelTab] = useState('car');
    const tabCar = useRef();
    const tabFirm = useRef();
    const tabCustomer = useRef();
    const [selectedData,setSelectedData] = useState([]);
    const [loadDoc, saveDoc, docUtils] = useDocument();
    //const [selectedKeys,setSelectedKeys] = useState([]);

    const insertSelected = (items)=>{

        //console.log('INSERT SELECTED COUNT:...' + items.length);
        //console.log(items);
        let mm = items.map(i=>{
            return {dataType:'D', dataName:i.key.replace('car.', ''), title:i.data.label}
        });
        let frs = docUtils.addFrames(mm);

        let ids = frs.map(f=>{
            return f.tempId;
        })

        dispatch({type:'selRange', payload:ids});

        /*
        items.forEach(s=>{
            console.log('INSERT SELECTED...');

        })
         */
    }

    useEffect(()=>{
        //console.log(props);
        //props.callbackInfo.callback = insertSelected;
        callbackInfo.callback = insertSelected;
        //alertI.callback = insertSelected;
    }, [])

    const tabClick = (ev)=>{
        //console.log("tabClick: " + ev.target.dataset.tabname);
        //console.log(ev.target.dataset)
        setSelTab(ev.target.dataset.tabname);
    }
/*
    useEffect(()=>{
        switch (selTab){
            case 'car':
                tabCar.current.cla
                break;
            case 'firm':
                break;
            case 'customer':
                break;
        }
    }, [selTab])
*/

    const checkBoxClick = (ev)=>{

        let name = ev.target.value;
        //console.log('CheckKClick...' + name);

        if(ev.target.checked){
            let filt = selectedData.filter((f)=>{
                return(f.key == name);
            });
            //console.log('CheckKClick 5...' + name);
            if(filt.length == 0){
                //console.log('CheckKClick 10...' + name);
                let pars = name.split('.');
                //console.log(pars);
                if(pars.length > 2){
                    let first = pars[0];
                    pars.splice(0, 1);
                    //console.log(pars);
                    pars = [first, pars.join('.')];
                }
                //console.log(pars);
                let key = pars[0];
                let name2 = pars[1];
                if(pars.length > 2){
                    name2 = '';
                    //Nenejde pokud je name např car.ced.barva
                }
                //console.log('KEY: ' + key);
                switch (key){
                    case 'car':
                        //console.log('VKLADAM SEL: ' + pars[1]);
                        //console.log(Editor.carFields.fields);

                        let cars = Editor.carFields.fields.filter((c)=>{
                                return c.name == pars[1];
                        }) ;
                        let car = {};
                        if(cars.length == 1){
                            car = cars[0];
                        }
                        //console.log(car);
                        setSelectedData([...selectedData, {key:name, type:'car', data:{...car}}]);
                        //console.log(selectedData);
                        break;
                    case 'customer':
                        //console.log('VKLADAM SEL: ' + pars[1]);
                        //console.log(Editor.customerFields);

                        let custs = Editor.customerFields.filter((c)=>{
                            return c.name == pars[1];
                        }) ;
                        let cust = {};
                        if(custs.length == 1){
                            cust = custs[0];
                        }
                        //console.log(cust);
                        setSelectedData([...selectedData, {key:name, type:'customer', data:{...cust}}]);
                        break;
                    case 'firm':
                        //console.log('VKLADAM SEL 3: ' + pars[1]);
                        //console.log(Editor.customerFields);

                        let firms = Editor.firmMap.filter((c)=>{
                            return c.name == pars[1];
                        }) ;
                        let firm = {};
                        if(firms.length == 1){
                            firm = firms[0];
                        }

                        setSelectedData([...selectedData, {key:name, type:'firm', data:{...firm}}]);
                        break;
                }
                //selectedData = [...selectedData, {}];

            }
        }else{
            let filt = selectedData.filter((f)=>{
                return(f.key != name);
            });
            setSelectedData(filt);
          }
    }

    //console.log('selectedData:');
    //console.log(selectedData);

   // console.log('FIRM MAP: ');
    //console.log(Editor.firmMap);



    useEffect(()=>{
        /*
        console.log('SELECTED DATA CHANGES')
        setSelectedKeys(selectedData.map(s=>{
            return s.key;
        }));
         */
        props.callbackInfo.items = [...selectedData];
    }, [selectedData]);


    return <>
        <div id={'insertMoreObjects'}>
            <div className={'title'}>Vložit objekty pro vybraná data?</div>
            <div className={'tabContent'}>
                <div className={'tabs'}>
                    <div key={'tab1'} onClick={(ev)=>{tabClick(ev)}}
                         data-tabname={'car'}
                         className={selTab == 'car' ? 'selTab' : ''}
                         ref={tabCar}>Auto</div>
                    <div key={'tab2'} onClick={(ev)=>{tabClick(ev)}}
                         className={selTab == 'firm' ? 'selTab' : ''}
                         data-tabname={'firm'} ref={tabFirm}>Firma</div>
                    <div key={'tab3'} onClick={(ev)=>{tabClick(ev)}}
                         className={selTab == 'customer' ? 'selTab' : ''}
                         data-tabname={'customer'}
                         ref={tabCustomer}>Zákazník</div>
                </div>
                <div style={{height:'300px', overflowY:'scroll'}}>
                    {selTab == 'car' && <>
                    {/*
                        <div className={'selButtons'}><button>Vybrat vše</button> <button>zrušit vše</button></div> */}
                        <div className={'checkSelect'}>
                        {Editor?.carFields?.fields && Editor.carFields.fields.length > 0 &&
                            Editor.carFields.fields.map((f, index)=> {
                            let xname = 'car.'+f.name;
                            return <label key={'car' + index}><input key={'inpCar' + index}
                             value={xname}
                             onChange={ev=>{checkBoxClick(ev)}}
                            checked={selectedData.find(s=>s.key == xname) ? true : false} type={'checkbox'} /> {f.label}</label>
                        })}
                        </div>
                    </>}
                    {selTab == 'firm' && <>
                        <div className={'checkSelect'}>
                            {
                                Editor.firmMap.map((fi, index)=>{
                                let xname = 'firm.'+fi.name;
                                return <label key={'firm' + index}>
                                    <input onChange={ev=>{checkBoxClick(ev)}} value={xname}
                                           key={'inpFirm' + index} checked={selectedData.find(s=>s.key == xname) ? true : false} type={'checkbox'} /> {fi.label}</label>
                            })}
                        </div></>}
                    {selTab == 'customer' && <>
                        <div className={'checkSelect'}>
                            {Editor.customerFields.map((fi, index)=>{
                                let xname = 'customer.'+fi.name;
                                return <label key={'customer' + index}>
                                    <input onChange={ev=>{checkBoxClick(ev)}} value={xname}
                                           checked={selectedData.find(s=>s.key == xname) ? true : false} type={'checkbox'} /> {fi.label}</label>
                            })}
                        </div>
                        </>}
                </div>
            </div>
            {/*
            <fieldset className={'fieldsetAlone'}>
                <legend>Vybráno</legend>
                <div className={'objSelected'}>
                {
                    selectedData.map((d, index)=>{
                        return  <div className={'sel_' + d.type} key={'sel'+ index}>{d.data.label}</div>;
                    })
                }
                </div>
            </fieldset>*/}
        </div>
    </>
}