import {useContext, useState} from "react";
import {DocumentContext} from "../App";

export function usePagePosition(){
    const [pos, setPos] = useState({x: -1, y: -1, delta:{x:0, y:0}});
    const [startPos, setStartPos] = useState({x: 0, y: 0});
    const [Cfg, dispatch] = useContext(DocumentContext);
    //const [posXY, setPosXY] = useState({x: -1, y: -1, width:0, height: 0});

    const handleXY = (x, y, w, h) => {
        //console.log("X: " + x + "; Y: " + y);
        let xMM = x * Cfg.pageKoef.x;
        let yMM = y * Cfg.pageKoef.y;
        let wMM = w * Cfg.pageKoef.x;
        let hMM = h * Cfg.pageKoef.y;
        let posInMM = {x:xMM, y:yMM, width:wMM, height: hMM};
        posInMM.x -= Cfg.pageOffset.x;
        posInMM.y -= Cfg.pageOffset.y;
        //console.log('Nastvuji:');
        //console.log(posInMM);
      //  setPosXY(posInMM);
        return posInMM;
    }

    const handleMouseMove = (e, start= false) => {
        e.persist();
        //console.log("Handle mouse move:" + Cfg.pageOffset.x);
        //console.log(Cfg);
        let xTemp = e.clientX;
        let yTemp = e.clientY;
        let xMM = xTemp * Cfg.pageKoef.x;
        let yMM = yTemp * Cfg.pageKoef.y;
        if(start){
            setStartPos({x: xTemp, y:yTemp});
        }
        //console.log("Handle mouse move: XDelta: " + pos.delta.x + '; YDelta: ' + pos.delta.y);
        let delta = start ? {x:0, y:0} : {x:(startPos.x - xTemp) * Cfg.pageKoef.x, y:(startPos.y - yTemp) * Cfg.pageKoef.y};
        let posInMM = {x:xMM, y:yMM, delta:delta};
        posInMM.x -= Cfg.pageOffset.x;
        posInMM.y -= Cfg.pageOffset.y;

        setPos(posInMM);
    }

    return [pos, handleMouseMove, handleXY]

}