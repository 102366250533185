import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {usePostData} from "../hooks/usePostData";
import {DocumentContext} from "../App";
import {UserField, UserFiled} from "../components/UserFields/UserField";
import {UserFieldCategory, UserFieldcategory} from "../components/UserFields/UserFieldCategory";

export const UserData = ()=>{
    const { template_id, document_id } = useParams();
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);
    const [userData, setUserData] = useState();
    const [dataTree, setDataTree] = useState();

    useEffect(()=>{
        //console.log(Cfg);
        if(Cfg.firmaInfo) {
            fetchJson('/getUserDocData/' + template_id).promise
                .then(resp => resp.json())
                .then(json => {
                    //console.log('Custom DocData loaded:');
                    //console.log(json);
                    setUserData(json.userFields);
                    let cats = {};
                    json.userFields.map((d)=>{
                        let splitted = d.title.split('|', 2);
                        if(splitted.length > 1){
                            d.abbrev = splitted[1];
                            if(splitted[0] in cats){
                                cats[splitted[0]].push(d);
                            }else{
                                cats[splitted[0]] = [d];
                            }
                        }else{
                            d.abbrev = splitted[0];
                            if('noCategory' in cats){
                                cats.noCategory.push(d);
                            }else{
                                cats.noCategory = [d];
                            }
                        }
                    });

                    //console.log(cats);
                    let CArray = [];
                    for(let c in cats){
                        CArray.push({categoryName:c, fields: cats[c]});
                    }
                    setDataTree(CArray);
                });
        }
    }, [Cfg.firmaInfo])

    return <><div style={{width:'100%'}}>
        {dataTree ? <div>
            {dataTree.map((c, index)=>{
            return <UserFieldCategory key={index} category={c}></UserFieldCategory>
        })}
            <div style={{padding:'10px'}} ><button>Uložit</button></div>
        </div> : <div>Načítám data...</div>}
        </div>
    </>
}