import {usePostData} from "../../hooks/usePostData";
import {Grid, GridContext} from "../Grid/Grid";
import {createContext, useContext, useEffect, useState} from "react";
import {DocumentContext} from "../../App";
//import FullFeaturedCrudGrid from "./FullFeaturedCrudGrid";
//import BasicEditingGrid from "./FullFeaturedCrudGrid";




export const AdminHome = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [jsonFetch, postFetch] = usePostData();
    //const [rows, setRows] = useState([]);
    //const [cols, setCols] = useState([]);
    const [dataFlds, setDataFlds] = useState({cols:[], data:[]});
    const [dataGroups, setDataGroups] = useState({cols:[], data:[]});



    const prevodClick = ()=>{
        jsonFetch('/prevodUzivSloupce').promise
            .then(resp=>resp.json())
            .then(json=>{
                //console.log(json);
            });
    }

    useEffect(()=>{
        //console.log('Colnum: ' + cols.length);
        /*
        let flds = [
            {column:'col1', title:'Sloupec 1', width: '150px', isKey:true},
            {column:'col2', title:'Sloupec 2', width: '150px', isKey:false},
            {column:'col3', title:'Sloupec 3', width: '450px', isKey:false},
        ];
        let data = [
            {col1: 'ABC 1', col2:'CDE 1', col3: 'XYZ 1'},
            {col1: 'ABC 2', col2:'CDE 2', col3: 'XYZ 2'},
            {col1: 'ABC 3', col2:'CDE 3', col3: 'XYZ 3'},
        ];
        setCols(flds);
        setRows(data);
*/
        if(Cfg.firmaInfo){
            jsonFetch('userDataGrid').promise
                .then(resp=>resp.json())
                .then(json=>{
                    //console.log(json);
                    //setCols(json.userFields.cols);
                    //setRows(json.userFields.data);
                    setDataFlds({...dataFlds, data:json.userFields.data, cols:json.userFields.cols});
                    setDataGroups({...dataGroups, data:json.groups.data, cols:json.groups.cols})
                });
        }

    }, [Cfg.firmaInfo]);

   // console.log('Colnum 2: ' + cols.length);


    const saveGroups = (data)=>{
        //console.log('Save groups:');
        //console.log(data);
        jsonFetch('userDataGrid/saveGroups', {data:data}).promise
            .then(resp=>resp.json())
            .then(json=>{
                //console.log(json);
                setDataFlds({...dataFlds, data:json.userFields.data, cols:json.userFields.cols});
                setDataGroups({...dataGroups, data:json.groups.data, cols:json.groups.cols})
            });
    };

    const saveFields = (data)=>{
        //console.log('Save fields:');
        //console.log(data);
        jsonFetch('userDataGrid/saveFields', {data:data}).promise
            .then(resp=>resp.json())
            .then(json=>{
                console.log(json);
                setDataFlds({...dataFlds, data:json.userFields.data, cols:json.userFields.cols});
                setDataGroups({...dataGroups, data:json.groups.data, cols:json.groups.cols})
            });
    };

    return <> {Cfg.firmaInfo && dataFlds.data ? <>
        {Cfg.firmaInfo.kod_firmy =='9989' ? <>
        <div style={{display:'flex', flexDirection:"column", alignItems:"start"}}>
        <h1>Admin</h1>
            <div>Skupiny</div>
            <GridContext.Provider value={[dataGroups, setDataGroups]}>
        <Grid dataRows={dataGroups.data} columns={dataGroups.cols} save={saveGroups}></Grid>
            </GridContext.Provider>
            <br/>
            <div>Údaje</div>
            <GridContext.Provider value={[dataFlds, setDataFlds]}>
        <Grid dataRows={dataFlds.data} columns={dataFlds.cols} save={saveFields}></Grid>
            </GridContext.Provider>
            {/*
        <button  onClick={prevodClick}>Preved</button> */}
        </div></> : <div>Nemáte oprávnění pro tuto stránku...</div>}
        </> : <div>Načítám uživatele...</div>}
    </>
};