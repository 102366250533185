import {useContext, useEffect, useRef, useState} from "react";
import {DocumentContext, EditorContext} from "../../App";
import {FrameTypes} from "../../structs/FrameTypes";
import {SelectPreviewCar} from "../../dialogs/SelectPreviewCar";
import {useGoods} from "../../hooks/useGoods";

export const DataTab = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const form  = useRef();
    const [sel, setSel] = useState(null);
    const [firma, setFirma] = useState([]);
    const [setGoodId] = useGoods();
    const [Editor, dispatchEditor] = useContext(EditorContext);

    useEffect(()=>{
        //console.log('useEffrct length: ' + Cfg.frameMan.Selected.length);
        if(Cfg.frameMan.Selected.length > 0){
            //console.log('useEffrct: 1');
            setSel(Cfg.frameMan.Frames.find((f)=>{return f.tempId == Cfg.frameMan.Selected;}));
//            console.log('Zmena sel: ' + sel)
        }else{
            setSel(null);

        }
    });


    //console.log('Render DataTab...');

    const formChanged = ()=>{
        let dataSel = form.current.elements.selData;
        let custSel = form.current.elements.custData;
        let volSel = form.current.elements.selVolitelne;

        let oldType = sel.dataType;
        sel.dataType = form.current.elements.typDat.value;
        //console.log('form changed... ' + sel.dataType + ' => ' + oldType);
        if (sel.dataType == oldType) {
            switch (sel.dataType) {
                case FrameTypes.TEXT:
                    //console.log('form changed... Text...' + form.current.elements.myText.value);
                    sel.textContent = form.current.elements.myText.value;
                    sel.title = sel.textContent;
                    break;
                case FrameTypes.DATA:
                    sel.dataName = dataSel.options[dataSel.selectedIndex ?? 0].value;
                    sel.title = dataSel.options[dataSel.selectedIndex ?? 0].text;


//                    console.log('Menim data 2...');
//                    console.log(sel);
               //     sel.content = dataSel.options[dataSel.selectedIndex ?? 0].text;
                    break;
                case FrameTypes.CUSTOM:
                    sel.volName = custSel.options[custSel.selectedIndex ?? 0].value;
                    sel.title = custSel.options[custSel.selectedIndex ?? 0].text;
             //       sel.content = volSel.options[volSel.selectedIndex ?? 0].text;
                    break;
            }
        }else{
            switch (sel.dataType) {
                case FrameTypes.TEXT:
  //                  console.log(sel);
 //                   sel.content = sel.content;
                    //sel.title =  sel.content;
                    break;
                case FrameTypes.DATA:
                    //sel.dataName = dataSel?.options[dataSel.selectedIndex ?? 0].value;
                    //sel.title =  dataSel?.options[dataSel.selectedIndex ?? 0].text;;
                    //console.log('Menim data...');
                    //console.log(sel);
                    //sel.content = dataSel.options[dataSel.selectedIndex ?? 0].text;
                    break;
                case FrameTypes.CUSTOM:
//                    sel.volName = volSel.options[volSel.selectedIndex ?? 0].value;
                    //sel.title = sel.volName;
                    //sel.content = volSel.options[volSel.selectedIndex ?? 0].text;
                    break;
            }
        }
        dispatch({type: 'frame', payload: sel});
    }

    const testGoodClick = ()=>{
        let retDlg = {id: Cfg.dataId};
        let dlg = <SelectPreviewCar retDlg={retDlg} close={(d, btn) => {
            if (btn == 'OK') {
                //previewCarsClosed(retDlg);
                console.log('previewCarsClosed: ');
                console.log(retDlg);

                //dispatch({type:'none', payload:{...Cfg, dataId:retDlg.id}});
                dispatch({type:'property', payload:{name:'dataId', value:retDlg.id}});
                setGoodId(retDlg.id);
                //dispatchGoods({type:'id', payload:retDlg.id});
            }
        }}>
        </SelectPreviewCar>
        dispatch({
            type: 'dialog', payload: {
                ctrl: dlg, verb: 'add', ok: () => {
                    //console.log('Ok Fce')
                }
            }
        });
    }


    useEffect(()=>{
        let firmaInf = [];
        let fFiltered = [];
        if(Cfg.firmaInfo && Editor.firmMap) {
            /*
            let firmMap = [
                ['jmeno', 'jméno'],
                ['email','email'],
                ['dic', 'dič'],
                ['ico', 'ičo'],
                ['obec', 'obec'],
                ['ulice', 'ulice'],
                ['psc', 'psč'],
                ['telefon', 'telefon'],
                ['www', 'www'],
                ['today', 'dnešní datum']
            ];
             */
            let fEntries = Object.entries(Cfg.firmaInfo);
            //console.log(fEntries);
            let fKeys = Editor.firmMap.map(f=>{
                return f[0];
            });
            //console.log('Keys: ');
            //console.log(fKeys);
            let fFiltered = fEntries.filter(f=>{
                return fKeys.includes(f[0]);
            });
            // console.log('Filtered');
            //console.log(fFiltered);

            firmaInf = fFiltered.map((f)=>{
                let keyEntry = Editor.firmMap.filter((ff)=>{
                    return ff[0] == f[0];
                });
                if(keyEntry?.length > 0) {
                    return {
                        name: f[0],
                        label: keyEntry[0][1],
                        value: f[1]
                    }
                }
            });
            setFirma(firmaInf)
        }
    }, [Cfg.firmaInfo])

/*
    console.log('Editor....');
    console.log(Editor);
*/
    //let optIndex = 0;

    return  <>{ Cfg.firmaInfo  &&  <div>
                {sel != null && <>
                    <form ref={form} onChange={formChanged} onSubmit={(ev) => {
                        ev.preventDefault()
                    }}>
                        <div className='typeRadios'>
                            <label>Text <input name='typDat' onChange={() => {
                            }} type="radio" checked={sel.dataType === FrameTypes.TEXT} value={FrameTypes.TEXT}/></label>
                            <label>Data <input name='typDat' onChange={() => {
                            }} type={"radio"} checked={sel.dataType === FrameTypes.DATA}
                                               value={FrameTypes.DATA}/></label>
                            <label>Poznámka <input name='typDat' onChange={() => {
                            }} type={"radio"} checked={sel.dataType === FrameTypes.CUSTOM}
                                                   value={FrameTypes.CUSTOM}/></label><br/>
                        </div>
                        <br/>
                        <div className='props'>
                            {sel.dataType == FrameTypes.TEXT &&
                                <div><label>Text: </label>
                                    <textarea onChange={() => {
                                    }} style={{display: (sel.dataType == FrameTypes.TEXT ? '' : 'none')}} type='text'
                                              name='myText'
                                              value={sel.textContent || ''}></textarea></div>
                            }
                            {sel.dataType == FrameTypes.CUSTOM &&
                                <>
                                    <div>Údaj se upravuje před tiskem a pro každý dokument se zadává zvlášť. (Např.
                                        jméno kupujícího)
                                    </div>
                                </>
                            }

                            {sel.dataType == FrameTypes.DATA && <>
                                <div>
                                    <label>Údaj:</label>
                                    <select name='selData' onChange={() => {
                                    }} value={sel.dataName ?? ''}>
                                        <option value=''></option>
                                        <optgroup label={'Auto'}>
                                            {Editor?.carFields?.fields && Editor.carFields.fields.length > 0 && Editor.carFields.fields.map((f, index) => {
                                                    return <option value={f.name} key={f.name}>
                                                        {f.label}
                                                    </option>
                                                }
                                            )}
                                        </optgroup>
                                        <optgroup label={'Firma'}>
                                            {Editor.firmMap.map((fi, index) => {
                                                return <option value={'firm.' + fi.name}
                                                               key={'firm-' + index}>{fi.label}</option>
                                            })}

                                        </optgroup>
                                        {Editor.customerFields && <>
                                            <optgroup label={'Zákazník'}>
                                                {Editor.customerFields.map((fi, index) => {
                                                    return <option value={'cust.' + fi.name} key={'cust-' + index}>
                                                        {fi.label}</option>
                                                })}
                                            </optgroup>
                                        </>}
                                    </select>
                                </div>
                                {/*
                <div>
                    <label onClick={testGoodClick} style={{textDecoration:"underline"}}>Testovací inzerát:</label>
                    <input type='text' readOnly={true} value={Cfg.dataId ?? ''}/>
                </div>*/}
                            </>
                            }
                        </div>
                    </form>
                </>}
            </div>
    }</>
}