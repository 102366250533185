import {useContext} from "react";
import {DocumentContext} from "../App";

export function ProgressOver(){
    const [Cfg, dispatch] = useContext(DocumentContext);

    return <div className='progressOver' style={{zIndex:251}}>
        <div>
            {Cfg.tasks.map((p, index)=>
                <div key={index}>{p.title}</div>
            )}
        </div>
    </div>
}