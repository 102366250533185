import {useContext, useEffect, useRef, useState} from "react";
import {DocumentContext, EditorContext} from "../App";
import {RadioInput} from "../components/forms/RadioInput";
import {Frame} from "./Frame";
import {FrameTypes} from "../structs/FrameTypes.js";
//import {Tab} from "./Tab";
import {TabBox} from "./TabBox";
import {AlertButtons, AlertInfo, AlertTypes, UseAlert} from "../hooks/useAlert";
import {useDocument} from "../hooks/useDocument";
import {PageSelector} from "./FrameProperties/PageSelector";
import {EditorState} from "../EditorState";
import {InsertMoreData} from "./FrameProperties/InsertMoreData";


export function FrameProperties(){
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
      //console.log('Props paint..');
      const pageForm = useRef();
      const [selTab, setSelTab] = useState(1);
      const [showAlert, renderAlerts] = UseAlert();
      const [loadDoc, saveDoc, docUtils] = useDocument();
      const [topPos, setTopPos] = useState();
    const [poradi, setPoradi] = useState([]);


    //const [typDat, setTypDat] = useState("T"); // '', posLeft:0, myText:''};


    //const { posLeft, setPosLeft} = useState(0);
    //const { myText, setMyText} = useState('');


    let sel = null;
    if(Cfg.frameMan.Selected.length > 0 ){
        sel = Cfg.frameMan.Frames.find((f)=>{return f.tempId == Cfg.frameMan.Selected[0];});
        //console.log(sel.getX());
    }



    useEffect(()=>{
      // setTypDat(sel ? sel.dataType : 'T');
    })



    //console.log(typDat);
/*
    const formChanged = ()=>{
        if(sel != null) {
            let dataSel = form.current.elements.selData;
            let volSel = form.current.elements.selVolitelne;
            //console.log('form changed... ' + dataSel.options[dataSel.selectedIndex].value);
            let oldType = sel.dataType;
            sel.dataType = form.current.elements.typDat.value;
            if (sel.dataType == oldType) {
                sel.left = parseInt(~~form.current.elements.posLeft.value ?? 0, 10);
                sel.top = parseInt(~~form.current.elements.posTop.value ?? 0, 10);
                sel.width = parseInt(~~form.current.elements.posWidth.value ?? 0, 10);
                sel.height = parseInt(~~form.current.elements.posHeight.value ?? 0, 10);
                switch (sel.dataType) {
                    case FrameTypes.TEXT:
                        sel.content = form.current.elements.myText.value;
                        break;
                    case FrameTypes.DATA:
                        sel.dataName = dataSel.options[dataSel.selectedIndex ?? 0 ].value;
                        sel.title  = dataSel.options[dataSel.selectedIndex ?? 0 ].text;
                        break;
                    case FrameTypes.CUSTOM:
                        sel.volName = volSel.options[volSel.selectedIndex ?? 0].value;
                        sel.title  = volSel.options[volSel.selectedIndex ?? 0 ].text;
                        break;
                }
            }

            dispatch({type: 'frame', payload: sel});
        }
    };
*/
    //console.log(Cfg.frameMan.Frames)

    const listClick = (ev, key)=>{
        //console.log('List click: ' + key);
        let fr = Cfg.frameMan.Frames[key];
        console.log('SEL: ');
        console.log(fr);
        dispatch({type:'edit',payload:{frame:fr, verb:'set'}});

    }

    //console.log(Cfg);

    const tabClick = (ev)=>{
        switch (ev.target.dataset.abc){

        }
       //console.log(ev.target.dataset.abc);
    }

    const getClass = (el)=>{
        //console.log('TAB CLASS: ' + el);
        return 'selected';
    }

    const addPage = ()=>{
        //dispatch({type:'none', payload:{...Cfg, pageCount:(Cfg.pageCount + 1)}});
        dispatch({type:'property', payload:{name:'pageCount', value:(Cfg.pageCount + 1)}});
    }

    const removePage = ()=>{

        //console.log('Copy to clipboard')
        /*
        if(Cfg.frameMan.Selected.length > 0){
            let sel = Cfg.frameMan.Selected[0];
            console.log('Copy to clipboard');
            console.log(sel);
            navigator.clipboard.writeText(JSON.stringify({clipboard:'Jsem tu'})).then(()=>{console.log('Konec Copy...')});
        }*/
        //navigator.clipboard.writeText(JSON.stringify({clipboard:'Jsem tu'})).then(()=>{console.log('Konec Copy...')});

        docUtils.removePage(Cfg.currentPage);
    }

    const pageFormChange = ()=>{

    };

    const pageChange = (ev, page)=>{
        //console.log(ev.target.value);
        //dispatch({type:'none', payload:{...Cfg, currentPage:ev.target.value}});
        //dispatch({type:'none', payload:{...Cfg, currentPage:page}});
        dispatch({type:'property', payload:{name:'currentPage', value:page}});
    };

    let pages = [];
    for(let i = 0; i < Cfg.pageCount; i++){
        pages.push(i + 1);
    }

    const deleteSelected = (ev)=>{
        if(sel){
            docUtils.removeFrame(sel);
            //dispatch({type:'removeFrame', payload:sel});
            /*
            let alertI ={...AlertInfo};
            alertI.type = AlertTypes.MESSAGE_BOX;
            alertI.buttons = [AlertButtons.NO, AlertButtons.YES];
            alertI.content = <>Opravdu smazat rámec?</>;
            alertI.return = (data)=>{
                console.log('Return: ' + data.closeButton.name);
                if(data.closeButton.name == 'yes'){
                    dispatch({type:'removeFrame', payload:sel});
                }
            };
            showAlert(alertI);
             */
        }
    }

    let propStyle = {
        top: (100 + window.scrollY) + 'px'
    };

    const insertMore = ()=>{
        let alertI ={...AlertInfo};
        alertI.type = AlertTypes.MESSAGE_BOX;
        alertI.buttons = [AlertButtons.NO, AlertButtons.YES];
        let callBack = {callback:null, items:[]};
        alertI.content = <InsertMoreData callbackInfo={callBack}/>;

        alertI.callBack = (data)=>{
            //console.log('Return: ' + data.closeButton.name);
            if(data.closeButton.name == 'yes'){
                //console.log('INSR: ' + callBack.items.length);
                console.log(callBack.items);
                callBack.callback(callBack.items);
                //alertI.content.insertSelected();
                //dispatch({type:'removeFrame', payload:frame});
                //dispatch({type: 'frame', payload:{verb:'remove', tempId:frame.tempId}});
            }
        };
        showAlert(alertI);
    }


    useEffect(()=>{
        let pageBounds = document.querySelector('#page').getBoundingClientRect()

        let tt = pageBounds.top + window.scrollY;
        let posY = tt;
        if(window.scrollY > tt){
            posY = window.scrollY;
        }
        //console.log('@@@ScrollOffset...' + tt + ' :: ' + window.scrollY + ' :: ' + posY);
        setTopPos(posY);
    }, [Editor.scroll])

    useEffect(()=>{
        if(Cfg.frameMan.Frames) {
            let por = [...Cfg.frameMan.Frames];
            por.reverse();
            setPoradi(por);
        }
    }, [Cfg.frameMan.Frames])



    return<div>
        <div id='frameProperies' style={{position: "absolute", marginTop:'0px', top: (topPos) + 'px'}}>
            <div className='topProps'>
                <PageSelector></PageSelector>
            <TabBox zmena={sel}></TabBox>
            </div>
            <div className={'addObjectCover'}>
                {/* <span className={'threeDotBtn'}>&#x25BC;</span> */}
                <div style={{flex:'1 1 auto'}} onClick={()=>{docUtils.addFrame()}} unselectable={'on'} className={'addObjectButton'}>vložit objekt
                </div>
                <div onClick={()=>{insertMore()}} style={{flex:'1 1 auto'}} className={'addObjectButton'}>vložit výběr ...</div>
            </div>
            <div className={'tabContent'}>
                {sel != null &&
                    <div>
                        <div className='frameLabel'>Objekt: {(sel.dbId > 0 ? sel.dbId : 0) + ' <' + sel.tempId + '>'} <span onClick={ev=>deleteSelected(ev)} className='delButton'>Smazat</span></div>
                    </div>
                }
                <div className='frameList'>

                    {poradi.map((f, index)=> {
                        if (f.page == Cfg.currentPage) {
                            return <div onClick={ev => listClick(ev, index)} key={'L' + f.tempId}
                                        style={(Cfg.frameMan.Selected.length > 0 && Cfg.frameMan.Selected.includes(f.tempId) ? {backgroundColor: '#F0F0F0', fontWeight:'bold'} : {})}>{docUtils.frameDescription(f)} </div>
                        }
                    })}
                </div>
            </div>
    </div>

    </div>
}