import {useContext, useEffect, useReducer, useRef, useState, memo} from "react";
import {FrameInfo} from "../structs/FrameInfo";
import {DocumentContext, GoodsContext, EditorContext} from "../App";
import {Switch} from "@mui/material";
import {DraggFrame} from "./DraggFrame";
import {FrameTypes} from "../structs/FrameTypes";
import {PageMode} from "../structs/PageMode";
import {usePagePosition} from "../hooks/usePagePosition";
import {useDocument} from "../hooks/useDocument";
import {ContextMenu, ContextMenuItem, useContextMenu} from "../hooks/useContextMenu";
import {frameManager} from "./FrameManager";
import {initialConfig} from "../InitialConfig";
import {useImages} from "../hooks/useImages";

//import CryptoJS from "crypto";
//import {ContextMenuItem} from "./FrameMenu";



const posReducer = (state, action) => {
    return state;
}


const Frame = (props)=>{
//function Frame(props){
    //const [info, dispatch] = useReducer(posReducer, FrameInfo);
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
    //const [modalShow, setModalShow] = useState(false);
    //const [popShow, setPopShow] = useState(false);
    const target = useRef(null);
    const [editable, setEditable] = useState(false);
    const [contentText, setContentText] = useState('');
    const myFrame = useRef();
    const [pos, handleMouseMove, handleXY] =  usePagePosition();
    const [loadDoc, saveDoc, docUtils] = useDocument();
    const [showContextMenu, renderContextMenu] = useContextMenu();
    const [lastRect, setLastRect] = useState(null);
    const  [Goods, dispatchGoods] = useContext(GoodsContext);
    //const DefaultClickText = "poklepáním myši změňte text";
    const [needDefaultText, setNeedDefaultText] = useState(null);
    const [getLogo] = useImages();


/*
    const crypto = require('crypto');
    const string = "password";
    const hash = crypto.createHash('sha256').update(string).digest('hex');

    console.log(hash);
*/

    //const [labelText, ]


    useEffect(()=>{
        //console.log('Test NEED: ' + editable);
        if(editable){
          //  console.log('Test NEED: NE');
            setNeedDefaultText(false);
            return;
        }
        let need = initialConfig.needClickText(props.fi.textContent);
        //console.log('Test NEED END: ' + need + ' pro ' + props.fi.textContent);
        setNeedDefaultText(need);
    }, [Cfg.pageMode, editable]);


    useEffect(()=>{
        //console.log('Effect editable...' + needDefaultText);
    }, [needDefaultText])

    useEffect(()=>{
        //myFrame.current = props.fi;
        if(props.fi.width == 0){
            let bb = target.current.getBoundingClientRect();
            let bb1 = handleXY(bb.x, bb.y, bb.width, bb.height);
            let fi = {...props.fi};
            //fi.tempWidth = bb1.width;
            //console.log('Reaslize X 20');
            dispatch({type:'frame', payload:{frame:fi, verb:'realSize'}});
        }
    }, []);

/*
    useEffect(()=>{
        console.log('Frames changed...');
    }, [Cfg.frameMan.Frames]);
*/

    let selected = Cfg.frameMan?.Selected?.includes(props.fi.tempId);
    /*
    if(Cfg.frameMan.Selected && Cfg.frameMan.Selected.length > 0){
        if(Cfg.frameMan.Selected[0] == props.fi.tempId){
            selected = true;
        }
    }
    */
    useEffect(()=>{
        switch (props.fi.dataType){
            case FrameTypes.TEXT:
                break;
            case FrameTypes.DATA:
                break;
            case FrameTypes.CUSTOM:
                switch (Cfg.pageMode){
                    case PageMode.EDIT:
                        setContentText('');
                        break;
                    case PageMode.PREVIEW:
                        setContentText(props.fi.textContent);
                        break;
                }

                break;
        }
    }, [Cfg.pageMode])



    useEffect(()=>{
        switch (props.fi.dataType){
            case FrameTypes.TEXT:{
                let txt = props.fi.textContent.replace(/</g, '&lt;');
                txt = txt.replace(/>/g, '&gt;');
                let lines = txt.split("\n");
                if(lines.count <= 1){
                    setContentText(props.fi.textContent);
                }else{
                    let divs =  lines.join('<br/>');
                    setContentText(divs);
                }}
                break;
            case FrameTypes.CUSTOM: {
                if (Cfg.pageMode == PageMode.PREVIEW) {
                    let txt = props.fi.textContent.replace(/</g, '&lt;');
                    txt = txt.replace(/>/g, '&gt;');
                    let lines = txt.split("\n");
                    if (lines.count <= 1) {
                        setContentText(props.fi.textContent);
                    } else {
                        let divs = lines.join('<br/>');
                        setContentText(divs);
                    }
                }else{
                    setContentText(' ');
                }}
                break;
            case FrameTypes.DATA:
                //console.log('Nastavuji Content...' + props.fi.title)
                setContentText('UPRAVIT...!');//adverb ? adverb.data[props.fi.dataName] : props.fi.title);
                break;
        }
    }, [props.fi, Cfg.frameMan.Frames, Cfg.pageMode]);

    //let pageOffset = props.page.getOffsetMm();

    let labelStyles = {
        position: 'absolute',
        left: ((props.fi.realSize.x + Cfg.pageOffset.x) + 'mm'),
        top: ((props.fi.realSize.y + Cfg.pageOffset.y - 5) + 'mm'),
        backgroundColor:'silver',
        fontSize:'0.75em',
        padding:'0px 5px',
    };

    //let styles = {};

    const posInMM = {
        top: ((props.fi.realSize.x + Cfg.pageOffset.y)),
        left: (props.fi.realSize.y + Cfg.pageOffset.x),
        width: 210,
        height:1
    };

    //console.log('fi top: ' + props.fi.top);
    //console.log('Offset x: ' + Cfg.pageOffset.x);

    props.pos.calc = {...posInMM};
    let align = 'left';

    switch (props.fi.font?.align ?? 'L'){
        case 'R':
            align = 'right';
            break;
        case 'C':
            align = 'center';
            break;
    }

    const dragStart = (ev)=>{
        if(!Cfg.frameMan.Selected.includes(props.fi.tempId)){
            ev.preventDefault();
            return false;
        }
        dispatchEditor({type:'dragStartPos', payload:props.fi.realSize});
        props.dragStart(ev, props.fi.tempId)
    }

    const styles = {
        top: posInMM.top + 'mm',
        left: posInMM.left + 'mm',
        width: posInMM.width + 'mm',
        height:'auto',// posInMM.height + 'mm'
        minHeight: '1em',
        minWidth: '1em',
        fontSize: (props.fi.font?.size ? parseFloat(props.fi.font?.size) : 8.5) + 'pt',
        fontWeight: (props.fi.font?.bold ? 'bold' : ''),
        fontStyle: (props.fi.font?.italic ? 'italic' : ''),
        textAlign: align,
        fontFamily:(props.fi.font?.family ?? '' ),
        outline:'0px'//(props.fi.editable ? 'solid red 5px' : '0px')
    };


    //console.log(styles);


    useEffect(()=>{
        //console.log('UseEffect realSize');
    }, [props.fi.realSize]);

    if(props.fi.isSection){
        styles.top = ((props.fi.realSize.y + Cfg.pageOffset.y) + 'mm');
        styles.left = (Cfg.pageOffset.x + 'mm');
        styles.width = ('210mm');
        //styles.height = '0.5mm';
        styles.backgroundColor = 'orange';
        styles.border = 'solid 0px transparent';



    }else{
//        console.log('realSize: ');
//        console.log(props.fi.realSize);
//        console.log(props.fi);
        styles.width = (props.fi.realSize.widthByText ? 'auto' : props.fi.realSize.width + 'mm');// (props.fi.width > 0 ? (props.fi.width * 1) + 'mm' : 'auto');
        //console.log(styles);
        //styles.height = (props.fi.height > 0 ? (props.fi.height) + 'mm' : 'auto');
        styles.height = (props.fi.realSize.heightByText ? 'auto' : props.fi.realSize.height + 'mm');// (props.fi.width > 0 ? (props.fi.width * 1) + 'mm' : 'auto');
        styles.left = ((props.fi.realSize.x + Cfg.pageOffset.x) + 'mm');
        styles.top =((props.fi.realSize.y + Cfg.pageOffset.y) + 'mm');

        styles.borderTop = Editor.dragging ? 'dotted 1px silver' : props.fi.border.top ? 'solid 1px black' : '';
        styles.borderRight = Editor.dragging ? 'dotted 1px silver' : props.fi.border.right ? 'solid 1px black' : '';
        styles.borderBottom = Editor.dragging ? 'dotted 1px silver' : props.fi.border.bottom ? 'solid 1px black' : '';
        styles.borderLeft = Editor.dragging ? 'dotted 1px silver' : props.fi.border.left ? 'solid 1px black' : '';


        //let rShadow = Cfg.dragging ? 'dotted 1px silver' : props.fi.border.left ? ' black' : '';


        //styles.boxShadow = '-1px 0px 0px 0px black, 1px 0px 0px 0px black';*/
        //styles.margin = 'auto';
        //styles.display = 'flex';

        if(!editable){
            styles.display=  'flex';
            styles.alignItems = 'center';
            switch (props.fi.font.align){
                case 'C':
                    styles.justifyContent = 'center';
                    break;
                case 'L':
                    styles.justifyContent = 'start';
                    break;
                case 'R':
                    styles.justifyContent = 'end';
                    break;
            }
            switch (props.fi.font.valign){
                case 'M':
                    styles.alignItems = 'center';
                    break;
                case 'T':
                    styles.alignItems = 'start';
                    break;
                case 'B':
                    styles.alignItems = 'end';
                    break;
            }
        }else{
            //Po ukončení editace je třeba udělat ošetření na správnou velikost
            styles.outline = 'solid 1px navy';
            //styles.maxWidth = (210 - props.fi.realSize.x) + 'mm';
        }


        //styles.borderColor = 'black'
        switch(Cfg.pageMode) {
            case  PageMode.EDIT:
                switch (props.fi.dataType) {
                    case FrameTypes.TEXT:
                        styles.backgroundColor = editable ? 'lightYellow' : 'white';
                        break;
                    case FrameTypes.DATA:
                        if(props.fi.dataType.length > 0){
                            if(props.fi.dataName.startsWith('customer.')){
                                styles.backgroundColor = '#FFFFE0';
                            }else{
                                if(props.fi.dataName.startsWith('firm.')){
                                    styles.backgroundColor = '#98FB98';
                                }else{
                                    styles.backgroundColor = '#F5DEB3';
                                }
                            }
                        }else{
                            styles.backgroundColor = 'lightcyan';
                        }

                        break;
                    case FrameTypes.CUSTOM:
                        styles.backgroundColor = 'silver';
                        break;
                }
                break;
            case  PageMode.PREVIEW:
                switch (props.fi.dataType) {
                    case FrameTypes.TEXT:
                        styles.backgroundColor = 'white';
                        break;
                    case FrameTypes.DATA:
                        styles.backgroundColor = 'white';
                        break;
                    case FrameTypes.CUSTOM:
                        styles.backgroundColor = props.fi.textContent ? 'palegreen' : 'pink';
                        styles.minHeight = '1.2em';
                        //styles.outline = editable ? 'solid 5px red' : '0px';
                        //styles.backgroundColor = 'red';
                        break;
                }
                break;
            case  PageMode.PRINT:
                switch (props.fi.dataType) {
                    case FrameTypes.TEXT:
                        styles.backgroundColor = 'white';
                        break;
                    case FrameTypes.DATA:
                        styles.backgroundColor = 'lightcyan';
                        break;
                    case FrameTypes.CUSTOM:
                        styles.backgroundColor = 'silver';
                        break;
                }
                break;
        }
    }
/*
    if(editable){
        styles.backgroundColor = 'lightYellow';
    }

 */
/*
    useEffect(()=>{
        if(Cfg.dragging){
            console.log('Dragging frame...');
            styles.borderTop = 'solid 1px silver';
        }
    }, [Cfg.dragging]);
*/

    //const styles = stylesx;

    /*
    const styles = {
        width: (props.fi.width > 0 ? (props.fi.width) + 'mm' : 'auto'),
        height: (props.fi.height > 0 ? (props.fi.height) + 'mm' : 'auto'),
        left: ((props.fi.left + Cfg.pageOffset.x) + 'mm'),
        top: ((props.fi.top + Cfg.pageOffset.y) + 'mm'),
    }
    */




    if(Editor.pressedKeys.includes('ControlLeft') || Editor.pressedKeys.includes('ControlRight')){
        styles.outline = 'dotted 1px black';
        styles.backgroundColor = 'rgb(255, 255, 255, 0.5)';
        //styles.opacity = 0.25;
    }



    //styles.outline = 'dotted ' + (Cfg.frameMan.Selected[0] == props.fi.tempId ? '2px red' : '1px silver');
    if(selected){
        styles.zIndex = 10;
        styles.outline = 'dotted 2px red';

        /*
        styles.borderTop = props.fi.border.top ? 'solid 1px red' : 'dotted 1px red';
        styles.borderRight = props.fi.border.right ? 'solid 1px red' : 'dotted 1px red';
        styles.borderBottom = props.fi.border.bottom ? 'solid 1px red' : 'dotted 1px red';
        styles.borderLeft = props.fi.border.left ? 'solid 1px red'  : 'dotted 1px red';
         */
        //styles.outline = props.fi.border.top ? 'solid 1px red' : 'dotted 1px red';



    }



    if(Cfg.frameMan.Selected && Cfg.frameMan.Selected.length > 0){
        if(Cfg.frameMan.Selected[0] == props.fi.tempId){
            if(props.fi.isSection){
                styles.backgroundColor = 'red';
            }else{
                //styles.borderColor = 'red';
            }

        }
    }

    const select = ()=>{
        console.log('SELECT ME...');
    }

    //console.log('XX: ' + props.prop.width);

    const editMe = (ev)=>{
        //console.log('editace');

        ev.preventDefault();
        //ev.target.scrollIntoView();
        //console.log('X: ' + ev.clientX + '; Y: ' + ev.clientY);
        //console.log(ev);

        if(Cfg.frameMan.Selected?.length > 0){

        }

        let par = {...props.fi};
        //target.current.focus();

        //dispatch({type:'edit',payload:{frame:par, verb:'set'}});
        if(ev.button == 0) {
            if (Editor.pressedKeys.includes('ShiftLeft') || Editor.pressedKeys.includes('ShiftRight')) {
                console.log('Shift pridani....');
                dispatch({type: 'edit', payload: {frame: par, verb: 'change'}});
            } else {
                dispatch({type: 'edit', payload: {frame: par, verb: 'set'}});
            }
        }
    }



    const contextMenu = (ev)=>{
        //console.log('Context menu Frame: ' + ev);
        ev.preventDefault();
        //ev.nativeEvent.preventDefault();
        ev.stopPropagation();
        let par = {...props.fi};
        if(!Cfg.frameMan.Selected.includes(props.fi.tempId)) {
            dispatch({type: 'edit', payload: {frame: par, verb: 'set'}});
        }
        //dispatch({type:'sel',payload:par});

        //let rect =  ev.target.getBoundingClientRect();

        //let newXY = handleXY(ev.clientX, ev.clientY, 10, 10);

        //newXY.x += Cfg.pageOffset.x;
        //newXY.y += Cfg.pageOffset.y;

        //console.log('newXY menu');
        //console.log(newXY);

        let contMenu = {...ContextMenu, x:ev.clientX, y:ev.clientY, items:[]};
        let delItem = {...ContextMenuItem};
        delItem.text = 'Smazat';
        delItem.callback = ()=>{
            //console.log('Callback Menu...')
            docUtils.removeFrame(props.fi);
            //dispatch({type: 'frame', payload:{verb:'remove', tempId:props.fi.tempId}});
        };
        contMenu.items.push(delItem);


        let duplItem = {...ContextMenuItem};
        duplItem.text = 'Duplikovat';
        duplItem.callback = ()=>{
            let frx = {...props.fi};
            frx.dbId = -1;
            let newPos = {...frx.realSize};
            newPos.x += 10;
            newPos.y += 10;
            docUtils.addFrame({position: newPos, page: Cfg.currentPage, byFrame: frx});

            //console.log('Callback Menu...')
            //docUtils.removeFrame(props.fi);
            //frx.dbId = -1;
            //docUtils.addFrame({position: posInMm, page: Cfg.currentPage, byFrame: frx});
            //dispatch({type: 'frame', payload:{verb:'remove', tempId:props.fi.tempId}});
        };
        contMenu.items.push(duplItem);

/*
        let str = 'Hello world!';
        var strHash = md5(str);
        console.log('The MD5 hash is: ' + strHash);
*/

        let key = '#Copy:' + (new Date()).getTime();
        dispatch({type:'property', payload:{propertyName:'copyHash', value:key}});
        let copyItem = {...ContextMenuItem};
        copyItem.text = 'Kopírovat';
        copyItem.callback = ()=>{
            sessionStorage.setItem('clipBoard', JSON.stringify(props.fi));
            //navigator.clipboard.writeText(key + "|" + JSON.stringify(props.fi)).then(()=>{});
        };
        contMenu.items.push(copyItem);

        showContextMenu(contMenu);
/*
        let cMenu = {position:{x:ev.clientX, y:ev.clientY}, from:'Page',
            items:[
                {text:'smazat', click:(ev)=>{
                //    dispatch({type:'removeFrame', payload:props.fi});
                        Cfg.contextMenu = null;// !!!!musím nastavit - closure
                        //dispatch({type:'none', payload:{...Cfg, contextMenu:null}});
                        docUtils.removeFrame(props.fi);
                }}
            ]};

        dispatch({type:'none', payload:{...Cfg, contextMenu:cMenu}});

*/
        return false;
        //
        //setModalShow(true);
    }

    const onInput = (ev)=>{
    };



/*
    useEffect = ()=>{
        window.addEventListener('paste', paste);
        window.addEventListener('copy', copy);
        return ()=>{
            window.removeEventListener('paste', paste);
            window.removeEventListener('copy', copy);
        }
    }
*/
    let content = '';
    switch (props.fi.dataType){
        case FrameTypes.TEXT:
            content = props.fi.textContent;
            break;
        case FrameTypes.DATA:
            content = props.fi.title ? props.fi.title : props.fi.dataName;;
            break;
        case FrameTypes.CUSTOM:
            content = props.fi.title ? props.fi.title : props.fi.volName;
            break;
    }

    const focusIn = (ev)=>{
        //console.log('FOCUS...');

    };

    const dblClick = (ev)=> {
        switch(props.fi.dataType){
            case  FrameTypes.TEXT:
                setEditable(Cfg.pageMode != PageMode.PREVIEW);
                break;
            case  FrameTypes.CUSTOM:
                setEditable(Cfg.pageMode == PageMode.PREVIEW);
                break;
            case  FrameTypes.DATA:
                break;
        }

    };

    const focusOut = (ev)=>{
        //console.log('BLUR...' + target.current.innerText);
        //console.log('BLUR2...' + contentText);
        //props.fi.content = target.current.innerText;
        let boundBox = ev.target.getBoundingClientRect();
        if(props.fi.width == 0){
            let rectInMM = handleXY(boundBox.x, boundBox.y, boundBox.width, boundBox.height);
            //props.fi.tempWidth = rectInMM.width;
        }
        switch (props.fi.dataType){
            case FrameTypes.TEXT:
                if(Cfg.pageMode == PageMode.EDIT){
                    target.current.innerHTML = target.current.innerHTML.replace(/<div><br(\/)*><\/div>/g, "<div>&nbsp;</div>");
                    let iText = target.current.innerText;//.split('\n');
                    /*
                    console.log('TEXT...');
                    console.log(iText);
                    console.log(target.current.innerHTML);
                    console.log(target.current.textContent);
                    console.log(target.current.value);
                    console.log('--------------------------------');
                     */
                    let it2 = iText;//.map((t)=>t).join("\n");
                    let fi = {...props.fi, textContent:it2};
                    setContentText(it2);

                    dispatch({type: 'frame', payload: fi});
                    //dispatch({type: 'sel', payload: -1});
                    //console.log('')
                    //console.log('BLUR...' + target.current.innerText);
                    setEditable(false);
                }
                break;
            case FrameTypes.CUSTOM:
                if(Cfg.pageMode == PageMode.PREVIEW){
                    let iText = target.current.innerText;//.split('\n');
                    let it2 = iText;//.map((t)=>t).join("\n");
                    let fi = {...props.fi, textContent:it2};
                    setContentText(it2);
                    dispatch({type: 'frame', payload: fi});
                    //dispatch({type: 'sel', payload: -1});
                    //console.log('Custom preview: ' + fi.previewText);
                    //console.log('BLUR...' + target.current.innerText);
                    setEditable(false);
                }
                break;
            case FrameTypes.DATA:
                break;
        }

    };

/*
    useEffect(()=>{
        if(props.fi.editable && !editable){

            //console.log('Editable ....X');
            //styles['outline'] = '5px';
            //setEditable(editable);
        }
    })
*/

    const leftHandleStyle = {...posInMM,
        width:'6mm', height:'6mm',backgroundColor:'red', zIndex:styles.zIndex + 1};
    leftHandleStyle.left =  (leftHandleStyle.left - 3) + 'mm';
    leftHandleStyle.top =  (leftHandleStyle.top - 3 + posInMM.height/2) + 'mm';


    const roundRect = (r)=>{
        return {x: Math.round(r.x), y: Math.round(r.y), width: Math.round(r.width), height:Math.round(r.height)};
    }

    const uploadedLogo = ()=>{
        console.log('Logo Uploaded...');
    }

    useEffect(()=>{
          if(props.fi.dataType == FrameTypes.DATA && props.fi.dataName == 'firm.logo'){
              console.log('Change Data na LOGO...');
              getLogo();
              /*
              if(!Editor.logo){

                  //dispatch({type: 'singleCall', payload: {key: 'logoUpload', call: uploadedLogo()}});
              }else{
                  console.log('LOGO....');
              }*/
          }
    }, [props.fi.dataName])

    useEffect(()=>{
        const paste = (ev)=>{
            //console.log('Paste into Frame...');

            if(editable) {
                ev.preventDefault();
                //ev.cancelBubble
                var text = '';
                if (ev.clipboardData || ev.originalEvent.clipboardData) {
                    text = (ev.originalEvent || ev).clipboardData.getData('text/plain');
                } else if (window.clipboardData) {
                    text = window.clipboardData.getData('Text');
                }

                console.log(text);
                target.current.innerText = text;
                /*
                            if (document.queryCommandSupported('insertText')) {
                                document.execCommand('insertText', false, text);
                            } else {
                                document.execCommand('paste', false, text);
                            }
                 */

            }
        }

        const copy = (ev)=>{
            console.log('Copy from Frame...');
        }

        if(editable){
            target.current.focus();
            var sel, range;
            //console.log('RANGE....');
            let fr = new FrameInfo();
            //console.log(target.current.innerText + ' vs ' + fr.textContent)
            if(target.current.innerText == fr.textContent) {
                try {
                    if (window.getSelection && document.createRange) {
                        range = document.createRange();
                        range.selectNodeContents(target.current);
                        sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                    } else if (document.body.createTextRange) {
                        range = document.body.createTextRange();
                        range.moveToElementText(target.current);
                        range.select();
                    }
                }catch(ex){
                    console.error(ex);
                }
            }

        }else{
            let rect = roundRect(target.current.getBoundingClientRect());
            rect.y += window.scrollY;
            //console.log('Rect for ' + props.fi.tempId);
            //console.log(rect);
            let rr = rect;// {x: rect.x, y: rect.y, width: rect.width, height:rect.height};

            if(lastRect == null){
                //console.log('Nastavuji inir rect...')
                setLastRect(rr);
            }else{
                //console.log(lastRect);
                if(lastRect.x != rr.x || lastRect.y != rr.y || lastRect.width != rr.width || lastRect.height != rr.height){
                    /*
                    console.log('SizeChanged');
                    console.log(lastRect);
                    console.log(rr);
                    console.log('----------------');
                     */
                    setLastRect(rr);

                    let inMM = handleXY(rect.x, rect.y, rect.width, rect.height);
                    //console.log('!!!!!Zmena velikosti....');
                    //console.log(rr);
                    let ff = {...props.fi};
                    let rrSize = {...ff.realSize, x:Math.round(inMM.x), y:Math.round(inMM.y), width:Math.round(inMM.width), height:Math.round(inMM.height)};
                    ff.realSize = rrSize;
                    //console.log('Reaslize X 10');
                    //console.log(ff.realSize);
                    if(isNaN(rrSize.x) || isNaN(rrSize.y) || isNaN(rrSize.width) || isNaN(rrSize.height)){
                        //Chtělo by zjistit, proč to nastává
                        return;
                    }
                    dispatch({type:'frame', payload:{frame:ff, verb:'realSize'}});
                    //console.log(inMM);

                }
            }
            //console.log(rect);
        }
        if(editable) {
            window.addEventListener('paste', paste);
            window.addEventListener('copy', copy);
        }
        return ()=>{
            window.removeEventListener('paste', paste);
            window.removeEventListener('copy', copy);
        }
    })

    const frameResized = (ev)=>{
        /*
        let rect = ev.target.getBoundingClientRect();
        console.log('Resized: ');
        console.log(rect);
         */
    }

    //console.log("Render frame " + props.fi.tempId);

    const dragStyle = {...styles, position:'absolute'};

    const mouseDown = (ev)=>{
        ev.stopPropagation();
        //ev.bubbles = false;
       // ev.preventDefault();
    }

    const getDataText = ()=>{
        switch (props.fi.dataType){
            case FrameTypes.TEXT:
            case FrameTypes.CUSTOM:
                if(!editable){

                    //return props.fi.textContent;
                }
                let txt = '';
                if(needDefaultText){
                    //console.log('DATA dataText: ANO...');
                    txt = initialConfig.defaultClickText;
                }else {
                    //console.log('Text => html');
                    //console.log(props.fi.textContent);

                    //console.log('DATA dataText: NEE...');
                    if (props.fi.dataType == FrameTypes.CUSTOM && props.fi.title?.length > 0) {
                      //  console.log('DATA dataText: NEE 2X...');
                        txt = props.fi.title;
                    } else {
                        txt = props.fi.textContent.replace(/\</g, '&lt;');
                        txt = txt.replace(/\>/g, '&gt;');
                        txt = txt.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    }
                    if(txt == initialConfig.defaultClickText){
                        txt = '';
                    }
                }
                //console.log('TEXT: ' + txt);
                return txt;
            case FrameTypes.DATA:
                //console.log('INFO: ');
                //console.log(props.fi);
                if(props.fi.dataName.startsWith('firm.')){
                    let firmKey = props.fi.dataName.split('.', 2)[1];
                    return Cfg.firmaInfo[firmKey];
                }else{
                    if(props.fi.dataName.startsWith('cust.') && Editor.customerData){
                        let custKey = props.fi.dataName.split('.', 2)[1];
                        if(Cfg.pageModeExt == PageMode.ADV_PREVIEW){
                            return Editor.customerData[custKey];
                        }else{
                            return custKey;
                        }
                    }else{
                        if(Cfg.dataId > 0 && Editor.carFields?.car && Object.keys(Editor.carFields.car).length > 0){
                            if(Editor.carFields.car[props.fi.dataName]){
                                return Editor.carFields.car[props.fi.dataName];
                            }else{
                                return '';
                            }
                        }
                        return  props.fi.title;
                    }
                }
        }
    };
/*
    let caption = '---';
    switch (props.fi.dataType){
        case FrameTypes.TEXT:
            caption = contentText;
            break;
        case FrameTypes.DATA:
            //console.log('INFO: ');
            //console.log(props.fi);
            if(props.fi.dataName.startsWith('firm.')){
                //console.log('INFO 2: ');
                caption = 'FIRM...';
            }else{
                caption = props.fi.title;
            }
            break;
        case FrameTypes.CUSTOM:
            if(Cfg.pageMode == PageMode.PREVIEW){
                caption = props.fi.title;
            }else{
                caption = ' ';
            }

            break;
    }
*/
/*
    console.log('Caption: ');
    console.log(props.fi);
    console.log(contentText);
    console.log(props.fi.previewText);
*/
    let ret = '';
    //console.log('PageMode: ' + Cfg.pageMode);

    switch (Cfg.pageMode){
        case PageMode.PREVIEW:
            ret = <>
                <div onInput={(ev)=>onInput(ev)}
                     contentEditable={editable}
                     data-mode={Cfg.pageMode}
                     ref={target}
                     className='ramec'
                     style={styles}
                     onFocus={(ev)=>focusIn(ev)}
                     onBlur={(ev)=>focusOut(ev)}
                     onDoubleClick={(ev)=>dblClick(ev)}
                     suppressContentEditableWarning={true}
                     dangerouslySetInnerHTML={{__html:(getDataText())}}></div>
            </>;
            break;
        case PageMode.EDIT:
            let txtCtx = getDataText();
            /*
            switch(props.fi.dataType){
                case FrameTypes.DATA:
                    if(props.fi.dataName.startsWith('firm.')){
                        let firmKey = props.fi.dataName.split('.', 2)[1];
                        txtCtx = Cfg.firmaInfo[firmKey];
                    }else{
                        txtCtx = contentText;
                    }
                    break;
                default:
                    txtCtx =contentText;
            }

             */
            let spanStyles = '';
            if(!editable){
                switch (props.fi.font.align){
                    case 'C':
                        spanStyles += 'text-align:center';
                        break;
                    case 'R':
                        spanStyles += 'text-align:right';
                        break;
                    default:
                        spanStyles += 'text-align:left';
                        break
                }

                if(spanStyles.length > 0){
                    spanStyles = 'style="' + spanStyles + '"';
                }
                txtCtx = '<span ' + spanStyles +'>' + txtCtx + '</span>';
            }
            ret = <>
                <div onInput={(ev)=>onInput(ev)}
                     contentEditable={editable}
                     onResize={(ev)=>{frameResized(ev)}}
                     ref={target}
                     data-mode={Cfg.pageMode}
                     className='ramec'
                     onContextMenu={contextMenu} ref={target}
                     onClick={(event) => {editMe(event);}}
                     style={styles}
                     onMouseDown={(ev)=>{mouseDown(ev)}}
                     onFocus={(ev)=>focusIn(ev)}
                     onBlur={(ev)=>focusOut(ev)}
                     onDoubleClick={(ev)=>dblClick(ev)}
                         draggable onDragStart={(ev)=>dragStart(ev)}
                     onDragEnter={(ev)=>props.dragEnter(ev, props.fi.tempId)}
                     onDragEnd={(ev)=>props.dragEnd(ev)}
                     suppressContentEditableWarning={true}
                     dangerouslySetInnerHTML={{__html:(getDataText())}}></div>
            </>
            break;
        case PageMode.PRINT:
            ret = <>
                <div data-dbg={'PRINT'} className='ramec'
                     onContextMenu={contextMenu}
                     ref={target}
                     data-mode={Cfg.pageMode}
                     onClick={(event) => {editMe(event);}} style={styles}
                     onFocus={(ev)=>focusIn(ev)}
                     onBlur={(ev)=>focusOut(ev)}
                     onDoubleClick={(ev)=>dblClick(ev)}
                     draggable onDragStart={(ev)=>props.dragStart(ev, props.fi.tempId)}
                     onDragEnter={(ev)=>props.dragEnter(ev, props.fi.tempId)}
                     onDragEnd={(ev)=>props.dragEnd(ev)}
                     suppressContentEditableWarning={true}
                     dangerouslySetInnerHTML={{__html:(getDataText())}}></div>
            </>
            break;
    }


/*
    console.log('styles: ');
    console.log(parseFloat(8.5));
    console.log(styles);
*/

    useEffect(()=>{
        //console.log('Effect editable...' +editable + ' vs ' + needDefaultText);
/*
        let aa = new Abc();
        aa.label = 'abc';
        console.log('Test TS...');
        console.log(aa);
 */
        if(props.fi.realSize.widthByText){
            if(editable){
                setNeedDefaultText(false);
            }else{
                setNeedDefaultText(initialConfig.needClickText(props.fi.textContent));
            }

        }else{
            setNeedDefaultText(false);
        }
    }, [editable, props.fi.realSize.widthByText, props.fi.realSize.heightByText])

    //console.log('Render...');
    return <> {ret}{selected && !editable &&
        <DraggFrame styles={dragStyle} frame={props.fi} innerComp={ret} innerChild={target.current}
                    dragStart={props.dragStart} pos={{}} dragEnter={props.dragEnter} dragEnd={props.dragEnd}></DraggFrame>}</>;

}

export default memo(Frame);