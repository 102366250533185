import {useContext, useEffect, useState} from "react";
import {DocumentContext} from "../App";
import {usePostData} from "../hooks/usePostData";


export function OpenDocumentDialog(props){
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [selDoc, setSelDoc] = useState();
    const [files, setFiles] = useState(null);
    const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);

    useEffect(()=>{
        //props = {...props, ok:()=>{OkEvent();}};
        fetchJson('getDocList').promise
            .then(resp=>resp.json())
            .then(json=>{
                setFiles(json);
            });
        //doFetchFiles({url:Cfg.dataUrl + '/getDocList'});
        //console.log('poFetch...');
    }, []);

    const clickItem = (ev, id)=>{
        //console.log('Click item: ' + ev.target + '; KEY: ' + id);
        setSelDoc(id);
        let doc = files.data.filter((d)=>d.id == id);
        if(doc.length > 0){
            props.dataBag.selection = doc[0];
        }

        /*
        props.selected.length = 0;
        props.selected.push(files.filter((d)=>d.id == id));
         */
    }


    const doubleClickItem = (ev)=>{
        //console.log('Double click item: ' + ev.target);
    }



    let classes = ['openFilePanel'];
    return <div className={classes.join(' ')}>
        {!files && <>Načítám data</>}
        {files &&  <> {
                files.data.length == 0 ? <div>Žádná data</div> :
                    <div>
                        {files.data.map((d, index)=>{
                    let itemStyle = {minWidth: '300px'};
                    if(selDoc && selDoc == d.id){
                        itemStyle = {...itemStyle, backgroundColor:'silver'}
                    }
                    return <div  onDoubleClick={doubleClickItem} key={index} onClick={(ev)=>clickItem(ev, d.id)} style={itemStyle}>{d.nazev}</div>
            })}
                    </div>}
        </>
        }

    </div>
}