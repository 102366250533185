export const EditorReducer = (state, action)=>{
    let payload = action.payload;

    const innerReducer = (state, action)=>{
        switch (action.type) {
            case 'image':
                switch (payload.type){
                    case 'logo':
                        console.log('Logo Request....');
                        break;
                }
                break;
            case 'all':
                return payload;
            default:
                let st = {...state};
                st[action.type] = payload;
                return st;
                return st;
        }
        return state;
    }


    let ret = innerReducer(state, action);
    /*
    console.log('Set Editor Session...');
    console.log(action);
    console.log(ret);
     */
    sessionStorage.setItem('editor', JSON.stringify(ret));
    return ret;

/*
    console.log('Jsem ve Editor switchi...');
    console.log(state);
    console.log(action);
    console.log('... jsem ve Editor switchi');
*/

}