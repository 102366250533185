import {useState, createContext, useContext, useRef, useEffect, useCallback, useReducer} from "react";
import {DocumentContext, GoodsContext, EditorContext} from "../App";
import Frame from "./Frame.js";
import {useNavigate, useSearchParams} from "react-router-dom";
import {usePostData} from "../hooks/usePostData";
import {PageMode} from "../structs/PageMode";
import {UseAlert} from "../hooks/useAlert";
import {ContextMenu, ContextMenuItem, useContextMenu} from "../hooks/useContextMenu";
import {useDocument} from "../hooks/useDocument";
import {EditorState} from "../EditorState";
import {EditorReducer} from "../EditorReducer";
import {DragFake} from "./DragFake";


export function Page(){    
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [queryParameters] = useSearchParams();
    const navigate = useNavigate();
    const dragItem = useRef({});
    const dragOverItem = useRef();
    const koefMm = useRef(null);
    const offsetMm = useRef(null);
    //const koefMm = useRef({});
    const [dragLineY, setDragLineY] = useState(-1);
    const [dragLineX, setDragLineX] = useState(-1);
    const [dragLineW, setDragLineW] = useState(-1);
    const [dragLineH, setDragLineH] = useState(-1);
    const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);
    const [showContextMenu, renderContextMenu] = useContextMenu();
    const [Editor, dispatchEditor] = useContext(EditorContext);
    const [loadDoc, saveDoc, docUtils] = useDocument();
    const ref = useRef();
    const  [Goods, dispatchGoods] = useContext(GoodsContext);
    const [dragFakes, setDragFakes] = useState([]);
    const [selectBox, setSelectBox] = useState({mouseDown:false, dragged:false,
        startPosition:{x:0, y:0},
        bounds:{x:0, y:0, width:0, height:0},
        mouseCaptured: false
    });

    //const [mode, setMode] = useState(Cfg.pageMode);

    Cfg.page = this;

    let id = queryParameters.get('docId');
    let service = queryParameters.get('s');
    let paramKeys =  queryParameters.keys();

    if(queryParameters.has('s')){

    }
/*
    useEffect(()=>{
            setMode(Cfg.pageMode);
        }, [Cfg.pageMode]);

  */
    const calculatePxToMm = (pxX, pxY)=>{
        let pos = {x:pxX * koefMm.current.x, y:pxY * koefMm.current.y};
        pos.x = Math.round(pos.x);
        pos.y = Math.round(pos.y);
        return pos;
    };

    const dragOver = (e) => {
        e.preventDefault();
        if(!dragItem?.current?.mouse){
            return;
        }
        let posun = {
            x: dragItem.current.mouse.x - dragItem.current.pos.x,
            y: dragItem.current.mouse.y - dragItem.current.pos.y,
        };

        let posun1 = {
            left: (e.clientX - posun.x +  window.scrollX) * koefMm.current.x,
            top: (e.clientY - posun.y  +  window.scrollY) * koefMm.current.y,
            width: dragItem.current.pos.width * koefMm.current.x,
            height: dragItem.current.pos.height * koefMm.current.y
        };

        posun1.left -= offsetMm.current.x;
        posun1.top -= offsetMm.current.y;

        posun1.top = Math.round(posun1.top);
        posun1.left = Math.round(posun1.left);
        posun1.x = posun1.left;
        posun1.y = posun1.top;

        setDragLineY(posun1.top);
        setDragLineX(posun1.left);
        //console.log(dragItem.current);
        //console.log(posun1);
        dispatchEditor({type:'dragCurrentPos', payload:posun1});
        setDragLineW(posun1.left + posun1.width);
        setDragLineH(posun1.top + posun1.height);
    }

    const dragStart = useCallback((e, position) => {
        let pos = e.target.getBoundingClientRect();
        let mousePoint = {x:e.clientX, y:e.clientY};
        dragItem.current = {index:position,pos:pos,mouse:mousePoint};

        let fr =  Cfg.frameMan.Frames.find((f)=>{return f.tempId == dragItem.current.index;});// Cfg.frameMan.Frames[dragItem.current];
        dragItem.current.frame = fr;

        //console.log('Start Dragg....');
        //dispatch({type:'property', payload:{propertyName:'dxragging', value:true}});
        dispatchEditor({type:'dragging', payload:true});

        /*
        if(Cfg.frameMan.Selected.length < 0 || fr.tempId != Cfg.frameMan.Selected[0]){
            dispatch({type:'edit',payload:{frame:{...fr}, verb:'set'}});
        }
         */
        if(!Cfg.frameMan.Selected.includes(fr.tempId)){
            dispatch({type:'edit',payload:{frame:{...fr}, verb:'set'}});
        }

        let sels = Cfg.frameMan.Frames.filter((f)=>{
           return Cfg.frameMan.Selected.includes(f.tempId) && f.tempId != fr.tempId;
        });
        setDragFakes(sels);
    });

    const dragEnter = useCallback((e, position) => {
        e.preventDefault();
        dragOverItem.current = position;
    });


    useEffect(()=>{
        //console.log(offsetMm);
        //console.log(koefMm);
        //if(!koefMm.current || !offsetMm.current) {

            let pp = ref.current;// document.getElementById('page');
            let br = pp.getBoundingClientRect();
            koefMm.current = {x: (210 / br.width), y: (297 / br.height)};
            offsetMm.current = calculatePxToMm(br.x + window.scrollX, br.y + window.scrollY);

            if (Cfg.pageOffset.x != offsetMm.current.x && (Cfg.pageOffset?.y ?? 0) == 0) {
                console.log('NEW offsetMM Dispatch: ');
                dispatch({
                    type: 'properties', payload: {
                        pageOffset: offsetMm.current,
                        pageKoef: koefMm.current
                    }
                });
            }
            //const url = new URL(document.location.href);
        //}
    });//Cfg.customFields, Cfg.dataFields]); //Offset se spočítá až po fetchi všech dat...

    useEffect(()=>{
        if(!id){
            let pars = [];

            for (const key of paramKeys) {
                pars.push(key + '=' + queryParameters.get(key));
            }
        }
        //console.log('Page effect: ' + br.width);
    }, [Cfg])

    const getOffset = ()=>{
        return {...offsetMm};
    };

/*
    useEffect(()=>{
        console.log('Selected changed...' + Cfg.frameMan.Selected?.length);
        if(Cfg.frameMan.Selected?.length == 1){
            let alerts = Cfg.alerts.filter((a)=>{return a.key != 'shiftSelect';});
            alerts.push({msg: 'Vybrat více objektů lze zmáčknutím klávesy SHIFT a kliknutím na další objekt', level:'info', key:'shiftSelect'});
            console.log('Prodavam alert...');
            dispatch({type:'property', payload:{name:'alerts', value:alerts}});
        }
    }, [Cfg.frameMan.Selected])
*/

    useEffect(()=>{
        console.log('Alerty změněny');
        console.log(Cfg.alerts);
    }, [Cfg.alerts])

    const getCustoms = ()=>{
        let payCustom = {title: "Načítám volitelná data", id: 'FetchCustoms', verb: "add"};
        dispatch({type: "task", payload: payCustom});

        fetchJson('getCustoms').promise.then(Response => Response.json())
            .then(json => {
                dispatch({type: "customs", payload: json});
            })
            .finally(() => {
                payCustom.verb = "remove";
                dispatch({type: "task", payload: payCustom})
            });
    }
/*
    const  testSync = ()=>{
        console.log('Test sync start....');
        let rr = async ()=>{
            let url = Cfg.dataUrl;
            url += "/customerData/?token=" + Cfg.firmaInfo.token;
            let pro = await fetch(url);
            let resp = await pro.json();
            console.log('Konec Async');
            console.log(resp);
            return resp;
        }

        let r2 = rr();
        console.log('Po Async....');
        console.log(r2)
    }
*/
    const getCustomerFields = ()=>{
        //console.log('Načítám data kupujícího' +  + Cfg.dataId);
        let payData = {title: "Načítám data kupujícich", id: 'FetchCustomerData', verb: "add"};
        dispatch({type: "task", payload: payData});

        getDataFields();

        let url = Cfg.dataUrl;
        url += "/customerData/?token=" + Cfg.firmaInfo.token;

        //if(Cfg.pageMode == PageMode.PREVIEW){
        if(Cfg.dataId){
                //data.goodsId = Cfg.dataId;
                url += '&goodsId=' + Cfg.dataId;
        }
        url += '&t=' + (new Date()).getTime();

        console.log('Nacitam data Customer call: ' + Cfg.dataId + '; Mode: ' +  Cfg.pageMode + '; Url:'  + url);
        fetch(url)
            .then(resp=>{
                console.log('Customer resp: ');
                console.log(resp);
                //console.log(resp);
                return resp.json();
            })
            .then(json=>{
                //console.log('Goods Fetch X..');
                let fields = json.fields;
                let goods = json.car;
                let customer = json.customer;
                console.log('Customer fields...');
                console.log(json);
                console.log(fields);
                console.log(customer);
                console.log(goods);
                dispatchEditor({type: "customerFields", payload: fields});
                dispatchEditor({type: "customerData", payload: customer});
            })
            .finally(() => {
                payData.verb = "remove";
                dispatch({type: "task", payload: payData})
            });

    }

    const getDataFields = ()=>{
        console.log('Načítám data');
        let payData = {title: "Načítám pevná data", id: 'FetchData', verb: "add"};
        dispatch({type: "task", payload: payData});

        let url = Editor.goodsUrl;
        url += "?token=" + Cfg.firmaInfo.token;
        let postData = {goodsId: 0};
        if(Cfg.dataId){
            console.log('Mam data ID....');
            //postData.goodsId = Cfg.dataId;
            url += "&id=" + Cfg.dataId;
        }

        fetch(url, {})
            .then(resp=>{
                console.log('Goods resp: ');
                console.log(resp);
                //console.log(resp);
                return resp.json();
            })
            .then(json=>{
               // console.log('Goods Fetch X..');
                //console.log(json);
                let goods = json.fields;
                let car = json.car;
                //console.log(goods);
                //console.log(car);
                dispatchEditor({type: "carFields", payload: {fields:goods, car:car}});
            })
            .finally(() => {
                payData.verb = "remove";
                dispatch({type: "task", payload: payData})
            });

    }

    useEffect(()=>{
        console.log('GoodsUrl Call....');
        if(Editor.goodsUrl) {
            console.log('GoodsUrl: ' + Editor.goodsUrl)
            dispatch({type: 'singleCall', payload: {key: 'dataFields', call: getDataFields}});
        }
    }, [Editor.goodsUrl])

    useEffect(()=>{
        console.log('UseEffect dataId: ' + Cfg.dataId + '; ' + Cfg.firmaInfo?.kod_firmy);
        //if(Cfg.dataId) {

            //setMode(PageMode.PREVIEW);

            //dispatch({type:'singleCall', payload:{key:'getCustoms', call:getCustoms}});
            console.log('Nacitam data Customer pro ' + Cfg.dataId);
            dispatch({type: 'singleCall', payload: {key: 'getCustomerFields'+ (Cfg.dataId ?? 0)
                        + '-' +  (Cfg.firmaInfo.kod_firmy) + '**'+ Cfg.dataId, call: getCustomerFields}});
            //dispatch({type: 'singleCall', payload: {key: 'testFc-'+ Cfg.dataId, call: testSync}});

        //}else{

        //}
    }, [Cfg.firmaInfo, Cfg.dataId])

    useEffect(()=>{
        //console.log('Render dragLineY');
    }, [dragLineY]);

    const drop = useCallback((e) => {
        //console.log('DROP: ' + dragItem.current);
        //console.log(Cfg);
        e.preventDefault();
        //console.log('POS: ' + ;
        if(false) {
            let fr = Cfg.frameMan.Frames.find((f) => {
                return f.tempId == dragItem.current.index;
            });// Cfg.frameMan.Frames[dragItem.current];
            // console.log(fr);
            //console.log(fr.w)
            let posun = {
                x: dragItem.current.mouse.x - dragItem.current.pos.x,
                y: dragItem.current.mouse.y - dragItem.current.pos.y,
            };


            fr.realSize.x = (e.clientX - posun.x + window.scrollX) * koefMm.current.x;
            fr.realSize.x -= offsetMm.current.x;
            fr.realSize.x = Math.round(fr.realSize.x);
            //fr.left = fr.realSize.x;

            fr.realSize.y = (e.clientY - posun.y + window.scrollY) * koefMm.current.y;
            fr.realSize.y -= offsetMm.current.y;
            fr.realSize.y = Math.round(fr.realSize.y);
            //fr.top = fr.realSize.y;


            let frames = [...Cfg.frameMan.Frames];
            frames[dragItem] = fr;
            //let cfg = [...Cfg];
            Cfg.frameMan.Frames = frames;
        }
        dragItem.current = null;
        dragOverItem.current = null;
        setDragLineY(-1);
        setDragLineX(-1);
        setDragLineW(-1);
        setDragLineH(-1);

        let sels = [...Cfg.frameMan.Frames];
        /*.filter(f=>{
            return Cfg.frameMan.Selected.includes(f.tempId);
        })*/

        let posunDrag = {
            x: (Editor.dragCurrentPos.x - Editor.dragStartPos.x),
            y: (Editor.dragCurrentPos.y - Editor.dragStartPos.y)
        };


        let newFrames = Cfg.frameMan.Frames.map(f=>{
            if(Cfg.frameMan.Selected.includes(f.tempId)){
                let rs = {...f.realSize,
                    x:f.realSize.x += posunDrag.x,
                    y:f.realSize.y += posunDrag.y
                };
                f.realSize = rs;
                return f;
            }else{
                return f;
            }
        });
        dispatch({type:'frames', payload:newFrames});
        /*
        sels.forEach(f=>{
            if(Cfg.frameMan.Selected.includes(f.tempId)){
                f.realSize.x += posun.x;
                f.realSize.y += posun.y;
            }
        });
        */
        //dispatch({type:'frame', payload:{frame:fr, verb:'update'}});
        dispatchEditor({type:'dragging', payload:false});


      //  setList(copyListItems);
    });

    const contextMenu = async (ev)=>{
        //console.log('Context menu Page: ' + ev);
        ev.preventDefault();
        ev.stopPropagation();
        if(Cfg.pageMode == PageMode.PREVIEW){
            return;
        }



        let rect = ev.target.getBoundingClientRect();
        let x = ev.clientX + window.scrollX;// - rect.left;
        let y = ev.clientY + window.scrollY;// - rect.top;

        //console.log('X: ' + x + '; Y: ' + y + '; scroll: ' + window.scrollY)

        let posInMm = calculatePxToMm(x, y);

        posInMm.x -= offsetMm.current.x;
        posInMm.y -= offsetMm.current.y;


        let contMenu = {...ContextMenu, x:ev.clientX - 10, y:ev.clientY - 10, items:[]};
        let delItem = {...ContextMenuItem};
        delItem.text = 'Nový objekt';


        delItem.callback = ()=>{
//          console.log('Callback Menu...')
//          dispatch({type: 'frame', payload:{verb:''}});
            docUtils.addFrame({position:posInMm, page:Cfg.currentPage});
//          dispatch({type:'addFrame', payload:{position:posInMm, page:Cfg.currentPage}});
        };
        contMenu.items.push(delItem);




        //let txt = await navigator?.clipboard?.readText();//.then((txt)=>{
        let txt = sessionStorage.getItem('clipBoard');

        if(txt) {

            let pasteItem = {...ContextMenuItem};
            pasteItem.text = 'Vložit';
            pasteItem.callback = () => {
                let frx = JSON.parse(txt);
                frx.dbId = -1;
                docUtils.addFrame({position: posInMm, page: Cfg.currentPage, byFrame: frx});
            };
            contMenu.items.push(pasteItem);
        }
        //console.log('Paste: ' + txt);



        showContextMenu(contMenu);


        //console.log('...page dispatch none')
    }

    const click = (ev)=>{
//        Cfg.contextMenu = {...Cfg, contextMenu:null};//null;
        //dispatch({type:'none', payload:{...Cfg, contextMenu:null}});
        dispatch({type:'property', payload:{name:'contextMenu', value:null}});
    }

    //let dragStyle = {position: 'absolute'};

    let relStyles = {
        left: '0mm',
        top:'0mm',
        width:'50mm',
        position:'relative',
        backgroundColor:'silver',
    }

    //Cfg.pageOffset = {...offsetMm.current};
    //PAGE... {Cfg.dataUrl}
    let lineYStyle = null;
    let lineXStyle = null;
    let lineWStyle = null;
    let lineHStyle = null;
    if(dragLineY > -1){
        //console.log("Offset: " + offsetMm.current + '; YLine: ' + dragLineY);
        //console.log(offsetMm);
        lineYStyle = {
            height:'0.5px',
            position:'absolute',
            top: (dragLineY + offsetMm.current.y) + 'mm',
            left:offsetMm.current.x + 'mm',
            width: '210mm',
            zIndex:220,
            border: '0px',
            backgroundColor: 'rgba(255, 0, 0, 0.15)',
        };
    }
    if(dragLineX > -1){
        //console.log("Offset: " + offsetMm.current + '; YLine: ' + dragLineY);
        //console.log(offsetMm);
        lineXStyle = {
            height:'297mm',
            position:'absolute',
            left: (dragLineX + offsetMm.current.x) + 'mm',
            top: offsetMm.current.y + 'mm',
            width: '0.5mm',
            zIndex:220,
            border: '0px',
            borderRight: '1px',
            backgroundColor: 'rgba(255, 0, 0, 0.15)',
        };
    }
    if(dragLineW > -1){
        //console.log("Offset: " + offsetMm.current + '; YLine: ' + dragLineY);
        //console.log(offsetMm);
        lineWStyle = {
            height:'297mm',
            position:'absolute',
            left: (dragLineW + offsetMm.current.x) + 'mm',
            top: offsetMm.current.y + 'mm',
            width: '0.5mm',
            zIndex:220,
            border: '0px',
            backgroundColor: 'rgba(255, 0, 0, 0.15)',
        };
    }

    if(dragLineH > -1){
        //console.log("Offset: " + offsetMm.current + '; YLine: ' + dragLineY);
        //console.log(offsetMm);
        lineHStyle = {
            height:'1px',
            position:'absolute',
            top: (dragLineH + offsetMm.current.y) + 'mm',
            left:offsetMm.current.x + 'mm',
            width: '210mm',
            zIndex:220,
            border: '0px',
            backgroundColor: 'rgba(255, 0, 0, 0.15)',
        };
    }

    let selId = -1;

    if(Cfg.frameMan.Selected && Cfg.frameMan.Selected.length > 0){
        selId = Cfg.frameMan.Selected[0];
    }

    //console.log('Mode: ' + mode);

    const styles = {
    };
    if(Cfg.pageMode == PageMode.PREVIEW){
        styles.border = 'solid 2px navy';
        styles.backgroundColor = 'cornsilk';
    }

    const showHelp = ()=>{
        switch (Cfg.pageMode){
            case PageMode.EDIT:
                let msgs = [
                    {msg:'Nový objekt lze přidat z menu kliknutím pravým tlačítkem na stránku'},
                    {msg:'Vybrat více objektů lze zmáčknutím klávesy SHIFT a kliknutím na další objekt', id:'shift-select'},
                    {msg:"Upravit text v objektu typu 'Text' lze poklepáním"},
                    {msg:"Upravit data v objektu typu 'Data' lze v záložce 'Údaje' v rozbalovacím seznamu"},
                    {msg:"Text v objektu typu 'Poznámka' lze měnit pouze v náhledu"},
                    {msg:"Testovací inzerát pro objekt typu 'Data' lze vybrat pomocí odkazu v záložce Údaje"},
                    {msg:"Typ objektu na Text/Data/Poznámka lze změnit v sekci 'Údaje'"},
                    {msg:"Rychlá klávesa pro uložení je Ctrl + S"},
                    {msg:"Smazat otevřenou šablonu lze z hlavního menu (pokud je uložená)"},
                    {msg:"Stisknutí klávesy Ctrl zobrazí okraje všech objektů"},
                    {msg:"Objekty lze kopírovat, vkládat a duplikovat pomocí menu vyvolaného pravým tlačítkem"}
                ];
                let tipIndex = Editor?.tipForEdit ? Editor.tipForEdit : 0;
                //console.log(Editor);
                let msg = msgs[tipIndex];
                let alerts = Cfg.alerts.filter((a)=>{return a.key != 'help';});
                alerts.push({msg: msg.msg, level:'info', key:'help'});
                let nextTip = tipIndex + 1;
                if(nextTip >= msgs.length){
                    //console.log("Menim tip na nulu, length: " + msgs.length + '; nextTip: ' + nextTip);
                    nextTip = 0;
                }
                dispatchEditor({type:'tipForEdit', payload:nextTip});
                dispatch({type:'property', payload:{name:'alerts', value:alerts}});
                //let alerts = Cfg.alerts}
                //dispatch({type:'NONE', payload:{...Cfg, alerts:alerts}});
                break;
        }
    }

    useEffect(()=>{
        showHelp();//14
        let urls = new URL(document.location.href);
        let docId = urls.searchParams.get('openId');
        if(docId && docId > 0) {
            console.log('mamDocId: ' + docId);
            let info = {
                props: {dataBag: {selection: {id: docId, makeCopy:true}}}
            };
            docUtils.openById(info);

            //info.props?.dataBag?.selection;
            navigate('/?token=' + Cfg.firmaInfo.token);
        }

    }, [])

    useEffect(()=>{
        showHelp();
    }, [Cfg.documentId])

    useEffect(()=>{
        //console.log(`**********Pressed keys: ${Editor.pressedKeys}`);
        //console.log(EditorState.pressedKeys);
    }, [Editor.pressedKeys])



    useEffect(() => {
        // clean up code

        const ku = (ev)=>{
            let newKeys = Editor.pressedKeys.filter((k)=>{
                return k != ev.code;
            });
            dispatchEditor({type:'pressedKeys', payload:newKeys});
        }

        const kd = (ev)=>{
            /*
            console.log('kd');
            console.log(Cfg);
            console.log('-----------------------');
             */
            const {ctrlKey, keyCode, code} = ev;


            if(!Editor.pressedKeys.includes(ev.code)){
                dispatchEditor({type:'pressedKeys', payload:[...Editor.pressedKeys, ev.code]});
            }



            //if (ev.ctrlKey && ev.keyCode === 83) {

            if (ctrlKey && keyCode === 83) {
                ev.preventDefault();
                /*
                console.log('Ctrl + S 22');
                console.log(Cfg);
                console.log('-----');
                 */

                //docUtils.saveDoc();
                saveDoc();
                //dispatch({type:'save'});
                return false;
            }
        };

        const windowBlur=()=>{
            //console.log("Window BLUR...");
        }
/*
        const paste = (ev)=>{
            console.log("PASTE: ....");
        }
*/
        const windowFocus=()=>{
            //console.log("Window Focus...");
            dispatchEditor({type:'pressedKeys',payload:[]});
            dispatch({type:'property',payload:{name:'tasks', value:[]}});
            showHelp();
        }

        //window.removeEventListener('keydown', kd);
        window.addEventListener('keydown', kd);
        window.addEventListener('keyup', ku);
//        window.addEventListener('paste', paste);
        //window.addEventListener('onblur', windowBlur);
        window.onblur = windowBlur;
        window.onfocus = windowFocus;
        return () => {
            window.removeEventListener('keydown', kd);
            window.removeEventListener('keyup', ku);
//            window.removeEventListener('paste', paste);
        }
    }, [Editor.pressedKeys]);


     //console.log('Page mod" ' + Cfg.pageMode);

    const keyDown = (ev)=>{
        console.log('KDOWN: ' + ev.code);
    }

    useEffect(()=>{
        if(!Editor.dragging){
            setDragFakes([]);
        }
    }, [Editor.dragging])

    const  mouseUp = (ev)=>{

        let bb = {...selectBox.bounds};
        let lt =  calculatePxToMm(bb.x, bb.y);
        lt.y -= offsetMm.current.y;
        lt.x -= offsetMm.current.x;
        let rb =  calculatePxToMm(bb.x + bb.width, bb.y + bb.height);
        rb.y -= offsetMm.current.y;
        rb.x -= offsetMm.current.x;



        let mm = {...selectBox, mouseDown: false, dragged: false};
        if(mm.mouseCaptured){
            ev.target.releasePointerCapture(ev.pointerId ?? 1);
            mm.mouseCaptured = true;
        }

        let mmRect = calculatePxToMm();
        let sels =  Cfg.frameMan.Frames.filter((fr)=>{
           /* console.log("CONTAINS:");
            console.log(fr.realSize)
            console.log(lt);
            console.log(rb);*/
            return  fr.realSize.x > lt.x
                && fr.realSize.x + fr.realSize.width < rb.x
                && fr.realSize.y > lt.y
                && fr.realSize.y + fr.realSize.height < rb.y
        });



        setSelectBox(mm);
        if(sels.length > 0){
            //console.log('pocet vybranych: ' + sels.length);
            dispatch({type: 'selRange', payload: sels.map(s=>s.tempId)});
        }

    }

    const  mouseDown = (ev)=>{
        console.log('Mouse DOWN...PAGE')
        let mm = {...selectBox, mouseDown: true, startPosition:{
            x:ev.clientX + window.scrollX, y:ev.clientY + window.scrollY
        }};
        setSelectBox(mm);

        if(!mm.mouseCaptured){
            ev.target.setPointerCapture(ev.pointerId ?? 1);
            mm.mouseCaptured = true;
        }
    }

    //SELECT ramecek

    const normalizeRect = (point1, point2)=>{
        let xNew = point1.x < point2.x ? [point1.x, point2.x] : [point2.x, point1.x];
        let yNew = point1.y < point2.y ? [point1.y, point2.y] : [point2.y, point1.y];
        return {x:xNew[0], y:yNew[0], width:xNew[1] - xNew[0], height: yNew[1] - yNew[0]};
    }

    const  mouseMove = (ev)=>{
        if(selectBox.mouseDown){
            //console.log('Mouse MOVE...' + selectBox.startPosition.x);
            let mm = {...selectBox, dragged: true, currentPosition:{x:ev.clientX + window.scrollX, y:ev.clientY + window.scrollY}};

            //mm.currentPosition.x -= window.scrollX;
            //mm.currentPosition.y -= window.scrollY;
            //let mm2 = {...mm};
            //let bounds = {...mm.bounds};


            let bounds = normalizeRect(mm.currentPosition, mm.startPosition);
            /*
            if(mm.currentPosition.x < mm.startPosition.x || mm.currentPosition.y < mm.startPosition.y){
                bounds = {x:mm.currentPosition.x, y:mm.currentPosition.y,
                    width:mm.startPosition.x - mm.currentPosition.x,
                    height:mm.startPosition.y - mm.currentPosition.y,
                }
            }else{
                bounds = {x:mm.startPosition.x, y:mm.startPosition.y,
                    width:mm.currentPosition.x - mm.startPosition.x,
                    height:mm.currentPosition.y - mm.startPosition.y,
                }
            }
            */
            mm.bounds = bounds;
            setSelectBox(mm);
        }
    }



    return <>

        <div ref={ref} style={styles} id='page' onContextMenu={contextMenu} onDragOver={dragOver}
             onMouseDown={(ev)=>{mouseDown(ev)}}
             onMouseUp={(ev)=>{mouseUp(ev)}}
             onMouseMove={(ev)=>{mouseMove(ev)}}
             onClick={click}>

            {Cfg.frameMan.Frames.filter((f)=>{return f.page == Cfg.currentPage;}).map((f, index)=> {

                let fr =  <Frame  fi={f} dragStart={dragStart} pos={{}} dragEnter={dragEnter} dragEnd={drop}
                                 key={f.tempId}></Frame>

                //return <> {(f.tempId == selId ?  (<div><DraggFrame key={'dr' + f.tempId} innerItem={fr}></DraggFrame></div>) : fr)}</>
                return fr;

            })}

            {selectBox.dragged && <div style={{
                position:'absolute',
                left:selectBox.bounds.x + 'px',
                top:selectBox.bounds.y + 'px',
                backgroundColor:'rgb(0, 255, 255, 0.5)',
                width:(selectBox.bounds.width) + 'px',
                height:(selectBox.bounds.height) + 'px',
                border:"solid 1px navy",
                zIndex:100
            }}></div>
            }
            {
                dragFakes.map((f, index)=>{
                    //{console.log('FAKE: ' + index);}
                        return <DragFake key={'dragFake-' + index} frame={f}/>
                })
            }
            {dragLineY > -1 &&<>
            {/*
                    left: (dragLineX + offsetMm.current.x + 'mm'),
                    top:((dragLineH + offsetMm.current.y) + 'mm')
            */}
                <div style={lineYStyle}></div>
                <div className={'prevent-select'} style={{position:"absolute", zIndex:100, padding:'2px',
                    fontSize:'0.75em',
                    backgroundColor: "black",
                    color:'white',
                    left:offsetMm.current.x + 210 + 'mm',
                    top: (window.scrollY) + 'px'
                }}
                >
                    x: {Math.round(dragLineX)}; y: {Math.round(dragLineY)} [mm]
                </div>
                </>
            }
            {dragLineX > -1 &&<>
                <div style={lineXStyle}></div>
            </>
            }
            {dragLineW > -1 &&<>
                <div style={lineWStyle}></div>
            </>
            }
            {dragLineH > -1 &&<>
                <div style={lineHStyle}></div>
            </>
            }
            {/*
            <div style={{
                backgroundColor:"orange",
                cursor:'hand',
                width:'0.5mm',height:'190mm',position:'absolute', left:'100mm', top:'100mm'}}></div>
*/}
            {/*
            <DraggFrame innerItem={'Ahoj'} show={true} x={'abcd'}></DraggFrame>*/}
        </div>
        {renderContextMenu()}
    </>
}