import { Outlet, Link } from "react-router-dom";
import {useContext} from "react";
import {DocumentContext} from "../App";
import {usePostData} from "../hooks/usePostData";
import {Alerts} from "../components/Alerts";
import {Dialogs} from "../components/forms/Dialogs";
import {ProgressOver} from "../components/ProgressOver";
import {UseAlert} from "../hooks/useAlert";

const Layout = (props) => {
    const [Cfg, dispatch] = useContext(DocumentContext);
    const {orientation, ...other} = props;
    const [showAlert, renderAlerts] = UseAlert();

    const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);


    return (
    <>
        {renderAlerts()}

        {Cfg.dialogs.length > 0 &&
            <Dialogs></Dialogs>
        }
        {Cfg.tasks.length > 0 &&
            <ProgressOver></ProgressOver>
        }

        {/*
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>            
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>           
      </nav>
*/}
      <div style={{flexDirection:(orientation == 'row' ? 'row' : 'column')}} id='main'>
        <Outlet />
          <Alerts></Alerts>
      </div>
    </>
  )
};

export default Layout;