import {useContext, useEffect, useRef, useState} from "react";
import {DocumentContext} from "../../App";
import * as React from "react";
import {AlertButtons, AlertInfo, AlertTypes, UseAlert} from "../../hooks/useAlert";
import {Checkbox} from "@mui/material";

export const PositionTab = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const form  = useRef();
    const [sel, setSel] = useState(null);
    const [showAlert, renderAlerts] = UseAlert();
    const widthCB = useRef();
//    const sel = useRef(null);

    //let sel = null;

    //let sel = null;
    /*
    if(Cfg.frameMan.Selected.length > 0) {
        Cfg.frameMan.Frames.find((f) => {
            return f.tempId == Cfg.frameMan.Selected[0];
        });
    }
*/
    useEffect(()=>{
        //console.log('useEffrct length: ' + Cfg.frameMan.Selected.length);
        if(Cfg.frameMan.Selected.length > 0){
            //console.log('useEffrct: 1');
            setSel(Cfg.frameMan.Frames.find((f)=>{return f.tempId == Cfg.frameMan.Selected;}));
//            console.log('Zmena sel: ' + sel)
        }else{
            //console.log('useEffrct: 2');
            //sel.current = null;
            setSel(null);
            //sel = null;
        }
    }, [Cfg.frameMan.Frames]);




    const formChanged = (ev)=>{
        if(sel != null) {
                let framePage = parseInt(~~form.current.elements.framePage.value ?? 0, 10);
                //console.log('page ' + framePage + ' vs ' + sel.page);
                if(framePage != sel.page){
                    let alertI ={...AlertInfo};
                    alertI.type = AlertTypes.CONFIRM;
                    alertI.buttons = [AlertButtons.NO, AlertButtons.YES];
                    alertI.content = <>Opravdu chcete přesunout objekt na stránku {framePage}?</>;
                    alertI.callBack = (data)=>{
                        //console.log('Callback...');
                        if(data.closeButton.name == 'yes'){
                            //sel.page = framePage;
                            let fr = {...sel, page:framePage};
                            dispatch({type:'frame', payload:{frame:fr, verb:'update'}});
                            //fr = {...fr, width:fr.width + 100};
                            //dispatch({type:'frame', payload:fr});
                        }
                    };
                    showAlert(alertI);
                    return;
                }
                // ~~ : it is used as a faster substitute for Math.floor() for positive numbers
                sel.realSize.x = parseInt(~~form.current.elements.posLeft.value ?? 0, 10);
                sel.realSize.y = parseInt(~~form.current.elements.posTop.value ?? 0, 10);
                sel.realSize.width = parseInt(~~form.current.elements.posWidth.value ?? 0, 10);
                sel.realSize.height = parseInt(~~form.current.elements.posHeight.value ?? 0, 10);
                sel.realSize.widthByText = form.current.elements.widthByText.checked;
                sel.realSize.heightByText = form.current.elements.heightByText.checked;

                //sel.left = sel.realSize.x;
                sel.top = sel.realSize.y;
                sel.width = sel.realSize.width;
                sel.height = sel.realSize.height;

            }
            dispatch({type: 'frame', payload: sel});
    }

    let selPages = [];
    for(let i = 0; i < Cfg.pageCount; i++){
        selPages.push(i + 1);
    }
/*
    const widthByText = (ev)=>{
        let wbt = ev.target.checked;
        console.log('WidthBytext: ' + wbt + ' vs ' + sel.realSize.widthByText);
        if(wbt != sel.realSize.widthByText){
            //let fr = {...sel};
            let rs = {...sel.realSize, widthByText:wbt};
            console.log('WidthBytext: ' + wbt);
            console.log(rs);
            dispatch({type:'frame', payload:{verb:'update', frame: {...sel, realSize:rs}}});
            widthCB.current.checked = wbt;
        }
    }
*/
    return <div>
        {sel != null && <>
            <form ref={form} onChange={formChanged} onSubmit={(ev)=>{ ev.preventDefault()}}>
        <div className='props'>
            <div><label>X: </label><input type='number' onChange={()=>{}}  name='posLeft' value={Math.round(sel.realSize.x || 0)}/> <span>mm</span></div>
            <div><label>Y: </label><input type='number' onChange={()=>{}} name='posTop' value={Math.round(sel.realSize.y || 0)}/> <span>mm</span></div>
            <div><label>Šířka: </label><input type='number' onChange={()=>{}} name='posWidth' value={Math.round(sel.realSize.width || 0)}/> mm
                <span className={'valueExt'}><label>
                    <input checked={sel.realSize.widthByText} onChange={()=>{}} type={'checkbox'} name={'widthByText'}/>podle textu</label></span></div>
            <div><label>Výška: </label><input type='number' onChange={()=>{}} name='posHeight' value={Math.round(sel.realSize.height || 0)}/> mm
                <span className={'valueExt'}><label>
                    <input checked={sel.realSize.heightByText} onChange={()=>{}} type={'checkbox'} name={'heightByText'}/>podle textu</label></span></div>
            <div><label>Stránka: </label>
                <select style={{width:'70px'}} onChange={()=>{}} value={sel.page} name={'framePage'}>
                    {selPages.map((p)=>{
                        return <option key={p} value={p}>{p}</option>
                    })}
                </select>
            </div>
        </div>
            </form>
        </>}
    </div>
}