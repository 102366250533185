import {UserField} from "./UserField";

export const UserFieldCategory = (props)=>{
    const {category, ...other} = props;

    //console.log(category);

    return <div style={{border: 'solid 1px black', padding: '5px'}}>
        <h3>{category.categoryName == 'noCategory' ? 'Všeobecná data' : category.categoryName}</h3>
        {category.fields.map((c, index)=>{
            return <UserField key={index} userData={c}></UserField>
        })}
    </div>
}