import {useContext, useEffect, useState} from "react";
import {DocumentContext, EditorContext} from "../App";
import {usePostData} from "../hooks/usePostData";
import {useNavigate} from "react-router-dom";
import {DocInfo, useDocument} from "../hooks/useDocument";
import {PageMode} from "../structs/PageMode";
import {Alerts} from "../components/Alerts";
import {Page} from "../components/Page";
import {PreviewPanel} from "../components/PreviewPanel";
import {FrameProperties} from "../components/FrameProperties";



export const Preview = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
    const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);
    const navigate = useNavigate();
    const [loadDoc, saveDoc, docUtils] = useDocument();
    const [docId, setDocId] = useState(-1);
    const [advId, setAdvId] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [errors, setErrors] = useState([]);


    useEffect(()=>{
        console.log('Use effect []');
        console.log(Cfg)
        let urls = new URL(document.location.href);
        let advId = urls.searchParams.get('advId');
        let docId = urls.searchParams.get('docId');
        setDocId(parseInt(docId));
        setAdvId(advId);
        dispatch({type:'property', payload:{name:'dataId', value:advId}});
        //setLoaded(false);
        if(!docId){
            setErrors([...errors, 'Neplatné id dokumentu'])
        }
        if(!advId){
            setErrors([...errors, 'Neplatné id dat'])
        }
        console.log('NASTAVUJI ID ADVERT...');
    }, []);

    useEffect(()=>{
        if(Cfg.pageModeExt == PageMode.ADV_PREVIEW){
            console.log('Effect AdvPageMode');
            console.log(Cfg);
            //navigate('/?token=' + Cfg.firmaInfo.token);
        }
    }, [Cfg.pageModeExt])

    useEffect(()=>{
        console.log('LoadedX... ' + (loaded ? 'ANO' : 'NE'));
        if(!loaded) {
            if (Cfg.firmaInfo?.jmeno && docId && advId) {
                setLoaded(true);

                //getCustomerFields();

                console.log('Nacitam doc...Doc: ' + docId + '; GoodsId: ' + advId);
                console.log(Cfg);
                let aa = new DocInfo();

                console.log((aa.constructor.name));
                let bb = {name: '', id: docId};
                console.log((bb.constructor.name));
                let docI = new DocInfo(docId, advId);
                loadDoc(docI, 'P');
                //navigate('/');
            }
        }
    }, [Cfg.firmaInfo]);

/*
    const getCustomerFields = ()=>{
        console.log('Načítám data kupujícího');
        let payData = {title: "Načítám data kupujícich", id: 'FetchCustomerData', verb: "add"};
        dispatch({type: "task", payload: payData});

        let url = Cfg.dataUrl;
        url += "/customerData/?token=" + Cfg.firmaInfo.token;
        let data = {goodsId:-1};

        if(Cfg.dataId > 0){
            data.goodsId = Cfg.dataId;
        }

        fetch(url)
            .then(resp=>{
                console.log('Customer resp: ');
                console.log(resp);
                //console.log(resp);
                return resp.json();
            })
            .then(json=>{
                //console.log('Goods Fetch X..');
                let fields = json.fields;
                let customer = json.customer;
                console.log('Customer fields...');
                console.log(fields);
                console.log(customer);
                dispatchEditor({type: "customerFields", payload: fields});
                dispatchEditor({type: "customerData", payload: customer});
            })
            .finally(() => {
                payData.verb = "remove";
                dispatch({type: "task", payload: payData})
            });

    }
*/
    useEffect(()=>{
        console.log('DocId... useEffect: ' + Cfg.templateId + '; data: ' + advId)
        console.log(Cfg);
        if(Cfg.templateId > 0){
            //Cfg.pageMode = PageMode.PREVIEW;
            //Cfg.pageModeExt = PageMode.ADV_PREVIEW;
            setLoaded(true);
            //getCustomerFields();
            let pay = {...Cfg,
                pageMode:PageMode.PREVIEW,
                pageModeExt:PageMode.ADV_PREVIEW,
                dataId: advId,
                id:docId,
                is_template:false
            };
            console.log('PayLoad---');
            console.log(pay);
            dispatch({type: 'none', payload:pay});

            console.log('docLoaded: ');
        }
    }, [Cfg.templateId])


    //console.log('Comp Preview...');
    //console.log(Cfg);
    return <>
        {errors.length > 0 &&
            errors.map((e, index)=>{
                return <div key={index} className={'err'}>{e}</div>
            })
        }
        {Cfg.firmaInfo ?  <>
            {/*
                <div>Preview for {Cfg.firmaInfo.jmeno}</div>
                <div>Dokument {Cfg.id}, data: {Cfg.dataId}</div>*/}
            <div style={{}}>
                {Cfg.error == null ?
                        <><Page></Page><PreviewPanel/></>: <div className={'err'}>Chyba: {Cfg.error.msg}</div>
                }
            </div>
    </>: <>Načítám</>
            }</>

}