import {useContext, useEffect, useState} from "react";
import {usePostData} from "../hooks/usePostData";
import {DocumentContext} from "../App";

export const SelectPreviewCar = (props)=>{
    const {retDlg, ...others} = props;
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);
    const [cars, setCars] = useState(null);
    const [selCar, setSelCar] = useState(0);

    const getCars = ()=>{
        fetchJson('getCars').promise
            .then(resp=>resp.json())
            .then(json=>{
                //console.log('getCars...');
                //console.log(json);
                setCars(json);
            });
    }

    useEffect(()=>{
        if(!cars){
            getCars();
        }
    }, [cars]);


   // console.log(cars);
/*
    let cc = null;
    if(cars){
        let cc = cars.cars;
    }
*/
    const carClick = (ev, carId)=>{
        retDlg.id = carId;
        /*
        console.log('carClick...');
        console.log(carId)
         */
        setSelCar(carId);
        //dispatch({type:'property', payload:{'name':data:id, value:}});
    }

    let selStyle = {
        backgroundColor: 'navy',
        color:'white',
    };

    return<>
        {cars ?
            <div>
                <table>
                    <thead></thead>
                    <tbody>
                    {cars.cars.map((c, index)=>{
                        return <tr key={c.id} style={selCar == c.id ? selStyle : {}}  onClick={(e)=>carClick(e, c.id)}>
                            <td >{c.foto}</td>
                            <td> {c.i_kodm_text}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
            :
            <div>Načítám inzeráty</div>
        }
    </>
}