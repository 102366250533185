import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {DocumentContext, EditorContext} from "../App";
//import {UseAlert} from "./useAlert";
//import {Exception} from "sass";

export const usePostData = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
    //const [loadStage, setLoadStage] = useState(0);
    //const [firstLoad, setFirstLoad] = useState(true);
   // const[addAlert, getAlerts] = UseAlert();
    //prvotni nacteni url
    useEffect(()=>{
        //console.log('useEffect usePostData: 10');
        if(!Cfg.dataUrl) {
            dispatch({type:'getDataUrl', payload:{call:(json)=>{
               //console.log("Call Called ...");
                        let basename = '/';
                        if(json['basename']){
                            basename = json.basename;
                        }
                        dispatch({type:'none', payload:{...Cfg, basename:basename, dataUrl:json.dataUrl, token:json.token}});
                        console.log('NASTAVUJI GOODS: ' + json.goodsUrl);
                        dispatchEditor({type:'goodsUrl', payload: json.goodsUrl});
                        //dispatchEditor({type:'all', payload: {...Editor, apiUrl:json.dataUrl, goodsUrl:json.goodsUrl}});
                    }}});
        };
    }, [Cfg.dataUrl]);
/*
    const afterCheckToken = (json) =>{
        if(json.status == 'OK'){
            let alerts = Cfg.alerts.filter((a)=>{return a.key != 'tokenErr';});
//----------------
            let loadedLocal = false;
            try {
                //if (firstLoad) {
                //  setFirstLoad(false);
                let dataS = sessionStorage.getItem('docData');
                if(dataS) {
                    let data = JSON.parse(dataS);
                    console.log('Local DATA OK X....' + data?.firmaInfo);
                    console.log(data?.firmaInfo);
                    console.log(data);
                    console.log(json.data);
                    data.tasks = [];
                    data.activeAlerts = [];
                    //console.log('Test A1');
                    if (data?.firmaInfo && json?.data) {
                      //  console.log('Test A');
                        if (data?.firmaInfo.kod_firmy == json.data.kod_firmy && data.firmaInfo.token == json.data.token) {
                        //    console.log('Test B');
                            // console.log('SHODA...');
                            dispatch({type: 'NONE', payload: data});
                            loadedLocal = true;
                        }
                    }
                }
                //}
            } catch (ex) {
                console.log(ex);
            }
            //---------------------------
            if(!loadedLocal) {
                dispatch({type: 'NONE', payload: {...Cfg, firmaInfo: json.data, alerts: alerts}});
            }
            //localStorage.setItem("docData", JSON.stringify(ret));

        }else{
            let alerts = Cfg.alerts.filter((a)=>{return a.key != 'tokenErr';});
            alerts.push({msg: 'Neplatný token', level:'err', key:'tokenErr'});
            //alerts.push({msg:'ABCDX', key:(new Date()).getTime()});
            dispatch({type:'NONE', payload:{...Cfg, alerts:alerts}});
        }
    }
*/
    const checkToken = ()=>{
        let urls = new URL(document.location.href);
        let token = urls.searchParams.get('token');
        //console.log('CfgToke> ' + Cfg.token);

        if(!token){
            let alerts = Cfg.alerts.filter((a)=>{return a.key != 'tokenErr';});
            alerts.push({msg: 'Neplatný token', level:'err', key:'tokenErr'});
            //alerts.push({msg:'ABCDX', key:(new Date()).getTime()});
            //dispatch({type:'NONE', payload:{...Cfg, alerts:alerts}});
            dispatch({type:'property', payload:{name:'alerts', value:alerts}});
            return;
        }

        let promiseCheckToken = fetchByJson('checkToken?token=' + token, {user:{token:token}}).promise;
        //fetchByJson('checkToken?token=' + token, {user:{token:token}}).promise
        promiseCheckToken.then(resp=>resp.json())
            .then(json=>{
                //console.log('MAM USERA: ' + JSON.stringify(json));
                if(json.status == 'OK'){
                    let alerts = Cfg.alerts.filter((a)=>{return a.key != 'tokenErr';});
//----------------
                    let loadedLocal = false;
                    try {
                        //if (firstLoad) {
                        //  setFirstLoad(false);
                        let dataS = sessionStorage.getItem('docData');
                        if(dataS) {

                            let data = JSON.parse(dataS);
                            console.log('Local DATA OK X....' + data?.firmaInfo);
                            console.log(data?.firmaInfo);
                            console.log(data);
                            console.log(json.data);
                            data.tasks = [];
                            data.activeAlerts = [];
                            //console.log('Test A1');
                            if (data?.firmaInfo && json?.data) {
                              //  console.log('Test A');
                                if (data?.firmaInfo.kod_firmy == json.data.kod_firmy && data.firmaInfo.token == json.data.token) {
                                    /*
                                    console.log('Test B');
                                    console.log('SHODA...');
                                    console.log(data);

                                     */
                                    dispatch({type: 'NONE', payload: data});
                                    loadedLocal = true;
                                }
                            }
                            try {
                                let dataEditor = sessionStorage.getItem('editor');
                                if(dataEditor){
                                    console.log('Editor');
                                    let de = JSON.parse(dataEditor);
                                    de.pressedKeys = [];
                                   // de.openFirst = Editor?.openFirst ? Editor?.openFirst : 0;
                                    /*
                                    de.from = 'POS 1x:';
                                    console.log(de);
                                    console.log(dataEditor);
                                    console.log(Cfg);

                                     */
                                    dispatchEditor({type:'all', payload:de});
                                }

                            }catch(ex){
                                console.log(ex);
                            }
                        }
                        //}
                    } catch (ex) {
                        console.log(ex);
                    }
                    //---------------------------
                    if(!loadedLocal) {
                        dispatch({type: 'NONE', payload: {...Cfg, firmaInfo: json.data, alerts: alerts}});
                    }
                    //localStorage.setItem("docData", JSON.stringify(ret));

                }else{
                    let alerts = Cfg.alerts.filter((a)=>{return a.key != 'tokenErr';});
                    alerts.push({msg: 'Neplatný token', level:'err', key:'tokenErr'});
                    //alerts.push({msg:'ABCDX', key:(new Date()).getTime()});
                    //dispatch({type:'NONE', payload:{...Cfg, alerts:alerts}});
                    dispatch({type:'property', payload:{name:'alerts', value:alerts}});
                }
            })
    }

    //prvotni nacteni usera
    useEffect(()=>{
        if(Cfg.dataUrl && !Cfg.firmaInfo) {
            //console.log('Effect data URL')
            //checkToken();
            dispatch({type:'singleCall', payload:{key:'checkToken', call:checkToken}});
            //dispatch({type:'loadGood', payload:{key:'goodsLoad', call:loadGoods}});
        }else{

        }
    }, [Cfg.dataUrl])

/*
    useEffect(()=>{
        try {
            let data = JSON.parse(localStorage.getItem('docData'));
            console.log('Local DATA OK....' + data?.firmaInfo);
            console.log(data?.firmaInfo);
            console.log(Cfg);
            if(data && JSON.stringify(data?.firmaInfo) == JSON.stringify(Cfg.firmaInfo)){
                console.log('AHODA...');
                //dispatch({type:'NONE', payload:data});
            }
        }catch(ex){
            console.log(ex);
        }
    }, [Cfg.firmaInfo])
*/

    //async/await pro validaci dialogu...
    const fetchByJson = (apiName, data={}, sync = false)=>{
        //console.log(apiName);
        if(!('user' in data)){
            data.user = {token:Cfg.firmaInfo.token};
        }
        let init = {
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        };
        if(apiName.startsWith('/')){
            apiName = apiName.substring(1);
        }


        let url = Cfg.dataUrl + '/' + apiName;
        //console.log('API_NAME: ' + apiName + '; URL: ' + url);
        //return {promise: fetch(url, init)};
        if(!sync){
            //console.log('Async fetch');
            return {promise: fetch(url, init)};
        }else{
            //console.log('Sync fetch');
            let retVal = null;
            let fce = async ()=>{
                let prom = await fetch(url, init);
                let json = await prom.json();
                retVal = json;
                console.log(retVal);
                return json;
            };
            fce();
            let ppp = new Promise(()=>{
                return 'Abcd';
            })

            //console.log(retVal);
            return fce;
            //return prom;
        }
    };

    const fetchByPost = (apiName, data)=>{
        let fData =  new FormData();
        for(let d in data){
            fData.set(d, data[d]);
        }
        //fData.set('user', Cfg.firmaInfo);
        let init = {
            cache: "no-cache",
            headers: {
          //      'Content-Type': 'application/json'
            },
            method: 'POST',
            body: fData
        };
        return {promise: fetch(Cfg.dataUrl+ apiName, init)};
    };
/*
    const loadedDocument = (doc)=>{
        console.log('Loaded in hook...');
    }
*/
    return [fetchByJson, fetchByPost];
}