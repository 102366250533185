import {usePostData} from "./hooks/usePostData";
import {useGoods} from "./hooks/useGoods";

export const GoodsReducer = (state, action)=> {
    let payload = action.payload;
    const [goods, setGoodId] = useGoods();

    const innerReducer = (state, action) => {
        switch (action.type) {
            case 'all':
                return action.payload;
            default:
                let st = {...state};
                st[action.type] = action.payload;
                return st;
                return st;
        }
        if(action.type == 'id'){
           // setGoodId(action.payload);
        }
        return state;
    }


    let ret = innerReducer(state, action);
    sessionStorage.setItem('goods', JSON.stringify(ret));
    return ret;
}