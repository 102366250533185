import { Outlet } from "react-router-dom";
import { Page } from "../components/Page";
import {Stack, Button, Navbar, Nav, NavDropdown, Container, NavLink} from "react-bootstrap";
import {useCallback, useContext, useEffect, useReducer, useRef, useState} from "react";
import {DocumentContext, EditorContext, GoodsContext} from "../App";
import {FrameProperties} from "../components/FrameProperties";
//import {FrameMenu} from "../components/FrameMenu";
import {ProgressOver} from "../components/ProgressOver";
import {InputName} from "../dialogs/InputName";
import {Dialogs} from "../components/forms/Dialogs";
import {OpenDocumentDialog} from "../dialogs/OpenDocumentDialog";
import {usePostData} from "../hooks/usePostData";
import {SelectPreviewCar} from "../dialogs/SelectPreviewCar";
import {useDocument} from "../hooks/useDocument";
//import {UseAlert} from "../hooks/useAlert";
//import {Alerts} from "../components/Alerts";
import {PageMode} from "../structs/PageMode";
import {PreviewPanel} from "../components/PreviewPanel";
import {AlertButtons, AlertInfo, AlertTypes, UseAlert} from "../hooks/useAlert";
import {FrameTypes} from "../structs/FrameTypes";
import {EditorState} from "../EditorState";
//import {Value} from "sass";


const Home = () => {
    //const [Cfg, dispatch] = useReducer(reducer, initialConfig);
    const addFrame = (ev)=>{
        ev.preventDefault();
        //Cfg.frameMan.addFrame();
        dispatch({type:'add'});
    }
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [Editor, dispatchEditor] = useContext(EditorContext);
    const [Goods, dispatchGoods] = useDocument(GoodsContext);

    const [fetchJson, fetchForm] = usePostData(Cfg, dispatch);
    //const docUtils = useDocument();
    const [loadDoc, saveDoc, docUtils] = useDocument();
    const [showAlert, renderAlerts] = UseAlert();
    //const [firstOpen, setFirstOpen] = useState(true);
    //const[addAlert, getAlerts] = UseAlert();
    //const cbRef = useRef(onKeyDown);


/*
    const saveNamed = ()=>{
        if(!Cfg.documentName){
            save(null);
        }else {
            let saveData = {...Cfg.frameMan.Frames};

            let pay = {title: "Ukládám data", id: 'Fetch-' + Date.now(), verb: "add"};
            dispatch({type: "task", payload: pay});

            let savedata = {action: 'save',
                documentName: Cfg.documentName,
                documentId:Cfg.documentId,
                data: saveData};

            let pp =  fetchJson('save', savedata);
            pp.promise
                .then(resp=>resp.json())
                .then(json=>{
                    console.log('SAVED:' + JSON.stringify(json));
                    //docUtils.loadDoc(json.doc);
                    loadDoc(json.doc);
                });
        }
     };
*/
    const saveAs = (ev)=>{
        if(ev){
            ev.preventDefault();
        }
        saveDoc(true);
        /*
        let props = {
            id:'fileName',
            myText:{text:''}
        };
        let inp =  <InputName {...props} ok={(ev)=>{
            let pp = {...Cfg};
            pp.documentName = inp.props.myText.text;
            //Cfg.documentName = '9874';
            dispatch({type:'none', payload:pp});
            saveNamed()}}></InputName>;

        dispatch({type:'dialog', payload:{ctrl:inp, verb:'add'} });
         */
    }

/*
    useEffect(() => {
        // clean up code
        function kd(ev){
            const {ctrlKey, keyCode} = ev;

            //if (ev.ctrlKey && ev.keyCode === 83) {
            if (ctrlKey && keyCode === 83) {
                ev.preventDefault();
                //docUtils.saveDoc();
                saveDoc();
                //dispatch({type:'save'});
                return false;
            }
        };
        window.removeEventListener('keydown', kd);
        window.addEventListener('keydown', kd);
        return () => {
            window.removeEventListener('keydown', kd);
        }
    }, [Cfg]);
*/
    const save = (ev)=>{
        saveDoc();
    }
/*
    const openDialogClosed = (info)=>{
        //console.log('Loading ...: ' + info.props.dataBag.selection);

        let doc = info.props?.dataBag?.selection;
        if(doc){
          //  console.log("DOC: " + doc);
            let pay = {title: "Načítám data pro " + doc.nazev, id: 'Fetch-Open', verb: "add"};
            dispatch({type: "task", payload: pay});
            let ff = fetchJson('open/' + doc.id);
            ff.promise
                .then(resp=>resp.json())
                .then(json=>{
                    console.log('OpenDoc First...');
                    console.log(json);
                    //let newDoc =  docUtils.loadDoc(json.doc);
                    let newDoc =  loadDoc(json.doc);// docUtils.loadDoc(json.doc);
                });

        }
    }
*/
    const previewCarsClosed = (retDlg)=>{
        //console.log(Cfg);
        dispatch({type:'none', payload:{...Cfg, dataId:retDlg.id, pageMode:PageMode.PREVIEW}});
        //dispatchGoods({type:'id', payload:retDlg.id});
        //dispatch({type:'pageNode', payload:PageMode.PREVIEW});
        //window.open(Cfg.dataUrl + '/pdf/preview/' + Cfg.documentId + '/' + retDlg.id + '?token=' + Cfg.firmaInfo.token + '&t=' + Date.now() , 'preview').focus();
    }

    const preview = (ev)=>{
        //console.log('Preview...');
        if (Cfg.documentId < 1) {
            //alert('Dokument musí být uložen');
            let alertI ={...AlertInfo};
            alertI.type = AlertTypes.MESSAGE_BOX;
            alertI.buttons = [AlertButtons.OK];
            alertI.content = <>Dokument musí být uložen</>;

            alertI.callBack = (data)=>{
                saveDoc();
                /*
                if(data.closeButton.name == 'yes'){
                    dispatch({type:'removeFrame', payload:frame});
                }
                 */
            };
            showAlert(alertI);
            return;
        } else {

            let withData = Cfg.frameMan.Frames.filter((f)=>{
                return f.dataType == FrameTypes.DATA;
            });

            if (withData.length > 0 && !Cfg.dataId) {
                let retDlg = {id: -1};
                dispatch({type:'pageMode', payload:PageMode.PREVIEW});
                let dlg = <SelectPreviewCar retDlg={retDlg} close={(d, btn) => {
                    /*
                    console.log(d);
                    console.log(btn);
                    console.log('Ok Car' + retDlg.id);
                     */
                    if (btn == 'OK') {
                        previewCarsClosed(retDlg);
                    }
                }}>
                </SelectPreviewCar>
                dispatch({
                    type: 'dialog', payload: {
                        ctrl: dlg, verb: 'add', ok: () => {
                            //console.log('Ok Fce')
                        }
                    }
                });
            }
            let fm = {...Cfg.frameMan, Selected:[]}
            dispatch({type:'property', payload:{name:'frameMan', value:fm}});
            dispatch({type:'pageMode', payload:PageMode.PREVIEW});
        }
    }





    const openDocument = ()=>{
        docUtils.openDoc();
        /*
        let props = {
            id:'fileName',
            myText:{text:''}
        };

        let od = <OpenDocumentDialog document={{}} dataBag={{selection:null}}
            close={(d)=>{
                //console.log('Close...' + d.button + '| ' + d);
                openDialogClosed(d);
            }}>
        </OpenDocumentDialog>
        dispatch({type:'dialog', payload:{ctrl:od, verb:'add', ok:()=>{}}});
         */
    }

    const newDocument = (ev)=>{
        //docUtils.loadDoc(null);
        loadDoc(null);
        dispatchEditor({type:'all', payload:{...Editor,
                lastInsertPosition: {...EditorState.lastInsertPosition},
                maxTempId: EditorState.maxTempId
        }});
        ev.preventDefault();
        //dispatch({type:"newDocument", payload:''});
    }

/*
    useEffect(()=>{

        //console.log(Cfg.testData());
        let postData = fetchJson('testovaci', {});
        postData.then(resp=>resp.json()).then(json=>{console.log('TEST: ' + json.stav);});
        console.log('PostData: ');
        //console.log(getFetchPostData);
        //console.log(postData)
    }, []);
*/

    //let text = 'Ahoj';
    //console.log(`Text je ${text}`);

/*
    const ttt = {
        name: 'abcd',
        fce:() => {
            console.log('ttt: ' + this.name);
        }
    };

    let xx = {...ttt};
    xx.name = 'xyz';
    xx.fce();
*/
/*
    class test{
        constructor() {
            this.name = 'abcd';
        }
        fce = ()=>{
            console.log(this.name);
        }

        set nameX(str){
            this.name = str;
        }
    }

    const tt = new test();
    const tt2 = new test();
    tt2.name = 'XYZ';
    tt.fce();
    tt2.fce();
*/

    const smazatSablonu = (ev)=>{
        docUtils.deleteTemplate();
    }


    useEffect(()=>{
        console.log('Open Start 10')
        if(Cfg.firmaInfo?.token){
            if(Cfg.pageMode == PageMode.EDIT) {
                let firstOpen = Editor.openFirst;
                /*
                let sessdata = sessionStorage.getItem('sessData');
                let sess = {};
                if (sessdata) {
                    sess = JSON.parse(sessdata);
                    console.log(sessdata);
                    if (sess?.openFirst) {
                        firstOpen = true;
                    }
                }
                 */
                //console.log('Open Start 20: ' + firstOpen);
                try {
                    if (!firstOpen) {
                        dispatchEditor({type:'openFirst', payload:1})
                        openDocument();

                    }

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }, [Cfg.firmaInfo?.token])

    return <>
        {/*
        {renderAlerts()}

        {Cfg.dialogs.length > 0 &&
            <Dialogs></Dialogs>
        }
        {Cfg.tasks.length > 0 &&
            <ProgressOver></ProgressOver>
        }
*/}
    <Container fluid >
        {Cfg.firmaInfo ? <>
    <Navbar bg="light" className="w-all" style={{width:'90% !important', justifyContent:"space-between"}}  expand="lg">
        <div style={{flexGrow:1}}>
      <Container>
          {/*
        <Navbar.Brand href="">{Cfg.documentName ? (Cfg.documentName + (Cfg.documentId > 0 ? ' (id: ' + Cfg.documentId + ')' : '')) : 'Bez jména'}</Navbar.Brand>
        */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
              <NavDropdown title="Šablona" id="basic-nav-dropdown">
                  <NavDropdown.Item style={{minWidth:'15vw'}} href="" onClick={ev=>newDocument(ev)}>Nová</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1" onClick={openDocument}>Otevřít...</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={(ev)=>save(ev)}>
                      Uložit <span style={{float:'right'}}>[Ctrs + S]</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={(ev)=>saveAs(ev)} href="">
                      Uložit jako...
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#" onClick={(ev)=>preview(ev)}>
                      Náhled
                  </NavDropdown.Item>

                  {Cfg.documentId > 0 &&<>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#" onClick={(ev)=>smazatSablonu(ev)}>
                      Smazat šablonu
                  </NavDropdown.Item></>}
                  {/*
                  <NavDropdown.Divider />

                  <NavDropdown.Item href="#action/3.4">
                      Ukončit
                  </NavDropdown.Item>
                  */}
              </NavDropdown>
              <NavLink className={'menuBtnBold'} onClick={(ev)=>save(ev)}>Uložit</NavLink>
              <NavLink className={'menuBtnBold'} onClick={(ev)=>window.close()}>Zavřít</NavLink>
              {/*
              <NavDropdown title="Stránka" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Záhlaví</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">Patička</NavDropdown.Item>
              </NavDropdown>
              */}
          </Nav>
        </Navbar.Collapse>
      </Container>
        </div>
        <div style={{flexGrow:1, fontWeight:"bolder"}}>
            {Cfg.documentName ? (Cfg.documentName + (Cfg.documentId > 0 ? ' (id: ' + Cfg.documentId + ')' : '')) : 'Bez jména'}</div>
        <div style={{marginRight:'10mm'}}>
            {Cfg.firmaInfo ? '(' + Cfg.firmaInfo.kod_firmy + ') ' + Cfg.firmaInfo.jmeno : '----'}
        </div>
    </Navbar>
        <Page></Page>{Cfg.pageMode == PageMode.EDIT ? <>
            <FrameProperties></FrameProperties>
        {/*<FrameMenu></FrameMenu>*/}</> : <PreviewPanel></PreviewPanel>
        }
    {/*
            <ConfirmationDialog></ConfirmationDialog> */}
        </>  :  <div>Načítám data firmy ...</div> }
    </Container>
    </>
  }
  export default Home;