import {useContext} from "react";
import {EditorContext} from "../App";
import {AlertButtons, AlertInfo, AlertTypes, UseAlert} from "./useAlert";
import {DocNameInput} from "./useDocument";
import {UploadImage} from "../components/UploadImage";

export const useImages = ()=>{
    const [Editor, dispatchEditor] = useContext(EditorContext);
    const [showAlert, renderAlerts] = UseAlert();

    const getLogo = ()=>{
        if(!Editor.logo){
            let alertI ={...AlertInfo};
            alertI.type = AlertTypes.INPUT;
            alertI.buttons = [AlertButtons.CANCEL, AlertButtons.OK];
            let inpData = {name:''};
            alertI.content = <UploadImage/>;
            alertI.callBackAsync = async (data)=>{
                if(data.closeButton.name.toLowerCase() == 'ok'){
                    //dispatch({type:'removeFrame', payload:frame});
                    /*
                    let validJsonProm = await fetchJson('checkName', {

                    });*/
                }
            }
            showAlert(alertI);
        }
    }

    return [getLogo];
}