import {createContext, useContext, useEffect, useRef, useState} from "react";

export const GridRowState = {
    SAVED:'S',
    NEW:'N',
    UPDATED:'U',
    DELETED:'D',
    ERASED: 'E'
};

export const GridContext = createContext();

export const Grid = (props)=>{
    const editedCell = useRef();
    const [edited, setEdited] = useState({row:null, cell:null});
    const oldEditvalue = useRef();
    const [gridData, setGridData] = useContext(GridContext);

    const colStyles = {flex: '0 1 auto',
        padding: '2px 10px',
        border: 'solid 1px black',
        display: 'inline-box'
    };

    const toolsStyle = {
        width:'100px',
        flex: '0 1 auto',
        padding: '2px 10px',
        border: 'solid 1px black',
        display: 'inline-box'
    };

    useEffect(()=>{
//           if(!dataLoaded) {
                //console.log('DataLoaded: ' + dataLoaded);
                if (gridData.cols.length > 0 && !gridData.cols[0]['styles']) {
                    let cols2 = gridData.cols.map((c) => {
                        return {...c, styles: {...colStyles, width: c.width}};
                    });
                    cols2[1].styles.flex = '1 1 auto';


                    let rrs = props.dataRows.map((r, index) => {
                        return {...r, state: GridRowState.SAVED, justAdded: false, gridIndex: index};
                    });
//                    setDataLoaded(true);
                    //setRows(rrs);
                    setGridData({...gridData, cols:cols2, data:rrs});

                }
//           }
    }, [gridData.cols]);


    const addClick = ()=>{
        let max = 1;
        if(gridData.data.length > 0){
            max = Math.max(...gridData.data.map(r=>{return r.gridIndex}));
            max++;
        }

        //console.log(max);

        let rr = {state: GridRowState.NEW, justAdded:true, gridIndex:max};
        for (let cc in gridData.cols){
            let cx = gridData.cols[cc];
            rr[cx.column] = '';
        }
        //console.log('New Row');
        //console.log(rr);
        //setRows([...rows, rr]);
        let data = [...gridData.data, rr];
        setGridData({...gridData, data:data});
    }

    const onBlurFce = (ev, cancel = false)=>{
        let indexR = edited.row;
        let indexC = edited.cell;
        //console.log('innerText: ' + editedCell.current);
        let newVal = editedCell.current.innerText ?? '';

        if(newVal === undefined){
            newVal = '';
        }

        //console.log('newVal: ' + newVal);

        editedCell.current.contentEditable = false;
        editedCell.current.style.backgroundColor = 'silver';
        editedCell.current.removeEventListener('keydown', keyDown);
        editedCell.current.removeEventListener('blur', onBlurFce);

        let oldRec = edited.record;

        //console.log('Cancel: ' + (cancel ? 'YES' : 'NO'));
        if(!cancel) {
            let newRows = gridData.data.map((r, index) => {
                //console.log('Nastavuji...' + index + ' vs ' + indexR);
                if (r.gridIndex == indexR) {
                    let col = gridData.cols[indexC];
                    let newR = {
                        ...r,
                        [col.column]: newVal,
                        state: r.state == GridRowState.SAVED ? GridRowState.UPDATED : r.state,
                        justAdded: false
                    };
                    return newR;
                } else {
                    return r;
                }
            });
            //setRows(newRows);
            setGridData({...gridData, data:newRows});
        }else{
            editedCell.current.innerText = oldEditvalue.current ?? '';
        }
        let nullEd = {row:null, cell: null};
        setEdited(nullEd);
        editedCell.current = null;
        oldEditvalue.current = null;
    }

    const keyDown = (ev)=>{
        const {ctrlKey, keyCode} = ev;
        switch (keyCode){
            case 13:
                editedCell.current.removeEventListener('keydown', keyDown);
                editedCell.current.removeEventListener('blur', onBlurFce);
                onBlurFce(false);
                break;
            case 27:
                editedCell.current.removeEventListener('keydown', keyDown);
                editedCell.current.removeEventListener('blur', onBlurFce);
                onBlurFce(null, true);
                break;
            default:
                break;
        }
    }

    const editCell = (ev, indexR, indexC)=>{
        if(!indexR){
            //console.log('noIndexR');
            //console.log(ev);
        }
        let col = gridData.cols[indexC];
        //console.log(col);
        if(col['readonly']){
            return;
        }
        let row = gridData.data.filter(r=>r.gridIndex == indexR);

        //console.log(ev.target.dataset.rowcell);
        edited.cell = indexC;
        edited.row = indexR;

        //console.log(edited);


       // console.log(editedCell);
        setEdited({row:indexR, cell:indexC});
        oldEditvalue.current = row[gridData.cols[indexC].column];

        ev.target.contentEditable = true;
        ev.target.style.backgroundColor = 'white';
        editedCell.current = ev.target;
        editedCell.current.focus();
        editedCell.current.addEventListener('keydown', keyDown);
        editedCell.current.addEventListener('blur', onBlurFce);
    }

    const saveMe = (ev)=>{
        props.save(gridData.data);
    };



    useEffect(()=>{
        //console.log('Just Added...');
        if(gridData.data && gridData.data.length > 0){
            let added = gridData.data.filter(r=>r.justAdded);

            if(added){
                //console.log(added);
                if(editedCell.current){
                    let indexR = gridData.data.findIndex(r=>r.justAdded === true);
                    var event = new MouseEvent('dblclick', {
                        view: window,
                        bubbles: true,
                        cancelable: true
                    });

                    //console.log('justAddedIndex: ' + indexR);
                    setEdited({row:indexR, cell: 0});
                    editedCell.current.dispatchEvent(event);
                    //editedCell.current.doubleclick();
                }
                added.justAdded = false;
            }
            //let cleared = rows.map(());

        }
    }, [gridData.data?.length]);

    const smazMe = (ev, info)=>{
        //console.log('Mazu: ' + info.row);
        let rr = gridData.data.map((r)=>{
            if(r.gridIndex == info.row){
                if(r.state == GridRowState.NEW){
                    return {...r, state: GridRowState.ERASED};
                }else {
                    return {...r, state: GridRowState.DELETED};
                }
            }else{
                return r;
            }
        });
        //setRows(rr.filter(r=>r.state != GridRowState.ERASED));
        setGridData({...gridData, data:rr.filter(r=>r.state != GridRowState.ERASED)});

    };

    let firstAdded = false;
    /*
    console.log('RENDER');
    console.log(gridData?.cols);
    console.log('RENDER---');
     */
    return <div>
        {gridData.cols && gridData.cols.length > 0 && <>
        <div className={'gridTools'}>
            <button onClick={addClick}> + </button>
            <button> - </button>
            <button onClick={()=>{saveMe()}}> Uložit </button>
        </div>
        <div style={{display:"flex", width: '1200px', backgroundColor:'gray', color:'white'}}>
            <span style={{...colStyles, width: '20px', paddingLeft: '0px', paddingRight: '0px'}}>&nbsp;</span>
        {gridData.cols.map((c, index)=>{
            return <div style={c.styles} key={index}>{c.title}</div>
        })}
            <div style={toolsStyle}>&nbsp;</div>
        </div>
        {gridData.data.map((r, indexR)=>{
            //console.log(r);
            if(r.state != GridRowState.DELETED) {
                let stateR = ' ';
                switch (r.state){
                    case GridRowState.NEW:
                        stateR = 'N';
                        break;
                    case GridRowState.UPDATED:
                        stateR = '*';
                        break;
                    case GridRowState.DELETED:
                        stateR = 'X';
                        break;
                }
                return <div key={indexR} style={{display: 'flex', backgroundColor: 'silver'}}>
                    <span style={{...colStyles, textAlign:'center', width: '20px', paddingLeft: '0px', paddingRight: '0px'}}>{stateR}</span>
                    {gridData.cols.map((c, indexC) => {
                        let addRef = false;
                        if (r.justAdded && !firstAdded) {
                            if(!c['readonly']) {
                                addRef = true;
                                firstAdded = true;
                            }
                            //r.justAdded = false;
                        }
                        return <div ref={addRef ? editedCell : null} onDoubleClick={(ev) => {
                            editCell(ev, r.gridIndex, indexC)
                        }}
                                    key={indexC} style={c.styles}>{r[c.column]}</div>
                    })}
                    <div style={toolsStyle}>
                        <button onClick={(ev) => {
                            smazMe(ev, {row: r.gridIndex})
                        }}>smazat
                        </button>
                    </div>
                </div>
            }
        })}
        </>}
    </div>
}

