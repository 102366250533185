
/*
export const AlertInfo = {
    content:<>---</>,
    type:AlertTypes.MESSAGE_BOX,
    callBack:(data)=>{},
    returnData:{},
}
*/

import {useContext, useState} from "react";
import {DocumentContext} from "../App";
import {usePagePosition} from "./usePagePosition";

export const ContextMenuItem = {
    text:'--',
    callback:()=>{}
}

export const ContextMenu = {
    items:[],
    x:0,
    y:0
}

export const useContextMenu = ()=>{
    const [Cfg, dispatch] = useContext(DocumentContext);
    const [pos, handleMouseMove, handleXY] = usePagePosition();
    //const [menu, setMenu] = useState(null);

    const showContextMenu = (menu)=>{
        let posInMm = handleXY(menu.x, menu.y, 0, 0);
        menu  = {...menu, x:(menu.x+window.scrollX), y: (menu.y+window.scrollY)};
        //console.log(menu);
        //console.log(Cfg);
        dispatch({type:'activeContextMenu', payload:{verb:'add', menu:menu}});
        //setMenu(menu);
    }

    const bckClick = (ev)=>{
        dispatch({type:'activeContextMenu', payload:{verb:'close', menu:null}});
    }

    const contextMenuSys = (ev)=>{
        ev.preventDefault();
        ev.stopPropagation();
        return false;
    }

    const  itemClick = (it)=>{
        dispatch({type:'activeContextMenu', payload:{verb:'close', menu:null}});
        if(it.callback){
            it.callback();
        }
    }

    const renderContextMenu = ()=>{
        if(Cfg.activeContextMenu){
            return <> <div onContextMenu={(ev)=>{return contextMenuSys(ev)}}
                           onClick={(ev)=>{bckClick()}} className={'contextMenuBackground prevent-select'}></div>
                <div className={'contextMenuCover'} style={{left:Cfg.activeContextMenu.x, top:Cfg.activeContextMenu.y}}>
                    {
                        Cfg.activeContextMenu.items.map((it, index)=>{
                            return <div key={index} onClick={()=>{itemClick(it)}} className={'contextMenuItem  prevent-select'}>{it.text}</div>
                        })
                    }
                </div>
            </>
        }
        return '';
    }
    return [showContextMenu, renderContextMenu];
}