import {useEffect} from "react";

export const UserField = (props)=>{
    const {userData, ...other} = props;

    return <div>
        <label>{props.userData.abbrev}
            <div><input type='text'/> </div>
        </label>
    </div>
}